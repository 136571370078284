import {
  GET_TENANTS_LIST,
  IMakeRentPaymentAction,
  IMonthlyView,
  ISecurityDeposit,
  ITenant,
  ITenantsPaymentInfo,
} from '../tenants/types';

export const GET_PROPERTY_LIST = 'GET_PROPERTY_LIST';
export const SET_ACTIVE_PROPERTY = 'SET_ACTIVE_PROPERTY';
export const UPDATE_SINGLE_TENANT = 'UPDATE_SINGLE_TENANT';
export const UPDATE_SECURITY_DEPOSITS = 'UPDATE_SECURITY_DEPOSITS';
export const SET_ACTIVE_DB = 'SET_ACTIVE_DB';
export const SET_LOADER_FLAG = 'SET_LOADER_FLAG';
export const SET_MOVEOUT_MONTHLYS = 'SET_MOVEOUT_MONTHLYS';
export const ADD_REMOVE_TENANT = 'ADD_REMOVE_TENANT';

export interface IGetPropertyListAction {
  type: typeof GET_PROPERTY_LIST;
  payload: IProperty[];
}

export interface ISetActivePropertyAction {
  type: typeof SET_ACTIVE_PROPERTY;
  payload: IProperty;
}

export interface IGetTenantsListAction {
  type: typeof GET_TENANTS_LIST;
  payload: ITenantsPaymentInfo;
}

export interface IUpdateSingleTenantAction {
  type: typeof UPDATE_SINGLE_TENANT;
  payload: ITenant;
}

export interface ISetActiveDBAction {
  type: typeof SET_ACTIVE_DB;
  payload: IDataBase;
}

export interface ISetLoaderFlagAction {
  type: typeof SET_LOADER_FLAG;
  payload: boolean;
}

export interface IUpdateSecurityDepositAction {
  type: typeof UPDATE_SECURITY_DEPOSITS;
  payload: ISecurityDeposit;
}

export interface ISetMoveoutMonthlyAction {
  type: typeof SET_MOVEOUT_MONTHLYS;
  payload: IMonthlyView[];
}

export interface IAddRemoveTenantAction {
  type: typeof ADD_REMOVE_TENANT;
  payload: ITenant;
}

export interface IProperty {
  PropertyID: number;
  Address: string;
  Comment: string;
  Tenants: ITenant[];
  SelectedTenant: number;
}

export interface IDataBase {
  DisplayName: string;
  DatabaseName: string;
  ID: number;
}

export interface IPropertyReduced {
  Address: string;
  PropertyID: number;
}

export type PropertyActionTypes =
  | IGetTenantsListAction
  | IGetPropertyListAction
  | ISetActivePropertyAction
  | IUpdateSingleTenantAction
  | IMakeRentPaymentAction
  | ISetActiveDBAction
  | ISetLoaderFlagAction
  | IUpdateSecurityDepositAction
  | IAddRemoveTenantAction
  | ISetMoveoutMonthlyAction;
