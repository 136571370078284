import React, { useEffect, useState } from 'react';
import { EntryType } from '../../../../modules/balance/types';
import { IStatementLine } from '../../../../modules/statement/types';
import { formatter } from '../../../../Utils/formatters';
import { NumberColor } from '../../Styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import styled from 'styled-components';
import visa from '../../../../resources/visa.png';
import { RootState } from '../../../../reducers';
import { applyAmountChange } from '../../../../modules/statement/statementActions';
import { useActiveDB } from '../../../../hooks/useActiveDB';

type MyProps = {
  StatementLine: IStatementLine;
  minWidth: string;
  CheckStatementLine: (
    cronid: number,
    isChecked: boolean,
    amount: string,
  ) => void;
};

const MatchStatementLine: React.FC<MyProps> = (props) => {
  const [showVisa, setShowVisa] = useState<boolean>(false);
  const [showNumberEditor, setShowNumberEditor] = useState<boolean>(false);
  const [editedAmount,setEditedAmount] = useState<number>(0);
  const { databaseName } = useActiveDB();
  const statementLines = useSelector(
    (state: RootState) => state.statementReducer.statementLines,
  );

  const dispatch = useDispatch();
  let currentType: EntryType = EntryType.None;
  const {
    eComment,
    mortgageComment,

    wComment,
    depositComment,
    rentComment,
    mortgageAmount,
    Notes,
    wAmount,
    depositAmount,
    rentAmount,
    eAmount,
    eCheckNumber,
    wCheckNum,
    mortgageCheck,
    TransactionTime,
    reviewed,
    cronid,
    ccCheck,
    ExpenseName,
  } = props.StatementLine;

  useEffect(() =>{
    if(showNumberEditor){
       console.log(props.StatementLine)
       if(props.StatementLine.eAmount){
        setEditedAmount(Number(props.StatementLine.eAmount));
       }
       if(props.StatementLine.mortgageAmount){
        setEditedAmount(Number(props.StatementLine.mortgageAmount));
       }
       
    }
   
  },[showNumberEditor])
  useEffect(() => {
    setShowVisa(ccCheck === '0');
  }, [ccCheck]);

  useEffect(() => {
    setShowNumberEditor(false);
  },[statementLines])
  const getEntryType = (): EntryType => {
    if (mortgageAmount) {
      currentType = EntryType.Mortgage;
      return EntryType.Mortgage;
    }
    if (wAmount) {
      currentType = EntryType.Withdrawal;
      return EntryType.Withdrawal;
    }
    if (eAmount) {
      currentType = EntryType.Expense;
      return EntryType.Expense;
    }
    if (depositAmount) {
      currentType = EntryType.Deposit;
      return EntryType.Deposit;
    }
    if (rentAmount) {
      currentType = EntryType.Income;
      return EntryType.Income;
    }
    return EntryType.None;
  };

  const getEntryMessage = (): string => {
    if (eComment) return `[${ExpenseName}] ${eComment} ${Notes}`;
    if (mortgageComment) return mortgageComment;
    if (wComment) return wComment;
    if (depositComment) return depositComment;
    if (rentAmount) return `${rentComment}`;
    return `[${ExpenseName}]`;
  };

  const getEntryIncome = (): string => {
    if (depositAmount) return formatter.format(depositAmount);
    if (rentAmount) return formatter.format(rentAmount);
    return '--';
  };

  const getEntryOut = (): string => {
    if (eAmount) return formatter.format(eAmount);
    if (mortgageAmount) return formatter.format(mortgageAmount);
    if (wAmount) return formatter.format(wAmount);
    return '--';
  };

  const getCheckNum = (): string => {
    if (eCheckNumber && eCheckNumber !== 'NULL') {
      return eCheckNumber;
    }
    if (wCheckNum) {
      return wCheckNum;
    }
    if (mortgageCheck) {
      return mortgageCheck;
    }
    return '';
  };

  const onChange = (
    e: any,
    cronid: number,
    amount: string,
    checked: boolean,
  ): void => {
    props.CheckStatementLine(cronid, checked, amount);
  };

  const onContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowNumberEditor(true);
  }

  const closeEditor = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowNumberEditor(false)
  }

  const applyChange = e => {
    dispatch(applyAmountChange(databaseName!,editedAmount,props.StatementLine));
    e.preventDefault();
    e.stopPropagation();
  }

  const inputEditorOnClick = e => {
    e.preventDefault();
    e.stopPropagation();
  }

  const inputEditorOnChange = e => {
    e.preventDefault();
    e.stopPropagation();
    setEditedAmount(e.target.value);
  }
  return (
    <div
      className={`border ${
        reviewed == 0 ? 'bg-light text-dark' : 'bg-dark text-light'
      } pt-2 `}>
      <input type='hidden' name='cronId' value={cronid} />
      <div
        className='MatchStatementLineWrapper'
        onClick={(e) => {
          const amount = `${getEntryIncome()}${getEntryOut()}`
            .replace(/--/g, '')
            .replace(/\$/g, '');
          onChange(e, cronid, amount, reviewed !== 1);
          
        }}
        >
        <div></div>
        <div>{new Date(TransactionTime).toISOString().substr(0, 10)}</div>
        <div>{getEntryType()}</div>
        <div>{getEntryMessage()}</div>
        <div>
          <NumberColor Type={currentType}>{getEntryIncome()}</NumberColor>
        </div>
        <div>
          <NumberColor Type={currentType} onContextMenu={onContextMenu}>{getEntryOut()}
            {showNumberEditor && <NumberEditor>
              <input type="text" value={editedAmount} onClick={inputEditorOnClick} 
                      onChange={inputEditorOnChange}/>
              <CheckCircleIcon className='svg1' onClick={applyChange} />
              <CancelIcon className='svg2' onClick={closeEditor} />
            </NumberEditor>}
          </NumberColor>
        </div>
        <div>
          {!showVisa && getCheckNum()}
          {showVisa && <img alt='' style={{ maxWidth: '30px' }} src={visa} />}
        </div>
      </div>
    </div>
  );
};

export default MatchStatementLine;

const NumberEditor = styled.div`
  position: relative;
  display: flex;
  input{
    width: 57px;
    position:absolute;
    top: -23px;
  }
  .svg1{
    position: absolute;
    top: -28px;
    right: -1px;
    zoom: 0.7;
    color: green;
  }
  .svg2{
    position: absolute;
    top: -28px;
    right: -22px;
    zoom: 0.7;
  }
`;
