export const GET_BALANCE_DATA = 'GET_BALANCE_DATA';
export const UPDATE_BALANCE_FIGURES = 'UPDATE_BALANCE_FIGURES';

export enum EntryType {
  Mortgage = 'Mortgage',
  Withdrawal = 'Withdrawal',
  Deposit = 'Deposit',
  Income = 'Income',
  Expense = 'Expense',
  Escrow = 'Escrow',
  Total = 'Total',
  None = 'None',
}

export interface ITableEntry {
  Name: EntryType;
  Amount: number;
}

export interface IPropertyBalance {
  PropertyID: Number;
  Address: string;
  Table: ITableEntry[];
}

export interface IRawBalanceEntry {
  name: EntryType;
  Address: string;
  PropertyID: number;
  Amount: number;
}

interface IGetBalanceAction {
  type: typeof GET_BALANCE_DATA;
  payload: IPropertyBalance[];
}

interface IUpdateBalanceFiguresAction {
  type: typeof UPDATE_BALANCE_FIGURES;
  payload: IRawBalanceEntry;
}

export type BalanceActionType = IGetBalanceAction | IUpdateBalanceFiguresAction;
