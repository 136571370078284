import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form } from "react-bootstrap";
import './Login.css';
import { ILoginCredentials } from "../../modules/Login/types";
import { checkStatus, sendLoginAction, toggleLoginError } from "../../modules/Login/loginActions";
import {  useHistory } from 'react-router';
import { RootState } from '../../reducers';
import { ErrorMessage, LoginBackground } from './styles';
import { CURRENT_PAGE, TOKEN_KEY, USER_KEY } from '../../Utils/Constants';
  
const Login = () => {
    const [userName, setUserName] = useState<string>('');
    const [password, setpassword] = useState<string>('');        
    const dispatch = useDispatch();
    const history = useHistory();  
    const [showControls, setShowControls] = useState<boolean>(false);
    const {token,hideError} = useSelector((state:RootState)=> state.loginReducer);    
    
    useEffect(()=>{
        const token = localStorage.getItem(TOKEN_KEY);
        const user = localStorage.getItem(USER_KEY);
        if(user && token){
            dispatch(checkStatus(user, token));
        } else {
            setShowControls(true);
            history.push("/Login");
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{                   
       if(token){
           const initPage = localStorage.getItem(CURRENT_PAGE);
           if(initPage && initPage !== 'RentPayments'){
            history.push(`/${initPage}`);
           } else {
                history.push("/RentPayments");
           }
        
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[token,hideError]);
    const onUserChange = (event: any) => {
        setUserName(event.target.value);
    }
    const onPasswordChange = (event: any) => {
        setpassword(event.target.value);
    }

    const onKeyDown = (e) => {
        if(e.key === 'Enter'){
            onLogin();
        }
    }

    const onLogin =  () => {
        let credentials: ILoginCredentials = {
            userName: userName || "NONE",
            password: password || "NONE"
        }              
        dispatch(sendLoginAction(credentials));           
    }

    const toggleError = (isShowing:boolean):void => {
        dispatch(toggleLoginError(isShowing))
    }

    return (
        <LoginBackground authenticate={false}>
            { showControls &&
            <Modal style={{ 'zIndex': '20001' }} centered show={true} onHide={() => { }} animation={true}>
                <Modal.Header >
                    <Modal.Title>Login: DK Associates Database</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control type="text" onKeyDown={onKeyDown} onChange={onUserChange} onFocus={(e) =>toggleError(true)} value={userName} />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" onKeyDown={onKeyDown} onChange={onPasswordChange} onFocus={(e) => toggleError(true)} value={password} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    { !hideError && <ErrorMessage>Login failed!</ErrorMessage>}
                    <Button variant="primary" onClick={onLogin}>
                        Login
                        </Button>
                </Modal.Footer>
            </Modal>
             }
        </LoginBackground>
    )
}


export default Login;