import React from 'react';
import styled from 'styled-components';
import ajaxLoader from '../../resources/ajax-loader-t.gif';

const Drape = styled.div<{ IsVisible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 58, 64, 0.6);
  z-index: 1000;
  padding: 100px;
  visibility: ${(prop) => (prop.IsVisible ? 'visible' : 'hidden')};
`;

type MyProps = {
  IsVisible: boolean;
};
const LoadingDrape: React.FC<MyProps> = ({ IsVisible }) => {
  return (
    <Drape IsVisible={IsVisible}>
      <img alt='' src={ajaxLoader} />
    </Drape>
  );
};

export default LoadingDrape;
