import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import styled from 'styled-components';
import { Leaf } from '../../modules/escrow/types';

const StyledTreeEntry = styled.div`
  color: gray;
  padding-left: 5px;
  margin: 2px;
  display: flex;
  cursor: pointer;
`;

const TreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Space = styled.div`
  width: 40px;
`;

type MyProps = {
  node: Leaf;
  spaces: number;
  onClick?: any;
};

export const TreeFragment: React.FC<MyProps> = ({ node, spaces, onClick }) => {
  const [, updateState] = React.useState<object>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const handleNodeToggle = (node: Leaf): void => {
    if (node.IsOpened) {
      node.IsOpened = false;
    } else {
      node.IsOpened = true;
    }
    forceUpdate();
  };

  const onLabelClick = (node: Leaf) => {
    if (onClick && !node.Children) {
      onClick(node);
    }
  };
  return (
    <TreeContainer>
      <StyledTreeEntry onClick={() => handleNodeToggle(node)}>
        {spaces === 1 && <Space />}
        {spaces === 2 && (
          <>
            <Space />
            <Space />
          </>
        )}
        {spaces === 3 && (
          <>
            <Space />
            <Space />
            <Space />
          </>
        )}
        {node.IsOpened && node.Children && <ArrowDropDownIcon />}
        {!node.IsOpened && node.Children && <ArrowRightIcon />}
        {!node.Children && <CancelIcon />}
        {node.Amount ? (
          <span onClick={() => onLabelClick(node)}>{`${
            node.Label
          } (${node.Amount.toFixed(2)})`}</span>
        ) : (
          <span onClick={() => onLabelClick(node)}>{`${node.Label}`}</span>
        )}
      </StyledTreeEntry>
      {node.IsOpened && node.Children && (
        <>
          <TreeContainer>
            {node.Children.map((child: Leaf, index: number) => {
              return (
                <TreeFragment
                  onClick={onClick}
                  key={child.AutoActionId}
                  spaces={spaces + 1}
                  node={child}
                />
              );
            })}
          </TreeContainer>
        </>
      )}
    </TreeContainer>
  );
};
