import styled from 'styled-components';
import { EntryType } from '../../modules/balance/types';
export const NumberColor = styled.div<{ Type: EntryType }>`
  font-weight: bold;
  color: ${(prop) => {
    switch (prop.Type) {
      case EntryType.Mortgage:
        return 'rgb(229,151,3)';
      case EntryType.Withdrawal:
        return 'rgba(148,63,169,0.8)';
      case EntryType.Expense:
        return 'rgb(232,0,5)';
      case EntryType.Deposit:
        return 'rgb(27,147,205)';
      case EntryType.Income:
        return 'rgb(78,211,20)';
    }
  }};
`;

export const SwitchWrapper = styled.div`
  position: relative;
`;

export const SwitchCover = styled.div`
  width: 30px;
  height: 30px;
  z-index: 2;
  position: absolute;
`;
