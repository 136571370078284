import React from 'react';
import {Container, Row, Col} from 'react-grid-system';
import styled from 'styled-components';
import {FormControl, InputGroup, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useForm } from '../../hooks/useForm';
import { IDepositWithdrawal } from './Transactions';
// @ts-ignore
type MyProps = {
    Name: string,
    ShowCheckNum?: boolean,
    Apply: () => void
    setData: (e:any) => void,
    data:IDepositWithdrawal,
    IsDisabled:boolean
}


const Container1 = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media(max-width:600px){
    grid-template-columns: 1fr;
  }
`;
const DepositWithdrawal:React.FC<MyProps> = ({Name,ShowCheckNum,Apply,data,setData,IsDisabled}) => {
    const {onChange} = useForm(data,setData);    
    return (
        <div className="flex-grow-1">
            <Container className='border bg-dark text-light' fluid>
                <h2>{Name}</h2>
                <Row className="border bg-dark text-light pt-2">
                    <Col>
                        
                            <Container1 className="align-items-center">
                                <Col >
                                    <Form.Control
                                        className="mb-2"
                                        id={`${Name}Amount`}
                                        placeholder="Amount"
                                        name="Amount"
                                        data-numeric={true}
                                        onChange={onChange}
                                        value={data.Amount}
                                    />
                                </Col>
                                <Col >
                                    <InputGroup className="mb-2">
                                        <FormControl 
                                          id={`${Name}Comment`} 
                                          placeholder="Comment"
                                          name="Comment"
                                          onChange={onChange}
                                          value={data.Comment}
                                          />
                                    </InputGroup>
                                </Col>
                                { ShowCheckNum &&
                                <Col >
                                        <InputGroup className="mb-2">
                                            <FormControl 
                                                id={`${Name}checkNum`} 
                                                placeholder="Check #:"
                                                name="CheckNumber"
                                                onChange={onChange}
                                                value={data.CheckNumber}
                                                />
                                        </InputGroup>
                                </Col>
                                }
                                {
                                    !ShowCheckNum && <Col ></Col>
                                }                                
                                <Col >
                                    <Button onClick={Apply} disabled={IsDisabled}  className="mb-2" block>
                                        Apply
                                    </Button>
                                </Col>
                            </Container1>                       
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DepositWithdrawal;