import {
  GET_ALLTIME_TENANTS,
  ITenant,
  tenantsActionType,
  UPDATE_ACTIVE_TENANT,
} from './types';

export interface ITenantsReducer {
  alltimeTenants: ITenant[];
}

const INIT_STATE: ITenantsReducer = {
  alltimeTenants: [],
};
export const tenantsReducer = (
  state: ITenantsReducer = INIT_STATE,
  action: tenantsActionType,
) => {
  switch (action.type) {
    case GET_ALLTIME_TENANTS:
      return { ...state, alltimeTenants: action.payload };
    case UPDATE_ACTIVE_TENANT:
      return {
        ...state,
        alltimeTenants: state.alltimeTenants.map((t: ITenant) => {
          if (t.TenantID === action.payload.TenantID) {
            return {
              ...t,
              Active: action.payload.Active,
            };
          } else {
            return t;
          }
        }),
      };
    default:
      return state;
  }
};
