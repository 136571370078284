import React from 'react';
import { IStatementLine } from '../../modules/statement/types';
import { TableCell, TableHeaderRow } from './Styles';

type MyProps = {
  line: IStatementLine;
};
export const TableRecords: React.FC<MyProps> = ({ line }) => {
  //['Jan 1 2021','Our House Acct','Spotiry','12.99']
  const fields: string[] = [];
  fields.push(line.TransactionTime.toString());
  fields.push(line.Address);
  fields.push(line.eComment!);
  fields.push(line.eAmount!.toString());
  return (
    <TableHeaderRow showSpace={false}>
      {fields!.map((field: string, index: number) => {
        return <TableCell key={index}>{field}</TableCell>;
      })}
    </TableHeaderRow>
  );
};

export default TableRecords;
