import styled from "styled-components";

export const LoginBackground = styled.div<{ authenticate: Boolean }>`
  width: 100%;
  height: 100vh;
  z-index: 10000;
  padding:0;
  margin:0;
  background-color: #005cbf;
  position: absolute;
  top:0;
  left:0;
  display:${props => props.authenticate ? 'none' : 'block'};
  `;

export const ErrorMessage = styled.div`
  font-weight:bold;
  color:red;
`;