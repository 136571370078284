import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getMortageList,
  postMortgagePayments,
} from '../../../modules/mortgages/mortgagesActions';
import { IMortgageEntry } from '../../../modules/mortgages/types';
import { RootState } from '../../../reducers';
import { useActiveDB } from '../../../hooks/useActiveDB';
import { MortgageConfirmModal } from './MortgageConfirmModal';
import { MortgageEntry } from './MortgageEntry';
import { EntryType, IRawBalanceEntry } from '../../../modules/balance/types';
import { updateBalanceFigures } from '../../../modules/balance/balanceActions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  max-width: 550px;
  height: 170px;
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  max-width: 550px;
  margin: auto;
  background-color: whitesmoke;
`;
type MyProps = {};
export type Mortgage = {
  Name: string;
  Label: string;
  Note: string;
  IsSelected: boolean;
};

export const MortgagePayment: React.FC<MyProps> = () => {
  const { databaseName } = useActiveDB();
  const [mortgages, setMortgages] = useState<IMortgageEntry[]>([]);
  const [showConfirmBox, setShowConfirmBox] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const mortgagesArr: IMortgageEntry[] = useSelector(
    (state: RootState) => state.mortgageReducer.mortgages,
  );
  useEffect(() => {
    if (mortgagesArr.length === 0) {
      dispatch(getMortageList(databaseName!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMortgages(mortgagesArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mortgagesArr]);

  useEffect(() => {
    dispatch(getMortageList(databaseName!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseName]);

  const validate = () => {
    const count = mortgages.reduce((total: number, m: IMortgageEntry) => {
      if (m.IsSelected) {
        total++;
      }
      return total;
    }, 0);
    return !(count > 0);
  };

  const handleMortgageCheck = (e: any, mort: IMortgageEntry) => {
    if (e.target.type === 'checkbox') {
      mort.IsSelected = !mort.IsSelected;
    }

    if (e.target.type === 'text') {
      mort.DefaultComment = e.target.value;
    }

    const newMortgages = mortgages.reduce(
      (mArr: IMortgageEntry[], m: IMortgageEntry) => {
        if (m.MortgageName === mort.MortgageName) {
          mArr.push(mort);
        } else {
          mArr.push(m);
        }
        return mArr;
      },
      [],
    );
    setMortgages(newMortgages);
  };

  const getMortgagesToPay = (): IMortgageEntry[] => {
    return mortgages.reduce((mArr: IMortgageEntry[], m: IMortgageEntry) => {
      if (m.IsSelected) {
        mArr.push(m);
      }
      return mArr;
    }, []);
  };

  const payMortgages = () => {
    const mortgages: IMortgageEntry[] = getMortgagesToPay();
    dispatch(postMortgagePayments(mortgages, databaseName!));
    mortgages.forEach((mort) => {
      let balanceEntry: IRawBalanceEntry = {
        name: EntryType.Mortgage,
        Amount: mort.Monthly,
        PropertyID: mort.PropertyID,
        Address: '',
      };
      dispatch(updateBalanceFigures(balanceEntry));
    });
  };

  const onClick = () => {
    const today = moment();
    if (today.date() !== 1) {
      setShowConfirmBox(true);
    } else {
      payMortgages();
    }
  };

  const CloseConfirmBox = () => {
    setShowConfirmBox(false);
  };
  return (
    <>
      <MortgageConfirmModal
        PayMortgages={payMortgages}
        ShowConfirmBox={showConfirmBox}
        CloseConfirmBox={CloseConfirmBox}
      />
      <Row className='border bg-dark text-light pt-2'>
        <Col>
          <h2>Mortgages</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <ButtonWrapper>
                <Button
                  style={{ maxWidth: '300px', margin: 'auto' }}
                  className='mb-2'
                  block
                  disabled={validate()}
                  onClick={onClick}>
                  Pay Mortgages
                </Button>
                <Container>
                  {mortgages.map((mort: IMortgageEntry, index: number) => {
                    return (
                      <MortgageEntry
                        Name={mort.MortgageName}
                        Label={mort.MortgageName}
                        Notes={mort.DefaultComment}
                        Amount={mort.Monthly}
                        IsSelected={mort.IsSelected}
                        HandleMortgageChange={(e) =>
                          handleMortgageCheck(e, mort)
                        }
                      />
                    );
                  })}
                </Container>
              </ButtonWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
