import { setLoaderFlag } from '../modules/properties/propertyActions';

export async function http(request: RequestInfo, dispatch: any): Promise<any> {
  dispatch(setLoaderFlag(true));
  let response;
  if (request.toString().indexOf('api') > -1) {
    const url = `${request}&cb=${crypto
      .getRandomValues(new Uint32Array(1))
      .toString()}`;
    response = await fetch(url);
  } else {
    response = await fetch(request);
  }

  const body = await response.json();

  dispatch(setLoaderFlag(false));
  return body;
}

export const post = async (
  url: string,
  payload: any,
  dispatch: any,
): Promise<any> => {
  dispatch(setLoaderFlag(true));
  const cbUrl = `${url}&cb=${crypto
    .getRandomValues(new Uint32Array(1))
    .toString()}`;
  const response = await fetch(cbUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Headers': '*',
      'Referrer-Policy': 'no-referrer',
      //Origin: 'https://aviezri.com',
    },
    body: JSON.stringify(payload),
  });
  const body = await response.json();
  dispatch(setLoaderFlag(false));
  return body;
};
