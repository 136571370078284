import React from 'react';
import './App.css';
import {NavBarComp} from "./NavBar/Navbar";
import {BrowserRouter} from "react-router-dom";
import Properties from "./Properties/Properties";
import Databases from "./Databases/Databases";
import {Provider} from "react-redux";
import { configureStore } from '../store/configureStore';
import Routes from './Routes/routes';
import styled from 'styled-components';
import Balance from './Balance/Balance';
const TopAppContainer = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-template-rows: 1fr;
   @media (max-width: 600px){
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
   }
`;

const store = configureStore();
function App() {
    return (
       <Provider store={store}  >
        <BrowserRouter>
            <div className="App">                
                <NavBarComp/>
                <TopAppContainer>
                    <Databases/>
                    <Balance />
                    <Properties/>
                </TopAppContainer>                                
                <Routes />                
            </div>
        </BrowserRouter>
       </Provider>
    );
}

export default App;
