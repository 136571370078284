import { format } from 'date-fns';
export const formatDate = (date: string, forceExtraDay: boolean = false) => {
  const _date = date ? new Date(date) : new Date();
  _date.setDate(_date.getDate() + 1);
  const result = format(_date, 'yyyy-MM-dd');
  return result;
  // let current_datetime = new Date(date);
  // let year = current_datetime.getFullYear();
  // let month = current_datetime.getMonth() + 1;
  // let sMonth: string = month.toString();
  // if (sMonth.length === 1) {
  //   sMonth = `0${sMonth}`;
  // }
  // let day: number = current_datetime.getDate(); //.toString();
  // // if(forceExtraDay){
  // //     day +=1;
  // //     if(day > 31){
  // //         day = 31;
  // //     }
  // // }
  // let sDay = day.toString();
  // if (sDay.length === 1) {
  //   sDay = `0${day}`;
  // }
  // return `${year}-${sMonth}-${sDay}`;
};

export const daysDiff = (dt2: any) => {
  if (!dt2.getFullYear) {
    dt2 = new Date(dt2);
  }
  const dt1 = new Date();
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24),
  );
};
