import React from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';

const MortgageRow = styled.div`
  display:flex;
  flex-direction:row;
`;
const CheckWrapper = styled.div`
  top:7px;
  position:relative;
`;

const MortgageLabel = styled.div`
  width:185px;
`;
type MyProps = {
    Name:string,
    Label:string,
    Notes:string,
    Amount:number,
    IsSelected:boolean,
    HandleMortgageChange: (e:any)=>void
}
export const MortgageEntry:React.FC<MyProps> = ({Name,Label,Notes,IsSelected,Amount,HandleMortgageChange}) => {
    return (
    <MortgageRow>
        <CheckWrapper>
                <Form.Check
                    className='ml-3 mb-3'
                    type="switch"
                    id={Name}
                    label=""
                    name={Name}
                    onChange={HandleMortgageChange}
                    checked={IsSelected}
                />
            </CheckWrapper>
        <InputGroup className="mb-3">
            
        <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
                <MortgageLabel>{Label}</MortgageLabel>
            </InputGroup.Text>
        </InputGroup.Prepend>
        <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">{Amount}</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
            placeholder="Notes"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            value={Notes}
            name="Notes"
            onChange={HandleMortgageChange}
            disabled={!IsSelected}
        />
    </InputGroup>
</MortgageRow>
    )
}