import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IExpensesReducer, NormalizedAllData } from './types';
import { DBJsonList } from '../../components/MyExpenses/Types';

const initialState: IExpensesReducer = {
    activeJson: {
      dka: undefined,
      house: undefined,
    },
    currentExpenses: {
      dka: undefined,
      house: undefined,
    },
    savedRecords: { expenses: [] },
    totals: {}
  };



export const myExpensesSlice = createSlice({
  name: 'myExpenses',
  initialState,
  reducers: {
    setActiveJson: (state, action: PayloadAction<NormalizedAllData>) => {
      state.activeJson = action.payload;
    },
    setMyExpenses:(state, action: PayloadAction<NormalizedAllData>) => {
      state.currentExpenses = action.payload;
    },
    setMayExpensesTableItems:(state, action: PayloadAction<DBJsonList>) => {
      state.savedRecords = action.payload;
    },
    setTotals:(state, action: PayloadAction<any>) => {
      state.totals = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setActiveJson,
  setMyExpenses,
  setMayExpensesTableItems,
  setTotals
 } = myExpensesSlice.actions

export default myExpensesSlice.reducer