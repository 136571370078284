import styled from 'styled-components';

export const MoveOutAlertDiv = styled.div`
  background-color: red;
  font-size: 12px;
  color: white;
  margin: 3px;
  padding: 0 5px;
  display: inline-block;
  border-radius: 4px;
  //animation: pulse 3s infinite ease-in-out;
  @keyframes pulse {
    0% {
      opacity: 0;
    }
    /* 50% {
            opacity: 1;
        }
        75% {
            opacity: 0;
        } */
    100% {
      opacity: 1;
    }
  }
`;
