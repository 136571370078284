import React from 'react';
import styled from 'styled-components';
import { TableCell, TableHeaderRow } from './Styles';

const HeaderWrapper = styled.div`
  @media (max-width: 600px) {
    visibility: hidden;
  }
`;
export const TableHeader: React.FC = () => {
  return (
    <HeaderWrapper>
      <TableHeaderRow showSpace={true}>
        <TableCell bold={true}>Date</TableCell>
        <TableCell bold={true}>Address</TableCell>
        <TableCell bold={true}>Message</TableCell>
        <TableCell bold={true}>Amount</TableCell>
        <div></div>
      </TableHeaderRow>
    </HeaderWrapper>
  );
};
