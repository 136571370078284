import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './Automatch.css';
import { CreditCardRecord, MatchRecordEntry } from '../types';
import { parseCreditCardRecrods } from '../Util';
import MatchedRecords from './MatchedRecords';
import UnmatchedRecords from './UnmatchedRecords';

type MyProps = {
  showAutomatch: boolean;
  setShowAutomatch: Dispatch<SetStateAction<boolean>>;
};
const Automatch: FC<MyProps> = ({ showAutomatch, setShowAutomatch }) => {
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const [ccRawRecords, setCCRawRecords] = useState<string[]>([]);
  const [matchedRecords, setMatchedRecords] = useState<MatchRecordEntry[]>([]);
  const [unmatch, setUnmatch] = useState<CreditCardRecord[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleUpload = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (ccRawRecords.length > 0) {
      (async () => {
        const { matchedRecords, ccRecords } = await parseCreditCardRecrods(
          ccRawRecords,
        );
        setMatchedRecords(matchedRecords);
        setUnmatch(ccRecords);
      })();
    }
  }, [ccRawRecords]);
  useEffect(() => {
    if (selectedFiles) {
      if (selectedFiles) {
        var reader = new FileReader();

        reader.onload = function (evt) {
          if (evt && evt.target && evt.target.result) {
            const arr: string[] = `${evt.target.result}`.split('\n');
            setCCRawRecords(arr);
          }
        };

        reader.onerror = function (evt) {
          console.error('An error ocurred reading the file', evt);
        };

        reader.readAsText(selectedFiles, 'UTF-8');
      }
    }
  }, [selectedFiles]);
  const onChange = (event: React.FormEvent) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      setSelectedFiles(files[0]);
    }
  };

  return (
    <Modal
      size='lg'
      style={{ paddingRight: '10px' }}
      centered
      show={showAutomatch}
      onHide={() => setShowAutomatch(false)}
      animation={true}>
      <Modal.Header>
        <Modal.Title>Auto Match</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='autoMatchContainer'>
          <div className='m-3'>
            <label className='mx-3'>Choose file: </label>
            <input
              ref={inputRef}
              className='d-none'
              type='file'
              onChange={onChange}
            />
            <button onClick={handleUpload} className='btn btn-outline-primary'>
              Upload
            </button>
            <UnmatchedRecords ccRecords={unmatch} />
            <MatchedRecords matchedRecords={matchedRecords} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={() => setShowAutomatch(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Automatch;
