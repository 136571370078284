import { Dispatch } from 'react';
import { ROOT_URL } from '../../Utils/Constants';
import { http, post } from '../../Utils/http';
import {
  GET_VENDERS_LIST,
  IVendor,
  SET_SELECTED_VENDOR,
  VendorsActions,
} from './types';

export const getVendorsListAction = (db: string) => async (
  dispatch: Dispatch<VendorsActions>,
) => {
  let url: string = `${ROOT_URL}/vendors/vendors.php?db_name=${db}`;
  const result: IVendor[] = await http(url, dispatch);
  dispatch({
    type: GET_VENDERS_LIST,
    payload: result,
  });
};

export const setSelectedVendor = (vendor?: IVendor) => ({
  type: SET_SELECTED_VENDOR,
  payload: vendor,
});

export const upsertVendor = (db: string, vendor: IVendor) => async (
  dispatch,
) => {
  let url: string = `${ROOT_URL}/vendors/insertVendor.php?db_name=${db}`;
  await post(url, vendor, dispatch);
  dispatch(getVendorsListAction(db));
};
