import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

const TotalsTable = () => {    
    const [bottomLine, setBottomLine] = useState<number>(0);
    const totals = useSelector((s:RootState) => s.myExpensesReducer.totals);
    
    useEffect(()=>{      
        let _bottomLine = 0;
        Object.entries(totals).forEach(item => {
            _bottomLine = _bottomLine + getTotalPerAddress(item);                
        });
         
        setBottomLine(_bottomLine);
    },[totals])
    const getTotalPerAddress = (item: any): any => {
        var totals = 0;
        Object.keys(item[1]).forEach(entry => {            
            totals = totals + item[1][entry];
        }); 
        return totals;
    };
    return (
        <div className='totals groupBlock'>
          <div className='totalsTable'>
          {Object.entries(totals).map((item, index) => {
              return (
                <div className='totalsItem' key={index}>
                  <div className='cell expenseColor'>{item[0]}</div>
                  <div className='cell expenseColor'>
                    {getTotalPerAddress(item).toFixed(2)}
                  </div>
                </div>
              );
            })} 

            <div className='bottomLineColor'>
              <div>Bottom Line: </div>
              <div>{bottomLine.toFixed(2)}</div>
            </div>
                       
          </div>
        </div>
    );
};

export default TotalsTable;