import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import StatementEntries from './StatementEntries';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from 'react-bootstrap';
import {
  applyStatmentChangesAction,
  getStatementLinesAction,
} from '../../modules/statement/statementActions';
import { useDispatch, useSelector } from 'react-redux';
import { formatter } from '../../Utils/formatters';
import { useActiveDB } from '../../hooks/useActiveDB';
import { RootState } from '../../reducers';
import { CURRENT_PAGE } from '../../Utils/Constants';
import Match from './Match/Match';
import SearchPanel from './search/SearchPanel';


const Statement: React.FC = () => {
  const { databaseName } = useActiveDB();
  const [showMatch, setShowMatch] = useState<boolean>(false);
  const dispatch = useDispatch();
  const totalAmount = useSelector(
    (s: RootState) => s.statementReducer.totalAmount,
  );
  const onGetLinesClicked = (showAll: boolean) => {
    dispatch(getStatementLinesAction(showAll, databaseName));
  };
  useEffect(() => {
    localStorage.setItem(CURRENT_PAGE, 'Statement');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApplyClicked = () => {
    dispatch(applyStatmentChangesAction(databaseName!));
  };
  return (
    <div className='flex-grow-1 '>
      <Match showMatch={showMatch} setShowMatch={setShowMatch} />
      <Container className='border bg-dark text-light' fluid>
        <h2>Statement</h2>
        <Row className='border bg-dark text-light pt-2'>
          <Col>
            <Button onClick={() => onGetLinesClicked(false)} block>
              Get Statement
            </Button>
          </Col>
          <Col>
            <Button onClick={onApplyClicked} block>
              Apply
            </Button>
          </Col>
          <Col>
            <Button onClick={() => onGetLinesClicked(true)} block>
              Show All
            </Button>
          </Col>
          <Col>
            <Button onClick={() => setShowMatch(true)} block>
              Match
            </Button>
          </Col>
          <div>
            <div onClick={() => console.log('adfasdf')} >
              <SearchIcon />
            </div>
          </div>
          <Col>Total: {formatter.format(totalAmount)}</Col>
        </Row>
        <Row className='border bg-dark text-light pt-2'>
          <Col>
          {/* <SearchPanel /> */}
            <StatementEntries />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Statement;
