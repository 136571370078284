import { Dispatch } from 'react';
import {
  GET_ALLTIME_TENANTS,
  IActiveTenant,
  IRentPayment,
  ITenant,
  MAKE_RENT_PAYMENT,
  UPDATE_ACTIVE_TENANT,
} from './types';
import { ROOT_URL } from '../../Utils/Constants';
import { http } from '../../Utils/http';
import {
  ADD_REMOVE_TENANT,
  IUpdateSingleTenantAction,
  PropertyActionTypes,
  UPDATE_SINGLE_TENANT,
} from '../properties/types';
import moment from 'moment';
import { formatDate } from '../../Utils/datesUtil';

export const updateTenantsAction =
  (tenant: ITenant, dbname: string) =>
  async (dispatch: Dispatch<PropertyActionTypes>) => {
    //&amount=999&tenantId=47
    let strAmount = (tenant.RentAmount + '').replace(/\$/g, '');
    let url: string = `${ROOT_URL}/tenants/updateTenant.php?db_name=${dbname}&`;
    url = url + `comment=${tenant.Comment}&`;
    url = url + `email=${tenant.Email}&`;
    url = url + `isreal=${tenant.IsReal}&`;
    url =
      url + `leaseExpirationDate=${formatDate(tenant.LeaseExpirationDate!)}&`;
    url = url + `phone1=${tenant.Phone1}&`;
    url = url + `phone2=${tenant.Phone2}&`;
    url = url + `amount=${strAmount}&`;
    url = url + `tenantId=${tenant.TenantID}&`;
    url = url + `tenantName=${tenant.TenantName}&`;
    url = url + `apartment=${tenant.Apartment}&`;
    url = url + `propertyId=${tenant.PropertyID}`;
    let result: ITenant = await http(url, dispatch);
    result.LeaseExpirationDate = result.LeaseExpirationDate || '';
    let action: IUpdateSingleTenantAction = {
      type: UPDATE_SINGLE_TENANT,
      payload: result,
    };
    dispatch(action);

    //return Promise.resolve();
  };

export const payRentAction =
  (rentPayment: IRentPayment, dbname: string) =>
  async (dispatch: Dispatch<PropertyActionTypes>) => {
    const { propertyId, tenantId, amount, tenantName, comments } = rentPayment;
    let url: string = `${ROOT_URL}/tenants/rent.php?db_name=${dbname}`;
    url += `&propertyId=${propertyId}`;
    url += `&tenantId=${tenantId}`;
    url += `&amount=${amount}`;
    url += `&tenantName=${tenantName}`;
    url += `&comments=${comments}`;

    let result: IRentPayment = await http(url, dispatch);
    if (result.status === 'success') {
      dispatch({
        type: MAKE_RENT_PAYMENT,
        payload: result,
      });
    } else {
      //TODO: add error message
    }
  };

export const getAlltimeTenants = (dbname: string) => async (dispatch) => {
  let url: string = `${ROOT_URL}/tenants/allTenants.php?db_name=${dbname}`;
  let result: ITenant[] = await http(url, dispatch);
  dispatch({
    type: GET_ALLTIME_TENANTS,
    payload: result,
  });
};

export const updateActiveTenant =
  (dbname: string, active: number, tenantId: number) => async (dispatch) => {
    //activateTenant.php?db_name=allglute_DKA2009
    let url: string = `${ROOT_URL}/tenants/activateTenant.php?db_name=${dbname}`;
    url += `&active=${active}&tenantId=${tenantId}`;
    let result: IActiveTenant[] = await http(url, dispatch);
    if (result && result.length > 0) {
      dispatch({
        type: UPDATE_ACTIVE_TENANT,
        payload: result[0],
      });

      dispatch({
        type: ADD_REMOVE_TENANT,
        payload: result[0],
      });
    }
  };
