import {
  BalanceActionType,
  GET_BALANCE_DATA,
  IPropertyBalance,
  IRawBalanceEntry,
  UPDATE_BALANCE_FIGURES,
} from './types';

export interface IBalanceReducer {
  propertyBalances: IPropertyBalance[];
}

const INIT_STATE: IBalanceReducer = {
  propertyBalances: [],
};

export const balanceReducer = (
  state: IBalanceReducer = INIT_STATE,
  action: BalanceActionType,
) => {
  switch (action.type) {
    case GET_BALANCE_DATA:
      return { ...state, propertyBalances: action.payload };
    case UPDATE_BALANCE_FIGURES:
      const propertyBalances = updatePropertyBalances(state, action.payload);
      return { ...state, propertyBalances };
    default:
      return state;
  }
};

const updatePropertyBalances = (
  state: IBalanceReducer,
  entry: IRawBalanceEntry,
) => {
  return state.propertyBalances.map((value: IPropertyBalance) => {
    if (value.PropertyID === entry.PropertyID) {
      value.Table = value.Table.map((it) => {
        if (it.Name === entry.name) {
          let newValue =
            parseFloat(it.Amount.toString()) +
            parseFloat(entry.Amount.toString());
          return {
            Amount: newValue,
            Name: it.Name,
          };
        }
        return it;
      });
    }
    return value;
  });
};
