import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { SwitchCover, SwitchWrapper } from './Styles';

type MyProps = {
  cronid: any;
  reviewed: number;
  onChange: Function;
};
const CheckSwitch: FC<MyProps> = ({ cronid, reviewed, onChange }) => {
  let showSwitch = localStorage.getItem('blockSwitch');
  if (showSwitch === null) {
    localStorage.setItem('blockSwitch', 'true');
    showSwitch = 'true';
  }
  return (
    <SwitchWrapper>
      {showSwitch === 'true' && <SwitchCover></SwitchCover>}
      <Form.Check
        id={`${cronid}`}
        label=''
        checked={Number(reviewed) === 1}
        name={`${cronid}`}
        onClick={(e) => onChange(e, cronid)}
        type='switch'
      />
    </SwitchWrapper>
  );
};

export default CheckSwitch;
