import { iteratorSymbol } from 'immer/dist/internal';
import { Dispatch } from 'react';
import { ROOT_URL } from '../../Utils/Constants';
import { http, post } from '../../Utils/http';
import { applyStatmentChangesAction } from '../statement/statementActions';
import {
  GET_STATEMENT_LINES,
  IApplyStatmentChangesAction,
  StatementActionsType,
} from '../statement/types';
import {
  BankStatementEntry,
  GET_BANKMATCH_LINES,
  MatchActionsType,
  PostMatchSelection,
  POST_MATCH_SETTELE,
  SELECT_BANKMATCH_LINE,
  UN_MATCH,
} from './types';

export const getStatementLinesAction =
  (showAll: boolean, databaseName) =>
  async (dispatch: Dispatch<StatementActionsType>) => {
    let url: string = `${ROOT_URL}/statement/statement.php?db_name=${databaseName}&showAll=${
      showAll ? 'true' : 'false'
    }`;
    let result = await http(url, dispatch);
    dispatch({
      type: GET_STATEMENT_LINES,
      payload: result,
    });
    return Promise.resolve();
  };

export const getBankEntries =
  (getAll: string, databaseName?: string) =>
  async (dispatch: Dispatch<MatchActionsType>) => {
    let year = `${new Date().getFullYear()}`;
    let url: string = `${ROOT_URL}/match/getBankEntries.php?db_name=${databaseName}&query=${getAll}&year=${year}`;
    let result: BankStatementEntry[] = await http(url, dispatch);
    const bankLines: BankStatementEntry[] = result.map(
      (item: BankStatementEntry): BankStatementEntry => ({
        ...item,
        selected: false,
      }),
    );
    dispatch({
      type: GET_BANKMATCH_LINES,
      payload: bankLines,
    });
    return Promise.resolve();
  };

export const postMatch =
  (payload: PostMatchSelection, databaseName?: string) =>
  async (dispatch: Dispatch<any>) => {
    let url: string = `${ROOT_URL}/match/postMatch.php?db_name=${databaseName}`;
    const res = await post(url, payload, dispatch);
    if (res === 'success') {
      dispatch({
        type: POST_MATCH_SETTELE,
        payload,
      });
      dispatch(applyStatmentChangesAction(databaseName!));
    }
  };
export const unMatch =
  (databaseName: string, matchId: number, cronIds: string) =>
  async (dispatch: Dispatch<any>) => {
    let url: string = `${ROOT_URL}/match/unMatch.php?db_name=${databaseName}&matchId=${matchId}&cronids=${cronIds}`;
    const res = await http(url, dispatch);
    if (JSON.parse(res).success) {
      dispatch({
        type: UN_MATCH,
        payload: matchId,
      });
    }
  };
export const toggleMatchBankRecord = (matchId: number) => ({
  type: SELECT_BANKMATCH_LINE,
  payload: { matchId },
});
