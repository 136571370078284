import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMyExpenses, getMyExpensesListItemsAction,
} from '../../modules/myExpenses/myExpensesActions';
import { NormalizedAllData } from '../../modules/myExpenses/types';
import MainExpensesTable from './MainExpensesTable/MainExpensesTable';
import MyExpensesSheet from './MyExpensesSheet/MyExpensesSheet';

export type MyExpenseType = {
  setShowData: Dispatch<SetStateAction<boolean>>;
  activeJson?: NormalizedAllData;
};
const MyExpenses = () => {
  const [showData, setShowData] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getMyExpenses(dispatch);
    getMyExpensesListItemsAction(dispatch);
  }, []);

  return (
    <div>
      {!showData && <MainExpensesTable setShowData={setShowData} />}
      {showData && <MyExpensesSheet setShowData={setShowData} />}
    </div>
  );
};

export default MyExpenses;
