import React, { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useActiveDB } from '../../../hooks/useActiveDB';
import './Match.css';
import MatchTabs from './MatchTabs';
import { loadBankStatement } from './Util';
// import { CreditCardRecord, MatchRecordEntry } from '../types';
// import { parseCreditCardRecrods } from '../Util';
// import MatchedRecords from './MatchedRecords';
// import UnmatchedRecords from './UnmatchedRecords';

type MyProps = {
  showMatch: boolean;
  setShowMatch: Dispatch<SetStateAction<boolean>>;
};
const Match: FC<MyProps> = ({ showMatch, setShowMatch }) => {
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const { databaseName, displayName } = useActiveDB();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const handleUpload = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (selectedFiles) {
      var reader = new FileReader();

      reader.onload = function (evt) {
        if (evt && evt.target && evt.target.result) {
          loadBankStatement(
            databaseName!,
            displayName!,
            dispatch,
            evt.target.result as string,
          );
        }
      };

      reader.onerror = function (evt) {
        console.error('An error ocurred reading the file', evt);
      };

      reader.readAsText(selectedFiles, 'UTF-8');
    }
  }, [selectedFiles]);
  const onChange = (event: React.FormEvent) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      setSelectedFiles(files[0]);
    }
  };

  return (
    <Modal
      size='lg'
      style={{ paddingRight: '10px' }}
      centered
      show={showMatch}
      onHide={() => setShowMatch(false)}
      animation={true}>
      <Modal.Header>
        <Modal.Title>Auto Match</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='autoMatchContainer'>
          <div className='m-3'>
            <label className='mx-3'>Choose file: </label>
            <input
              ref={inputRef}
              className='d-none'
              type='file'
              onChange={onChange}
            />
            <button onClick={handleUpload} className='btn btn-outline-primary'>
              Upload
            </button>
            <MatchTabs />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={() => setShowMatch(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Match;
