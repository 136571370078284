export const SET_ESCROW_HASHTABLE = 'SET_ESCROW_HASHTABLE';
export const SET_ESCROW_TREE_DATA = 'SET_ESCROW_TREE_DATA';
export const SET_ESCROW_TREE = 'SET_ESCROW_TREE';
export const SET_MONTHLY_RECORDS = 'SET_MONTHLY_RECORDS';
export const RESET_ESCROW_TREE = 'RESET_ESCROW_TREE';
export interface Leaf {
  Label: string;
  Amount: number;
  Date?: string;
  AutoActionId?: number;
  IsOpened?: boolean;
  Children?: Leaf[];
  Context?: any;
}

export interface IEscrowExpense {
  Amount: number;
  ExpenseName: string;
  ExpenseTypeID: number;
  PropertyID: number;
  TransactionTime: string;
  ActionDate: string;
  AutoActionID?: string;
  Address?: string;
}

export type AutoAction = {
  AutoPaymentID: number;
  CC?: number;
  Address: string;
  ExpenseName: string;
  ActionDate: string;
  Amount: number;
  ToEscrow: number;
  PropertyID: number;
  ExpenseTypeID: number;
};

export type MonthlyRun = {
  AutoPaymentID: number;
  ExpenseTypeID: number;
  PropertyID: number;
  CC?: CreditCardEnum;
  Amount: number;
  ToEscrow: number;
  Comment: string;
  ExpenseName: string;
  Address: string;
};

export enum CreditCardEnum {
  None = 0,
  OnCreditCard = 1,
}

export enum ActionTypeEnum {
  NoAction = 'No Action',
  NewEntry = 'New Entry',
}
export enum ActionNameEnum {
  Escrow = 'Escrow',
  Payment = 'Payment',
}
export type ActionRecord = {
  ActionType: ActionTypeEnum;
  ActionName: ActionNameEnum;
  Address: string;
  ExpenseName: string;
  Amount: number;
  OnCreditCard: boolean;
};

export interface ISetEscrowTreeData {
  type: typeof SET_ESCROW_TREE_DATA;
  payload: IEscrowExpense[];
}

export interface ISetEscrowTree {
  type: typeof SET_ESCROW_TREE;
  payload: Leaf;
}

export interface ISetEscrowHashtable {
  type: typeof SET_ESCROW_HASHTABLE;
  payload: object;
}

export interface ISetMonthlyRecords {
  type: typeof SET_MONTHLY_RECORDS;
  payload: ActionRecord[];
}

export interface IResetEscrowTree {
  type: typeof RESET_ESCROW_TREE;
}

export type IEscrowActions =
  | ISetEscrowHashtable
  | ISetEscrowTreeData
  | ISetEscrowTree
  | ISetMonthlyRecords
  | IResetEscrowTree;
