import React from "react"
import { Button, Modal } from "react-bootstrap"

type MyProps = {
   ShowConfirmBox: boolean,
   CloseConfirmBox: () => void,
   PayMortgages:() => void
}
export const MortgageConfirmModal:React.FC<MyProps> = ({ShowConfirmBox,CloseConfirmBox,PayMortgages}) => {
    const PayAndClose = () => {
        PayMortgages();
        CloseConfirmBox();
    }
    return (
        <Modal show={ShowConfirmBox} onHide={CloseConfirmBox}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Mortgage Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Are you sure you want to pay mortgages?</h4>
                <br/>
                <h5>This is not the first of the month</h5>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={CloseConfirmBox}>
                    Cancel
                </Button>
            <Button variant="primary" onClick={PayAndClose}>
                    Yes, Pay Mortgage
                </Button>
            </Modal.Footer>
        </Modal>
    )
}