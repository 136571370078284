export const SET_SELECTED_DEVICE = 'setSelectedDevice';
export const ADD_DEVICE_RECORD = 'addDeviceRecord';
export const GET_ALL_DEVICES = 'getAllDevices';
export const DELETE_DEVICE = 'deleteDevice';
export const SET_DEVICE_FILTERS = 'setDeviceFilters';

export type DeviceType =
  | 'SmokeDetector'
  | 'HotWaterHeater'
  | 'Extinguisher'
  | 'BoilerService'
  | 'Range'
  | 'Refrigerator'
  | 'AntiFreeze'
  | 'SumpPump'
  | 'Battery';

export type BuildingName =
  | '75Monroe'
  | '476-478Middle'
  | '11-13Durrell'
  | '9Linden'
  | '';

export type FilterType = 'Building' | 'Apartment'; // might add more filters moving forwards
export type SingleDevice = { label: string; id: DeviceType };
export type DeviceFilter = { Filter: FilterType; IsSelected: boolean };

export interface ISET_SELECTED_DEVICE {
  type: typeof SET_SELECTED_DEVICE;
  payload: DeviceType;
}

export interface IADD_DEVICE_RECORD {
  type: typeof ADD_DEVICE_RECORD;
  payload: DeviceRecord;
}

export interface IGET_ALL_DEVICES {
  type: typeof GET_ALL_DEVICES;
  payload: DeviceRecord[];
}

export interface IDELETE_DEVICE {
  type: typeof DELETE_DEVICE;
  payload: number;
}

export interface ISET_DEVICE_FILTERS {
  type: typeof SET_DEVICE_FILTERS;
  payload: DeviceFilter[];
}

export type deviceActionsType =
  | ISET_SELECTED_DEVICE
  | IADD_DEVICE_RECORD
  | IGET_ALL_DEVICES
  | IDELETE_DEVICE; // | .... | ..... etc

export type DeviceRecord = {
  InstallDate: Date;
  YearsInService: number;
  Device: DeviceType;
  Notes: string;
  Apartment?: string;
  Building?: string;
  status?: number;
  ID?: number;
};
