import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../reducers';
import { MovingOutProps } from '../MovingOutModal';

const MView = styled.div<{ showRight: boolean }>`
  border: 1px solid gray;
  display: grid;
  height: 200px;
  width: 464px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  left: ${(prop) => (prop.showRight ? '-464px' : '0')};
  transition: left 1s;
`;

const SingleMonth = styled.div<{ Zebra: number }>`
  background-color: ${(prop) =>
    prop.Zebra % 2 === 0 ? 'lightgray' : '#eeeeee'};
`;

const Address = styled.div`
  font-size: 10px;
  color: #0400ff;
  font-weight: bold;
  position: relative;
  top: 6px;
  padding-left: 5px;
  span {
    color: #ff2f00;
  }
`;
const MonthName = styled.div`
  font-weight: bold;
`;

const TenantName = styled.div`
  margin-left: 10px;
`;

const MonthlyView: React.FC<MovingOutProps> = (props: MovingOutProps) => {
  let monthlyViews = useSelector(
    (s: RootState) => s.propertyReducer.monthlyViews,
  );
  monthlyViews = monthlyViews.sort((a, b) => {
    if (a.Id > b.Id) return 1;
    if (a.Id < b.Id) return -1;
    return 0;
  });
  return (
    <MView showRight={props.showRightPane!}>
      {monthlyViews.map((mv, ndx) => {
        return (
          <SingleMonth key={ndx} Zebra={ndx}>
            <MonthName>{mv.Month}</MonthName>
            {mv.Tenants.map((t, ndx) => {
              return (
                <TenantName key={ndx}>
                  <div style={{ display: 'flex' }}>
                    {t.TenantName}
                    {t.Apartment && (
                      <Address>
                        {`[${t.Address}]`}
                        <span>{`(${t.Apartment})`}</span>
                      </Address>
                    )}

                    {!t.Apartment && <Address>{`[${t.Address}]`}</Address>}
                  </div>
                </TenantName>
              );
            })}
          </SingleMonth>
        );
      })}
    </MView>
  );
};

export default MonthlyView;
