import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useActiveDB } from '../../hooks/useActiveDB';
import {
  getAlltimeTenants,
  updateActiveTenant,
} from '../../modules/tenants/tenantsActions';
import { ITenant } from '../../modules/tenants/types';
import { RootState } from '../../reducers';
import { ListView } from './RentalPaymentControls';

type MyProps = {
  showManageTenants: boolean;
  closeMangeTenantsModal: () => void;
};

const CheckedLine = styled.div`
  display: flex;
  margin-left: 10px;
`;
export const ManageTenantsModal = (props: MyProps) => {
  const [showOnlyActive, setShowOnlyActive] = useState<boolean>(false);
  const [filteredTenants, setFilteredTenants] = useState<ITenant[]>(
    [] as ITenant[],
  );
  const dispatch = useDispatch();
  const { databaseName } = useActiveDB();
  const alltimeTenants = useSelector(
    (s: RootState) => s.tenantsReducer.alltimeTenants,
  );

  useEffect(() => {
    dispatch(getAlltimeTenants(databaseName!));
    // eslint-disable-next-line
  }, [props.showManageTenants]);
  useEffect(() => {
    if (!showOnlyActive) {
      setFilteredTenants(alltimeTenants);
    } else {
      setFilteredTenants(
        alltimeTenants.filter((t: ITenant) => Number(t.Active) === 1),
      );
    }
  }, [alltimeTenants, showOnlyActive]);
  const onSelected = (e, tenant: ITenant) => {
    const act = e.target.checked ? 1 : 0;
    dispatch(updateActiveTenant(databaseName!, act, Number(tenant.TenantID)));
  };

  const onShowOnlyActive = (e) => {
    setShowOnlyActive(e.target.checked);
  };
  return (
    <Modal show={props.showManageTenants} onHide={props.closeMangeTenantsModal}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Tenants</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListView>
          {filteredTenants.map((t: ITenant, ndx: number) => {
            return (
              <CheckedLine>
                <Form.Check
                  id={`${t.TenantID}`}
                  label={t.TenantName}
                  checked={Number(t.Active) === 1}
                  name={`${t.TenantID}`}
                  onClick={(e) => onSelected(e, t)}
                  type='switch'
                />
              </CheckedLine>
            );
          })}
        </ListView>
      </Modal.Body>
      <Modal.Footer>
        <CheckedLine>
          <Form.Check
            id='showOnlyActive'
            label='Show Only Active Tenants'
            checked={showOnlyActive}
            name='showOnlyActive'
            onClick={(e) => onShowOnlyActive(e)}
            type='switch'
          />
        </CheckedLine>
        <Button variant='primary' onClick={props.closeMangeTenantsModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
