import {
  ADD_JUST_PAID_ENTRY,
  GET_EXPENSE_TYPE_LIST,
  IExpenseType,
  IJustPaidExpense,
  ITransactionActions,
} from './types';

export interface ITransactionReducer {
  expenseTypeList: IExpenseType[];
  justPaidData: IJustPaidExpense[];
}
const INIT_STATE: ITransactionReducer = {
  expenseTypeList: [] as IExpenseType[],
  justPaidData: [] as IJustPaidExpense[],
};
export const transactionReducer = (
  state = INIT_STATE,
  action: ITransactionActions,
): ITransactionReducer => {
  switch (action.type) {
    case GET_EXPENSE_TYPE_LIST:
      return {
        ...state,
        expenseTypeList: action.payload,
      };
    case ADD_JUST_PAID_ENTRY:
      return {
        ...state,
        justPaidData: [action.payload, ...state.justPaidData],
      };
    default:
      return state;
  }
};
