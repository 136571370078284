import React, { FC } from 'react';
import { MatchRecordEntry } from '../types';

type MyProps = {
  matchedRecords: MatchRecordEntry[];
};
const MatchedRecords: FC<MyProps> = ({ matchedRecords }) => {
  return (
    <div className='GroupFrame'>
      <div className='GroupName'>Matched Records</div>
      <div className='MatchRecordsGrid '>
        <div className='MatchGridRow headerRow'>
          <div>CC Dates</div>
          <div>DB Dates</div>
          <div>DB Category</div>
          <div>CC Note</div>
          <div>Amount</div>
          <div>Card No.</div>
        </div>

        {matchedRecords.map((rec: MatchRecordEntry, key: number) => {
          const zebraClass = key % 2 === 0 ? 'zebra1' : 'zebra2';
          return (
            <div key={key} className={`MatchGridRow ${zebraClass}`}>
              <div className='ccDates'>
                <div>{rec.CCTransactionDate}</div>
                <div>{rec.CCPostedDate}</div>
              </div>
              <div className='field'>{rec.DBDate}</div>
              <div className='field'>{rec.DBNote}</div>
              <div className='field'>{rec.CreditCardNote}</div>
              <div className='field'>{rec.Amount}</div>
              <div className='field'>{rec.CardNo}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatchedRecords;
