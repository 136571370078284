import React, { FC } from 'react';
import { CreditCardRecord } from '../types';

type MyProps = {
  ccRecords: CreditCardRecord[];
};
const UnmatchedRecords: FC<MyProps> = ({ ccRecords }) => {
  const records = ccRecords ? ccRecords : [];
  return (
    <div className='GroupFrame'>
      <div className='GroupName'>UnMatched Records</div>
      <div className='MatchRecordsGrid '>
        <div className='UnMatchGridRow headerRow'>
          <div></div>
          <div>CC Dates</div>
          <div>CC Note</div>
          <div>Amount</div>
          <div>Card No.</div>
        </div>

        {records
          .filter((r: CreditCardRecord) => r.foundKey === undefined)
          .map((rec: CreditCardRecord, key: number) => {
            const zebraClass = key % 2 === 0 ? 'zebra1' : 'zebra2';
            const factor = rec.isDebit ? 1 : -1;
            return (
              <div key={key} className={`UnMatchGridRow ${zebraClass}`}>
                <div className='field'>{key + 1}</div>
                <div className='ccDates'>
                  <div>{rec.TransactionDate}</div>
                  <div>{rec.PostedDate}</div>
                </div>
                <div className='field'>{rec.Description}</div>
                <div className='field'>{factor * rec.Amount}</div>
                <div className='field'>{rec.CardNo}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UnmatchedRecords;
