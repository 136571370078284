import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ITenant } from '../../../modules/tenants/types';
import { useActiveDB } from '../../../hooks/useActiveDB';
import { useForm } from '../../../hooks/useForm';
import { formatDate } from '../../../Utils/datesUtil';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { updateTenantsAction } from '../../../modules/tenants/tenantsActions';
//import { showAllProm } from './promisses';

//showAllProm().then((aa) => {});
const BodyContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  min-width: 600px;
  textarea {
    width: 100%;
    @media (max-width: 600px) {
      width: 50%;
    }
  }
  input[name='IsReal'] {
    width: 100px;
    z-index: 1000;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const RequiredField = styled.div<{ required: boolean }>`
  border: ${(props) => {
    return props.required ? '3px solid red' : 'none';
  }};
  width: max-content;
  border-radius: 5px;
`;

type MyProps = {
  showTenantsManagement: boolean;
  selectedTenant: ITenant;
  setSelectedTenant: any;
  propertyID: number;
  closeWindow: () => void;
};

const TenantsManagement: React.FC<MyProps> = (props: MyProps) => {
  const { databaseName } = useActiveDB();
  //const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  let {
    selectedTenant: {
      TenantName,
      RentAmount,
      Phone1,
      Phone2,
      Email,
      LeaseExpirationDate,
      IsReal,
      Comment,
      TenantID,
      PropertyID,
      Apartment,
    },
  } = props;
  const [data, setData] = useState<ITenant>(props.selectedTenant);
  const { onChange } = useForm(data, setData);

  useEffect(() => {
    if (!data.TenantID) {
      onReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const initData: ITenant = {
      TenantName,
      RentAmount,
      Phone1,
      Phone2,
      Email,
      LeaseExpirationDate,
      IsReal,
      Comment,
      TenantID,
      PropertyID,
      Apartment,
    };
    setData(initData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTenant]);

  const onReset = () => {
    const ndata = { ...data };
    ndata.Comment = '';
    ndata.Email = '';
    ndata.IsReal = 0;
    ndata.LeaseExpirationDate = formatDate('');
    ndata.PaidAmount = undefined;
    ndata.Phone1 = '';
    ndata.Phone2 = '';
    ndata.TenantID = -1;
    ndata.SecurityDeposits = [];
    ndata.TenantName = '';
    ndata.RentAmount = 0;
    ndata.PropertyID = props.propertyID;
    setData(ndata);
  };

  const handleClose = () => {
    props.setSelectedTenant({});
    props.closeWindow();
  };

  const onSubmit = () => {
    try {
      dispatch(updateTenantsAction(data, databaseName!));
      handleClose();
    } catch (e) {
      //TODO: add global error handler
    }
  };

  const isDisabled = (): boolean => {
    let forceEmail = data.TenantID === -1;
    let emailValidation = forceEmail ? data.Email : true;
    return !(
      data.TenantName &&
      data.RentAmount &&
      emailValidation &&
      data.Phone1
    );
  };
  const onRealTenantClick = (e) => {
    const d = { ...data };
    d.IsReal = e.target.checked ? 1 : 0;
    setData(d);
  };

  return (
    <Modal
      size='lg'
      style={{ paddingRight: '10px' }}
      centered
      show={props.showTenantsManagement}
      onHide={handleClose}
      animation={true}>
      <Modal.Header>
        <Modal.Title>Tenants Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BodyContent>
          <div>
            <div>Name:</div>
            <div>
              <RequiredField required={data.TenantName === ''}>
                <input
                  name='TenantName'
                  value={data.TenantName}
                  onChange={onChange}
                />
              </RequiredField>
            </div>
          </div>

          <div>
            <div>Rent:</div>
            <div>
              <RequiredField required={data.RentAmount === 0}>
                <input
                  name='RentAmount'
                  value={data.RentAmount}
                  data-numeric={true}
                  onChange={onChange}
                  autoComplete='off'
                  onFocus={(e) => e.target.select()}
                />
              </RequiredField>
            </div>
          </div>

          <div>
            <div>Phone1:</div>
            <RequiredField required={data.Phone1 === ''}>
              <div>
                <input name='Phone1' value={data.Phone1} onChange={onChange} />
              </div>
            </RequiredField>
          </div>

          <div>
            <div>Email:</div>
            <RequiredField required={data.Email === ''}>
              <div>
                <input name='Email' value={data.Email} onChange={onChange} />
              </div>
            </RequiredField>
          </div>

          <div>
            <div>Phone2:</div>
            <div>
              <input name='Phone2' value={data.Phone2} onChange={onChange} />
            </div>
          </div>

          <div>
            <div>Expiration Date:</div>
            <div>
              <TextField
                id='date'
                label='End of Lease'
                type='date'
                name='LeaseExpirationDate'
                value={formatDate(data.LeaseExpirationDate!, true)}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
                variant='standard'
              />
            </div>
          </div>

          <div>
            <Form.Check
              className='ml-3 mb-3'
              type='switch'
              label='Real Tenant'
              name='IsReal'
              onChange={onRealTenantClick}
              checked={Number(data.IsReal) === 1}
            />
          </div>
          <div>
            <div>Apartment:</div>
            <div>
              <input
                name='Apartment'
                value={data.Apartment}
                onChange={onChange}
              />
            </div>
          </div>
          <div>
            <div>Message:</div>
            <textarea
              name='Comment'
              value={data.Comment}
              onChange={onChange}></textarea>
          </div>
        </BodyContent>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={onSubmit} disabled={isDisabled()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TenantsManagement;
