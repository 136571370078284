import styled from 'styled-components';

export const RowGrid = styled.div<{ Record: boolean; Check?: string }>`
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr 3fr;
  div{
    border: ${(prop) => (prop.Record ? '2px solid gray' : '2px solid white')} ;
    background-color:${(prop) => (prop.Record ? 'white' : 'rgb(108,117,125)')};
    color:${(prop) => {
      if (!prop.Record) {
        return 'black';
      } else {
        if (prop.Check) {
          return 'Red';
        }
        return 'blue';
      }
    }}
`;

export const TableCol2 = styled.div<{
  width: number;
  hideMobile?: boolean;
  mobileWidth?: number;
}>`
  flex: ${(props) => (props.mobileWidth ? props.mobileWidth : props.width)};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 136px;
  overflow: hidden;
  @media (max-width: 600px) {
    visibility: ${(props) => (props.hideMobile ? 'hidden' : 'visible')};
  }
`;
