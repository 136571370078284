import {
  ActionRecord,
  IEscrowActions,
  IEscrowExpense,
  Leaf,
  RESET_ESCROW_TREE,
  SET_ESCROW_HASHTABLE,
  SET_ESCROW_TREE,
  SET_ESCROW_TREE_DATA,
  SET_MONTHLY_RECORDS,
} from './types';

interface IEscrowReducer {
  expenseEscrows: object;
  escrows: IEscrowExpense[];
  escrowTree: Leaf;
  monthlyRuns: ActionRecord[];
}
const INIT_STATE: IEscrowReducer = {
  expenseEscrows: {},
  escrows: [],
  monthlyRuns: [],
  escrowTree: { Label: 'Loading ...' } as Leaf,
};

export const escrowReducer = (state = INIT_STATE, action: IEscrowActions) => {
  switch (action.type) {
    case SET_ESCROW_HASHTABLE:
      return {
        ...state,
        expenseEscrows: action.payload,
      };
    case SET_ESCROW_TREE_DATA:
      return {
        ...state,
        escrows: action.payload,
      };
    case SET_ESCROW_TREE:
      return {
        ...state,
        escrowTree: action.payload,
      };
    case SET_MONTHLY_RECORDS:
      return {
        ...state,
        monthlyRuns: action.payload,
      };
    case RESET_ESCROW_TREE:
      return INIT_STATE;
    default:
      return state;
  }
};
