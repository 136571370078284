import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getExpenseTypeList } from '../../modules/transactions/transactionActions';
import { Leaf } from '../../modules/escrow/types';
import { TreeFragment } from './TreeFragment';
import { useActiveDB } from '../../hooks/useActiveDB';
import LoadingDrape from '../Shared/LoadingDrape';

// @ts-ignore
type MyProps = {};

export const TreeContainer = styled.div`
  height: 449px;
  overflow-y: scroll;
  background-color: whitesmoke;
  @media (max-width: 600px) {
    border: 1px solid grey;
    height: 200px;
    overflow-y: auto;
  }
`;

const EscrowEntries: React.FC<MyProps> = (MyProps) => {
  const dispatch = useDispatch();
  const showLoaderValue = useSelector(
    (state: RootState) => state.propertyReducer.showLoader,
  );
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const { databaseName } = useActiveDB();
  const escrowTree: Leaf = useSelector(
    (state: RootState): Leaf => state.escrowReducer.escrowTree,
  );

  useEffect(() => {
    setShowLoader(showLoaderValue);
  }, [showLoaderValue]);

  useEffect(() => {
    if (escrowTree.Label === 'Loading ...') {
      dispatch(getExpenseTypeList(databaseName!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseName]);

  return (
    <div className='flex-grow-1'>
      <Container fluid>
        <Row>
          <Col>
            <TreeContainer>
              <LoadingDrape IsVisible={showLoader} />
              <TreeFragment node={escrowTree} spaces={0} />
            </TreeContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EscrowEntries;
