import React, { FC, useEffect } from 'react';
import './MyExpenses.css';
import DataTable from './DataTable';
import { CURRENT_PAGE } from '../../../Utils/Constants';
import { NormalizedData } from '../Types';
import ExpenseBanner from './ExpenseBanner';
import { MyExpenseType } from '../MyExpenses';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { setActiveJson } from '../../../modules/myExpenses/expensesSlice';
import TotalsTable from './TotalsTable';
import { Dispatch } from '@reduxjs/toolkit';
import { NormalizedAllData } from '../../../modules/myExpenses/types';
import { calculateTotals } from '../utils';

const MyExpensesSheet: FC<MyExpenseType> = ({ setShowData }) => {  
  const dispatch = useDispatch();
  const activeJson = useSelector(
    (state: RootState) => state.myExpensesReducer.activeJson,
  );
  useEffect(() => {
    localStorage.setItem(CURRENT_PAGE, 'MyExpenses');
  }, []);

  useEffect(() => {
    calculateTotals(dispatch,activeJson)
  }, [activeJson]);

  const updateCellData = (item: NormalizedData) => {
    if (item.Key.startsWith('Exp')) {
      //expenses
      updateSpecificDataArray(item,'expenses'); 
    } else if (item.Key.startsWith('Rent')) {
      //income
      updateSpecificDataArray(item,'incomes');
    } else {
      //mortgage
      updateSpecificDataArray(item,'mortgages');
    }
  };

  const updateSpecificDataArray = (item: NormalizedData, dataArray: string) => {
    let total = 0;
    let address = item.Address;
    const path = `[${item.Source}].${dataArray}`;
    const newData = _.get(activeJson,path);
    const _dataArray = newData.normalizedDataArr.map((exitingItem: NormalizedData) => {
      if (item.Key === exitingItem.Key) {
        total+=item.Amount;
        return item;
      }
      if(exitingItem.Address === address){
         total+=exitingItem.Amount;
      }
     
      return exitingItem;
    });
     
    console.log(total);
    const _activeJson = {
      ...activeJson,
      [item.Source]: { ...activeJson[item.Source], [dataArray]: {normalizedDataArr: _dataArray}}
    }

    dispatch(setActiveJson(_activeJson));
  }

  if (!activeJson.dka || !activeJson.house) {
    return null;
  }
  return (
    <>
      <ExpenseBanner setShowData={setShowData} activeJson={activeJson} />
      <div className='myExpensesWrapper'>
        <TotalsTable />         
      </div>
      {/*First Row*/}
      <div className='myExpensesWrapper expenseData'>
        <div className='dkaData groupBlock'>
          <DataTable
            data={activeJson.dka.expenses}
            normalData='Expenses'
            updateCellData={updateCellData}
          />
        </div>
        <div className='houseData groupBlock'>
          <DataTable
            data={activeJson.house.expenses}
            normalData='Expenses'
            updateCellData={updateCellData}
          />
        </div>
        {/*Second Row*/}
        <div className='dkaData groupBlock'>
          <DataTable
            data={activeJson.dka.incomes}
            normalData='Rent'
            updateCellData={updateCellData}
          />
        </div>
        <div className='houseData groupBlock'>
          <DataTable
            data={activeJson.house.incomes}
            normalData='Rent'
            updateCellData={updateCellData}
          />
        </div>

        {/*Third Row*/}
        <div className='dkaData groupBlock'>
          <DataTable
            data={activeJson.dka.mortgages}
            normalData='Mortgage'
            updateCellData={updateCellData}
          />
        </div>
        <div className='houseData groupBlock'>
          <DataTable
            data={activeJson.house.mortgages}
            normalData='Mortgage'
            updateCellData={updateCellData}
          />
        </div>
      </div>
    </>
  );
};

export default MyExpensesSheet;


