import { useSelector } from 'react-redux';
import { getPropertyList } from '../modules/properties/propertyActions';
import { IDataBase } from '../modules/properties/types';
import { RootState } from '../reducers';

export const useActiveDB = () => {
  const selecteDatabaseID = useSelector(
    (s: RootState) => s.propertyReducer.selectedDatabaseID,
  );
  const allDatabases = useSelector(
    (s: RootState) => s.propertyReducer.databases,
  );
  const selectedDatabase = allDatabases.find(
    (d: IDataBase) => d.ID === selecteDatabaseID,
  );
  const databaseName = selectedDatabase?.DatabaseName;
  const displayName = selectedDatabase?.DisplayName;
  const selectedPropertyId = useSelector(
    (state: RootState) => state.propertyReducer.selectedProperty,
  );
  const propertyList = useSelector(
    (state: RootState) => state.propertyReducer.propertyList,
  );
  getPropertyList(databaseName!);
  let selectedProperty = propertyList.find(
    (property) => Number(property.PropertyID) === Number(selectedPropertyId),
  );

  return {
    selectedDatabase,
    databaseName,
    selectedProperty,
    propertyList,
    allDatabases,
    displayName,
  };
};
