import React, { useState } from "react";
import {Button, Modal} from "react-bootstrap";
import {ITenantMovingOut} from "../../modules/tenants/types";
import MovingOutList from "./MovingOut/MovingOutList";
import styled from "styled-components";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MonthlyView from "./MovingOut/MonthlyView";

export type MovingOutProps = {
    showMovingOut:boolean,
    setShowMovingOut:(show:boolean) => void,
    movingOuts:ITenantMovingOut [],
    showRightPane?:boolean
}
const MovingOutWrapper = styled.div`
    border: 1px solid gray;
    overflow:hidden;
    svg{
        cursor: pointer;
        zoom:1.5;
    }
`;

const Arrows = styled.div`
  display:flex;
  justify-content:space-between;
  font-weight:bold;
  div{
      cursor:pointer;
  }
`;

const Slider = styled.div`
    display: flex;
    width: 928px;
`;
const CurrentViewPadding = styled.span`
  padding-left:10px;
`;
export const MovingOutModal:React.FC<MovingOutProps> = (props:MovingOutProps) => {
    const [showRight,setShowRight] = useState<boolean>(false);
    return (
        <Modal show={props.showMovingOut} onHide={()=> props.setShowMovingOut(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Moving out report.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <MovingOutWrapper> 
                    <Arrows>
                      {!showRight &&
                       <div onClick={()=>setShowRight(true)} ><ArrowBackIcon/><span onClick={()=>setShowRight(false)}>Monthly View</span></div>
                      }
                        
                      {showRight &&
                        <div onClick={()=>setShowRight(false)}><CurrentViewPadding>Current View</CurrentViewPadding> <ArrowForwardIcon /></div>
                      }
                    </Arrows>
                    <Slider>
                        <MovingOutList showRightPane={showRight} {...props} />  
                        <MonthlyView showRightPane={showRight} {...props} />
                    </Slider>
                    
                </MovingOutWrapper>             
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={()=> props.setShowMovingOut(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}