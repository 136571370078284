import {
  ADD_DEVICE_RECORD,
  DELETE_DEVICE,
  deviceActionsType,
  DeviceRecord,
  GET_ALL_DEVICES,
  SET_SELECTED_DEVICE,
  SingleDevice,
} from './types';

export type IDevicesReducer = {
  selectedDevice: SingleDevice;
  records: DeviceRecord[];
};

const INIT_STATE: IDevicesReducer = {
  selectedDevice: { label: '', id: 'SmokeDetector' },
  records: [],
};

export const devicesReducer = (
  state: IDevicesReducer = INIT_STATE,
  action: deviceActionsType,
): IDevicesReducer => {
  switch (action.type) {
    case SET_SELECTED_DEVICE:
      return { ...state };
    case ADD_DEVICE_RECORD:
      const newRecords = [action.payload, ...state.records];
      return { ...state, records: newRecords };
    case GET_ALL_DEVICES:
      return { ...state, records: action.payload as DeviceRecord[] };
    case DELETE_DEVICE:
      const reducedRecords = state.records.reduce(
        (arr: DeviceRecord[], prevValue: DeviceRecord) => {
          if (prevValue.ID !== action.payload) {
            arr.push(prevValue);
          }
          return arr;
        },
        [],
      );
      return { ...state, records: reducedRecords };
    default:
      return state;
  }
};
