import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { calculateBalance } from '../../modules/balance/balanceActions';
import { IPropertyBalance, ITableEntry } from '../../modules/balance/types';

const PropertyWrapper = styled.div`
  display:grid;
  grid-template-columns: repeat(9,1fr);
  grid-template-rows: repeat(8,1fr);  
  .address{
    grid-column: 1/6;
    font-weight:bold;
    border-top:2px solid #000;
  }
  .cell{
    font-weight:bold;
    font-size:14px;
  }
  .total-all{
      border-top:2px solid #000;
      color:#000; 
      font-weight:bold;     
  }
  .numeric-cell{
     grid-column-start: span 4;     
  }
  .type-space{
      grid-column-start: span 2;
  }
  .type-name{
    grid-column-start: span 3;
  }
  .Deposit, .Income{
      color: green;     
  }
  .Expense, .Mortgage, .Withdrawal{
      color:red;
  }
  .Escrow{
      color:rgb(221,169,11);
  }
  .Total{
      color:blue;
  }
  .balance{
      color:#000;
  }
`;

type NumericLineData = {
   TypeName: string,
   NumericValue: number,
   ClassName: string
}
const NumericLine:React.FC<NumericLineData> = ({TypeName,NumericValue,ClassName}) => {
    return (
       <>
         <div className="type-space">
            </div>
            <div className={`cell type-name ${ClassName}`}>
                {TypeName}
            </div>
            <div className={`cell numeric-cell ${ClassName}`}>               
                {`${Number(NumericValue).toFixed(2)}`}
            </div>
       </>
    );
}

type PropertyRecordProps = {
    Property: IPropertyBalance
}

const PropertyRecord:React.FC<PropertyRecordProps> = ({Property}) => {
    const [balance, setBalance] = useState<number>(0);
    useEffect(()=>{
        const t:number = calculateBalance(Property)                
        setBalance(t);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])       
    return (
        <PropertyWrapper>
            <div className="address">{Property.Address}</div><div className="numeric-cell total-all">${Number(balance).toFixed(2)}</div>
            {Property.Table.map((value:ITableEntry,ndx:number)=>{                
                return <NumericLine key={ndx} TypeName={value.Name} NumericValue={value.Amount} ClassName={value.Name} />
            })}                        
            
        </PropertyWrapper>
    );
};

export default PropertyRecord;