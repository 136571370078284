import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useActiveUser } from '../../hooks/useActiveUser';
const ProtectedRoute = (props) => {    
    const userName = useActiveUser();
    if (userName) {
        return <Route {...props} />
    }

    return <Redirect to='/login' />
}

export default ProtectedRoute