import { IStatementLine } from '../modules/statement/types';
import { headerKeys } from '../components/Statement/StatementEntries';
//TODO: adding sorting for the comments and for the dates
export const SortStatementLines = (
  lines: IStatementLine[],
  toggleValue: number,
  selectedHeaderKey: headerKeys,
): IStatementLine[] => {
  let asc: number;
  let desc: number;
  if (toggleValue % 3 === 1) {
    asc = 1;
    desc = -1;
  }

  if (toggleValue % 3 === 2) {
    asc = -1;
    desc = 1;
  }

  if (toggleValue % 3 === 0) {
    return lines;
  }

  switch (selectedHeaderKey) {
    case headerKeys.widthdrawal:
      return lines.sort((a, b) => {
        if (parseFloat(getAmount(a)) > parseFloat(getAmount(b))) return asc;
        if (parseFloat(getAmount(a)) < parseFloat(getAmount(b))) return desc;
        return 0;
      });
    case headerKeys.checkNum:
      return lines.sort((a, b) => {
        if (getCheck(a) > getCheck(b)) return asc;
        if (getCheck(a) < getCheck(b)) return desc;
        return 0;
      });
    case headerKeys.deposit:
      return lines.sort((a, b) => {
        if (parseFloat(getDeposit(a)) > parseFloat(getDeposit(b))) return asc;
        if (parseFloat(getDeposit(a)) < parseFloat(getDeposit(b))) return desc;
        return 0;
      });
    default:
      return lines;
  }
};

const getAmount = (line: IStatementLine): string => {
  const { eAmount, mortgageAmount, wAmount } = line;
  if (eAmount) return `${eAmount}`;
  if (mortgageAmount) return `${mortgageAmount}`;
  if (wAmount) return `${wAmount}`;
  return '0';
};

const getCheck = (line: IStatementLine): string => {
  if (line.wCheckNum) return line.wCheckNum;
  if (line.mortgageCheck) return line.mortgageCheck;
  if (line.eCheckNumber) return line.eCheckNumber;
  return '';
};

const getDeposit = (line: IStatementLine): string => {
  if (line.rentAmount) return `${line.rentAmount}`;
  if (line.depositAmount) return `${line.depositAmount}`;
  return '0';
};
