import { http } from '../../Utils/http';
import {
  APPLY_AMOUNT_CHANGE,
  APPLY_STATEMENT_CHANGES,
  CHECK_LINE,
  CLEAR_STATEMENT,
  GET_STATEMENT_LINES,
  IApplyAmountChangeAction,
  IApplyStatmentChangesAction,
  IStatementLine,
  StatementActionsType,
} from './types';
import { Dispatch } from 'react';
import { ROOT_URL } from '../../Utils/Constants';
import { RootState } from '../../reducers';

export const getStatementLinesAction =
  (showAll: boolean, databaseName) =>
  async (dispatch: Dispatch<StatementActionsType>) => {
    let url: string = `${ROOT_URL}/statement/statement.php?db_name=${databaseName}&showAll=${
      showAll ? 'true' : 'false'
    }`;
    let result = await http(url, dispatch);
    dispatch({
      type: GET_STATEMENT_LINES,
      payload: result,
    });
    return Promise.resolve();
  };

export const checkStatementLineAction = (
  cronid: number,
  isChecked: boolean,
) => ({
  type: CHECK_LINE,
  payload: { cronid, isChecked },
});

export const clearStatementLines = () => ({
  type: CLEAR_STATEMENT,
});
export const applyStatmentChangesAction =
  (db: string) =>
  async (dispatch: Dispatch<IApplyStatmentChangesAction>, getState: any) => {
    const state = getState();
    const cronIds: number[] = state.statementReducer.statementLines.reduce(
      (arr: number[], line: IStatementLine) => {
        if (line.reviewed === 1) {
          arr.push(line.cronid);
        }
        return arr;
      },
      [],
    );

    if (cronIds.length > 0) {
      let url: string = `${ROOT_URL}/statement/update.php?db_name=${db}&cronIds=${cronIds.join(
        ',',
      )}`;
      let result = await http(url, dispatch);
      if (result.errorInfo) {
        //TODO: add error message
      } else {
        dispatch({
          type: APPLY_STATEMENT_CHANGES,
          payload: cronIds,
        });
      }
    }
  };

  export const applyAmountChange = (db: string,editedAmount:number,statementLine:IStatementLine) =>
  async (dispatch: Dispatch<IApplyAmountChangeAction>, getState: ()=>RootState) => {
    let _type = "";
    if(statementLine.eAmount){
      _type = "expense";
    } else if(statementLine.mortgageAmount){
      _type = "mortgage";
    } else {
      return;
    }
    let url: string = `${ROOT_URL}/match/updateMatchLine.php?`;
    url = url + `db_name=${db}`;
    url = url + `&cronid=${statementLine.cronid}`
    url = url + `&amount=${editedAmount}`;
    url = url + `&type=${_type}`;
    let result = await http(url, dispatch);
    if (result.errorInfo) {
      //TODO: add error message
    } else {
      const _lines = getState().statementReducer.statementLines;
      const lines = _lines.map(line => {
          if(line.cronid === statementLine.cronid){
            return {
              ...line,
              eAmount: editedAmount
            }
          }
          return line;
        });
      dispatch({
          type: APPLY_AMOUNT_CHANGE,
          payload: lines
      })
   
    }


    
  }
 

 