import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { IPropertyBalance } from '../../modules/balance/types';
import { RootState } from '../../reducers';
import PropertyRecord from './PropertyRecord';
import { BodyContent } from './Styles';

type MyProps = {
    ShowPopup:boolean,
    ShowBalancesTable:any
}
const BalancePopup:React.FC<MyProps> = ({ShowPopup,ShowBalancesTable}) => {
    const propertyBalances = useSelector((state:RootState) => state.balanceReducer.propertyBalances)    
    return (
        <Modal  centered show={ShowPopup} onHide={() => ShowBalancesTable(false)} animation={true}>
        <Modal.Header >
            <Modal.Title>Account Balances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <BodyContent>
          {
              propertyBalances.map((value:IPropertyBalance,ndx:number)=> {
               return (<PropertyRecord key={ndx} Property={value} />);
              })
          }
                       
        </BodyContent>
        
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => ShowBalancesTable(false)}>
                Close
                </Button>
        </Modal.Footer>
    </Modal> 
    );
};

export default BalancePopup;