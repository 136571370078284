import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import detector from './Images/smokedetector.png';
import heater from './Images/hotwaterheater.png';
import extinguisher from './Images/extinguisher.png';
import boilder from './Images/boiler.png';
import range from './Images/range.png';
import sump from './Images/sump.jpg';
import battery from './Images/fireSystemBattery.png';
import refrigerator from './Images/refrigerator.png';
import flake from './Images/flake.png';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { FC, useEffect, useState } from 'react';
import { CURRENT_PAGE } from '../../Utils/Constants';
import {
  BuildingName,
  DeviceRecord,
  DeviceType,
} from '../../modules/devices/types';
import { DeviceRecords } from './DeviceRecords';
import { ButtonWrapper, DevicesWrapp, ImgElem } from './Devices.style';
import { FilterPopup } from './FilterPopup';
import { Buildings } from './Buildings';
import { Apartments } from './Apartments';
import { addDeviceRecord } from '../../modules/devices/deviceActions';
import { useDispatch } from 'react-redux';
import { useActiveDB } from '../../hooks/useActiveDB';

const Devices: FC = () => {
  const [device, setDevice] = useState<DeviceType>('SmokeDetector');
  const [apartment, setApartment] = useState<string>('');
  const [installDate, setInstallDate] = useState<any>(null);
  const [yearsInService, setYearsInService] = useState<any>(0);
  const [building, setBuilding] = useState<BuildingName>('');
  const [notes, setNotes] = useState<string>('');
  const [showCursorPopup, setShowCursorPopup] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { databaseName } = useActiveDB();
  useEffect(() => {
    localStorage.setItem(CURRENT_PAGE, 'Devices');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setDevice(event.target.value as DeviceType);
  };

  const addRecord = async () => {
    if (building === '' || installDate === null || yearsInService === 0) {
      return;
    }
    console.log(
      `device:${device},apartment:${apartment},installDate:${installDate},yearsInService:${yearsInService},building:${building}`,
    );
    const newRecord: DeviceRecord = {
      InstallDate: installDate,
      YearsInService: yearsInService,
      Notes: notes,
      Device: device,
      Apartment: apartment,
      Building: building,
    };
    dispatch(addDeviceRecord(newRecord, databaseName!));
  };
  return (
    <>
      <h2>Devices</h2>
      <DevicesWrapp>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Device</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={device}
            label='Device'
            onChange={handleChange}>
            <MenuItem value='SmokeDetector'>Smoke Detector</MenuItem>
            <MenuItem value='HotWaterHeater'>Hot water Heater</MenuItem>
            <MenuItem value='Extinguisher'>Extinguesher</MenuItem>
            <MenuItem value='BoilerService'>Boiler Service</MenuItem>
            <MenuItem value='Range'>Range</MenuItem>
            <MenuItem value='Refrigerator'>Refrigerator</MenuItem>
            <MenuItem value='AntiFreeze'>AntiFreeze</MenuItem>
            <MenuItem value='SumpPump'>SumpPump</MenuItem>
            <MenuItem value='Battery'>Fire Panel Battery</MenuItem>
          </Select>
        </FormControl>

        <div>
          {device === 'SmokeDetector' && <ImgElem src={detector} />}
          {device === 'HotWaterHeater' && <ImgElem src={heater} />}
          {device === 'Extinguisher' && <ImgElem src={extinguisher} />}
          {device === 'BoilerService' && <ImgElem src={boilder} />}
          {device === 'Range' && <ImgElem src={range} />}
          {device === 'Refrigerator' && <ImgElem src={refrigerator} />}
          {device === 'AntiFreeze' && <ImgElem src={flake} />}
          {device === 'SumpPump' && <ImgElem src={sump} />}
          {device === 'Battery' && <ImgElem src={battery} />}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '20px' }}></div>
          <Buildings
            value={building}
            setvalue={setBuilding}
            setApartment={setApartment}
          />
        </div>
        <div>
          <Apartments
            value={apartment}
            setvalue={setApartment}
            setBuilding={setBuilding}
          />
        </div>
        <div>
          <TextField
            error={installDate === null}
            id='date'
            label='Install Date'
            type='date'
            name='InstallDate'
            value={installDate}
            onChange={(e) => {
              setInstallDate(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant='standard'
          />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <TextField
            error={yearsInService === 0}
            type='number'
            value={yearsInService}
            onChange={(e) => setYearsInService(e.target.value)}
            InputProps={{
              inputProps: {
                max: 20,
                min: 1,
              },
            }}
            style={{ width: '200px' }}
            label='Years In Service'
          />
        </div>
        <div>
          <TextField
            id='outlined-multiline-flexible'
            label='Add Notes'
            multiline
            maxRows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <ButtonWrapper>
          <div
            onClick={() => {
              setShowCursorPopup(true);
            }}>
            {' '}
            <Tooltip title='Filter' placement='right'>
              <FilterListIcon />
            </Tooltip>
          </div>
          <div onClick={addRecord}>
            {' '}
            <Tooltip title='Add Record' placement='right'>
              <PlaylistAddIcon />
            </Tooltip>
          </div>
          {showCursorPopup && (
            <FilterPopup setShowCursorPopup={setShowCursorPopup} />
          )}
        </ButtonWrapper>
      </DevicesWrapp>
      <DeviceRecords />
    </>
  );
};

export default Devices;
