import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import EscrowEntries from './EscrowEntries';
import AutoActions from './AutoActions';
import AddEscrowEntry from './AddEscrowEntry';
import { CURRENT_PAGE } from '../../Utils/Constants';

const Escrow: React.FC = () => {
  useEffect(() => {
    localStorage.setItem(CURRENT_PAGE, 'Escrow');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='flex-grow-1'>
      <Container fluid>
        <AddEscrowEntry />

        <Row className='border bg-dark text-light'>
          <Col lg={6} md={12}>
            <EscrowEntries />
          </Col>
          <Col lg={6} md={12}>
            <AutoActions />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Escrow;
