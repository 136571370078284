export const GET_TENANTS_LIST = 'TENANTS/GET_TENANTS_LIST';
export const MAKE_RENT_PAYMENT = 'TENANTS/MAKE_RENT_PAYMENT';
export const GET_ALLTIME_TENANTS = 'GET_ALLTIME_TENANTS';
export const UPDATE_ACTIVE_TENANT = 'UPDATE_ACTIVE_TENANT';

export interface ISecurityDeposit {
  TenantID: number;
  Apartment: string;
  Date: string;
  Amount: number;
  CheckNumber?: string;
  Comment?: string;
}

export interface IActiveTenant {
  TenantID: number;
  Active: number;
}

export interface ITenant {
  TenantName?: string;
  RentAmount?: number;
  PaidAmount?: number;
  Email?: string;
  TenantID?: number;
  PropertyID?: number;
  Phone1?: string;
  Phone2?: string;
  LeaseExpirationDate?: string;
  SecurityDeposits?: ISecurityDeposit[];
  Address?: string;
  IsReal?: number;
  Comment?: string;
  Apartment?: string;
  Active?: number;
}
export interface ITenantPayment {
  TenantID: number;
  Amount: number;
}

export interface IMonthlyView {
  Id: number;
  Key: string;
  Month: string;
  Tenants: ITenant[];
}

export interface ITenantMovingOut {
  TenantName: string | undefined;
  DayToMoveOut: number;
}

///// payloads interfaces
export interface ITenantsPaymentInfo {
  tenants: ITenant[];
  tenantsPayment: ITenantPayment[];
  securityDeposits: ISecurityDeposit[];
  errorInfo: any;
}

export interface IRentPayment {
  tenantId?: number;
  tenantName: string;
  propertyId?: number;
  amount: number;
  status?: string;
  comments?: string;
}

///// Actions interfaces
export interface IMakeRentPaymentAction {
  type: typeof MAKE_RENT_PAYMENT;
  payload: IRentPayment;
}

export interface IGetAlltimeTenants {
  type: typeof GET_ALLTIME_TENANTS;
  payload: ITenant[];
}

export interface IUpdateActiveTenantAction {
  type: typeof UPDATE_ACTIVE_TENANT;
  payload: IActiveTenant;
}

export type tenantsActionType =
  | IMakeRentPaymentAction
  | IGetAlltimeTenants
  | IUpdateActiveTenantAction;
