import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useActiveDB } from '../../hooks/useActiveDB';
import { useForm } from '../../hooks/useForm';
import { Leaf } from '../../modules/escrow/types';
import { IVendor } from '../../modules/vendors/types';
import { getVendorsListAction, setSelectedVendor, upsertVendor } from '../../modules/vendors/vendorsActions';
import { emptyVendor } from '../../modules/vendors/vendorsReducer';
import { RootState } from '../../reducers';
import { CURRENT_PAGE } from '../../Utils/Constants';
import { TreeContainer } from '../Escrow/EscrowEntries';
import { TreeFragment } from '../Escrow/TreeFragment';
import LoadingDrape from '../Shared/LoadingDrape';

const Row = styled.div`
  display:grid;
  grid-template-columns:50% 50%;
  @media(max-width:600px){
       grid-template-columns:100%;
       grid-template-rows:1fr 1fr;
       div{
           margin-bottom:5px;
       }
  }
`;
const Container = styled.div`
    display:grid;
    grid-template-columns:50% 50%;
    grid-template-rows:100%;
    @media(max-width:600px){
         grid-template-columns:100%;
    grid-template-rows:207px 50%;
    }
`;
const Controls = styled.div`
    display:grid;
    grid-template-columns:50% 50%;
    grid-template-rows:repeat(5,1fr);
    border: 1px solid gray;
    padding: 17px;
    @media(max-width:600px){
     display:flex;
     flex-direction:column;
     height:480px;
    }
`;

const SpanedControl = styled.div`
  grid-column-start: span 2;  
  display:grid;
  grid-template-columns:130px auto; 
`;

const WideText = styled.div`
    margin-top:5px;
    input{
      width: 86%;
      float: left;
      margin-left: -17px;
    }
  
`;
const RowedControl = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  padding:6px;
`;

const Col1 = styled.div`
    button{
        width: 300px;
        margin: auto;
    }   
`;

const Vendors:React.FC = () => {
    const [data, setData] = useState<IVendor>(emptyVendor);
    const { onChange } = useForm(data,setData);
    const {databaseName} = useActiveDB();
    const dispatch = useDispatch();
    const vendorsTree = useSelector((state:RootState)=> state.VendorsReducer.vendorsTree);
    const showLoader = useSelector((state:RootState)=> state.propertyReducer.showLoader);
    const selectedVendor = useSelector((state:RootState)=> state.VendorsReducer.selectedVendor);
    useEffect(()=>{       
        dispatch(getVendorsListAction(databaseName!));
        localStorage.setItem(CURRENT_PAGE,'Vendors');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[databaseName]);

    useEffect(()=>{
        if(selectedVendor){
            setData(selectedVendor);
        } else {
            setData(emptyVendor);
        }
        
    },[selectedVendor])
    const onTreeNodeClick = (node:Leaf) => {
        dispatch(setSelectedVendor(node.Context));        
    }

    const onReset = () => {
        dispatch(setSelectedVendor(undefined));  
    }

    const onSaveVendor = () => {
        let localData:IVendor = {...data};
        if(!data.Id){
            localData.Id = -1;
            setData(localData);
        }
        dispatch(upsertVendor(databaseName!,localData));
        dispatch(setSelectedVendor(undefined));  ;
    }

    return (
        <>
        <div className="flex-grow-1 ">
            <div className='border bg-dark text-light' >
                <h2>Vendors</h2>
                <Row style={{'paddingBottom': '8px'}} className="border bg-dark text-light pt-2">
                    <Col><Col1><Button onClick={onReset} block>Clear</Button></Col1></Col>
                    <Col><Col1><Button onClick={onSaveVendor} block>Save</Button></Col1></Col>                                        
                </Row>
            </div>
        </div>
        <Container>     
             <div>                
                <TreeContainer >
                    <LoadingDrape IsVisible={showLoader} />                    
                    <TreeFragment onClick={onTreeNodeClick} node={vendorsTree} spaces={0}/>                                         
                </TreeContainer>
            </div>       
            <Controls>
               <RowedControl>
                    <div>Vendor Name:</div>
                    <div><input name="Vendor" value={data.Vendor} onChange={onChange}/></div>
               </RowedControl>
               <RowedControl>
                    <div>Trade:</div>
                    <div><input name="Trade" value={data.Trade} onChange={onChange}/></div>
               </RowedControl>
               <RowedControl>
                    <div>Contact 1:</div>
                    <div><input name="Contact1" value={data.Contact1} onChange={onChange}/></div>
               </RowedControl>
               <RowedControl>
                    <div>Contact 2:</div>
                    <div><input name="Contact2" value={data.Contact2} onChange={onChange}/></div>
               </RowedControl>
               <RowedControl>
                    <div>Phone 1:</div>
                    <div><input name="Phone1" value={data.Phone1} onChange={onChange}/></div>
               </RowedControl>
               <RowedControl>
                    <div>Phone 2:</div>
                    <div><input name="Phone2" value={data.Phone2} onChange={onChange}/></div>
               </RowedControl>
               <SpanedControl>
                    <div>Address:</div>
                    <WideText><input name="Address" value={data.Address} onChange={onChange}/></WideText>
               </SpanedControl>
               <RowedControl>
                    <div>City:</div>
                    <div><input name="City" value={data.City} onChange={onChange}/></div>
               </RowedControl>
               <RowedControl>
                    <div>State:</div>
                    <div><input name="State" value={data.State} onChange={onChange}/></div>
               </RowedControl>
               <RowedControl>
                    <div>Zip code:</div>
                    <div><input name="Zipcode" value={data.Zipcode} onChange={onChange}/></div>
               </RowedControl>
               <SpanedControl>
                    <div>Comments:</div>
                    <WideText><input name="Comments" value={data.Comments} onChange={onChange}/></WideText>
               </SpanedControl>
            </Controls>           
        </Container>
        </>        
    );
};

export default Vendors;