import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { FC } from 'react';
import { BuildingName } from '../../modules/devices/types';

export const Apartments: FC<{
  value: string;
  setvalue: (v: string) => void;
  setBuilding: (v: BuildingName) => void;
}> = ({ value, setvalue, setBuilding }) => {
  const apartmentChange = (e) => {
    if (e.target.value.indexOf('durrell') > -1) {
      setBuilding('11-13Durrell');
    }
    if (e.target.value.indexOf('monroe') > -1) {
      setBuilding('75Monroe');
    }
    if (e.target.value.indexOf('middle') > -1) {
      setBuilding('476-478Middle');
    }
    if (e.target.value.indexOf('linden') > -1) {
      setBuilding('9Linden');
    }
    setvalue(e.target.value);
  };
  return (
    <FormControl>
      <InputLabel htmlFor='grouped-native-select'>Apartment</InputLabel>
      <Select
        native
        defaultValue=''
        id='grouped-native-select'
        label='Grouping'
        onChange={(e: any) => apartmentChange(e)}>
        <option aria-label='None' value={value} />
        <optgroup label='Monroe'>
          <option value={'monroe1'}>monroe 1</option>
          <option value={'monroe2'}>monroe 2</option>
          <option value={'monroe3'}>monroe 3</option>
          <option value={'monroe4'}>monroe 4</option>
          <option value={'monroe5'}>monroe 5</option>
          <option value={'monroe6'}>monroe 6</option>
          <option value={'monroe7'}>monroe 7</option>
          <option value={'ward'}>ward</option>
        </optgroup>
        <optgroup label='Middle'>
          <option value={'middle1'}>middle 1</option>
          <option value={'middle2'}>middle 2</option>
          <option value={'middle3'}>middle 3</option>
          <option value={'middle4'}>middle 4</option>
          <option value={'middle5'}>middle 5</option>
        </optgroup>
        <optgroup label='Linden'>
          <option value={'linden1'}>linden 1</option>
          <option value={'linden2'}>linden 2</option>
          <option value={'linden3'}>linden 3</option>
          <option value={'linden4'}>linden 4</option>
          <option value={'linden5'}>linden 5</option>
        </optgroup>
        <optgroup label='Durrell'>
          <option value={'durrell11A'}>durrell 11A</option>
          <option value={'durrell11B'}>durrell 11B</option>
          <option value={'durrell13'}>durrell 13</option>
        </optgroup>
      </Select>
    </FormControl>
  );
};
