import { Dispatch } from 'react';
import { ROOT_URL } from '../../Utils/Constants';
import { http, post } from '../../Utils/http';
import {
  GET_MORTGAGE_LIST,
  IMortgageActions,
  IMortgageEntry,
  POST_MORTGAGE_PAYMENTS,
} from './types';

export const getMortageList = (databaseName: string): any => async (
  dispatch: Dispatch<IMortgageActions>,
) => {
  let url: string = `${ROOT_URL}/mortgage/get.php?db_name=${databaseName}`;
  const mortgagesList: IMortgageEntry[] = await http(url, dispatch);
  dispatch({
    type: GET_MORTGAGE_LIST,
    payload: mortgagesList,
  });
};

export const postMortgagePayments = (
  mortgagesList: IMortgageEntry[],
  databaseName: string,
): any => async (dispatch: Dispatch<IMortgageActions>) => {
  let url: string = `${ROOT_URL}/mortgage/payMortgage.php?db_name=${databaseName}`;
  await post(url, mortgagesList, dispatch);
  dispatch({
    type: POST_MORTGAGE_PAYMENTS,
    payload: mortgagesList,
  });
};
