import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ajaxLoader from '../../resources/ajax-loader-t.gif';
import { useActiveDB } from '../../hooks/useActiveDB';
import { useActiveUser } from '../../hooks/useActiveUser';
import { calculateBalance, getBalanceData } from '../../modules/balance/balanceActions';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { RootState } from '../../reducers';
import { getExpenseTypeList } from '../../modules/transactions/transactionActions';
import BalancePopup from './BalancePopup';
import { BalanceFigures, BalanceRow, ButtonContainer, Icon } from './Styles';
import { EntryType, IPropertyBalance } from '../../modules/balance/types';
import styled from 'styled-components';

const ImgClosure = styled.div`
    width: 32px;
    height: 32px;   
    display: inline-table;
  img{
       width:28px;
  }
 
`;

const Balance = () => {
    const dispatch = useDispatch();
    const {databaseName} = useActiveDB();
    const userName = useActiveUser();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [pnlBalance, setPnlBalance] = useState<number>(0);
    const [lShowLoader, setLShowLoader] = useState<boolean>(true);
    const [accountBalance, setAccountBalance] = useState<number>(0);
    const expensesEscrows = useSelector((state:RootState) => state.escrowReducer.expenseEscrows);
    const propertyBalances = useSelector((state:RootState) => state.balanceReducer.propertyBalances);
    useEffect(()=>{        
        if (userName) {
            if (Object.keys(expensesEscrows).length === 0) {
                //loading the Escrow data in case it is not already loaded               
                dispatch(getExpenseTypeList(databaseName!));
                return;
            }
            dispatch(getBalanceData(databaseName!));            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userName,expensesEscrows]);  
  
    useEffect(()=>{        
        let balance=0;
        let escrow=0;
        propertyBalances.map((value:IPropertyBalance)=>{
            balance += calculateBalance(value);          
            escrow += value.Table.find(e => e.Name === EntryType.Escrow)!.Amount;
            return 0;
        })       
        //turning the spinny off
        if(propertyBalances.length > 0){
           setLShowLoader(false);
        }
       setAccountBalance(balance); 
       setPnlBalance(balance-escrow);     
    },[propertyBalances])
    
    const showBalancesTable = (isShowing:boolean) => {
        setShowPopup(isShowing);        
    }
    return (
        <>       
         <BalancePopup ShowPopup={showPopup} ShowBalancesTable={showBalancesTable} /> 
                      
            <BalanceRow>
                <ButtonContainer>
                    <button onClick={() => showBalancesTable(true)}><Icon><AssessmentIcon /></Icon></button>
                    <button onClick={() => {setLShowLoader(true);dispatch(getBalanceData(databaseName!))} }><Icon><AutorenewIcon /></Icon></button>
                    <ImgClosure>
                       {lShowLoader && <img alt="" src={ajaxLoader} /> }
                    </ImgClosure>
                    
                </ButtonContainer>
                
                <BalanceFigures>
                    <div>P.N.L Balance:<span>${Number(pnlBalance).toFixed(2)}</span></div>
                    <div>Account Balance:<span>${Number(accountBalance).toFixed(2)}</span></div>
                </BalanceFigures>
            </BalanceRow>
        </>
        
    );
};

export default Balance;