import { getBankEntries } from '../../../modules/match/matchActions';
import { BankStatementEntry } from '../../../modules/match/types';
import { ROOT_URL } from '../../../Utils/Constants';
import { post } from '../../../Utils/http';

export const loadBankStatement = async (
  db: string,
  dbName: string,
  dispatch: any,
  fileContent: string,
) => {
  const arr: string[] = fileContent.replace(/\r/g, '').split('\n');
  let counter = 0;
  let showWrongAccount = false;
  const objArr: BankStatementEntry[] = arr.reduce(
    (arr: BankStatementEntry[], item: string) => {
      if (counter === 0) {
        const _dbName = dbName.replace(/DEV-/g, '');
        if (item.toUpperCase().indexOf(_dbName.toUpperCase()) === -1) {
          showWrongAccount = true;
        }
      }
      if (item.length > 0 && counter > 3) {
        //let initTokens = item.replace(/"/g, '').replace(/\*/g, '').split(',');
        const description = getItemDescription(item);
        const _desc = description.replace(/,/g, '___');
        var re = new RegExp(description, 'g');
        const _item = item.replace(re, _desc);
        //replacing description with commas
        const initTokens = _item.replace(/"/g, '').split(',');
        const itemObj: BankStatementEntry = {
          transactionNumber: `${initTokens[1]}[${initTokens[4]}${initTokens[5]}]${initTokens[6]}`,
          date: initTokens[1],
          description: initTokens[2],
          amount: `${initTokens[4]}${initTokens[5]}`,
          checknumber: `${initTokens[7]}`,
          matchId: null,
          selected: false,
        };
        arr.push(itemObj);
      }
      counter++;
      return arr;
    },
    [],
  );

  if (showWrongAccount) {
    alert('This data comes from the wrong account');
    return;
  }
  const url = `${ROOT_URL}/match/uploadBankEntries.php?db_name=${db}`;
  const result: BankStatementEntry[] = await post(url, objArr, dispatch);
  dispatch(getBankEntries('all', db));
};

const getItemDescription = (item: string) => {
  const firstIndex = item.indexOf('**');
  const lastIndex = item.indexOf('",');
  const desc = item.substring(firstIndex + 2, lastIndex);
  return desc;
};
const getEntryKey = (item: BankStatementEntry) =>
  `${item.date}${item.amount.trim()}${item.description}`;
