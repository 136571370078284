import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { BankStatementEntry } from '../../../modules/match/types';
import { useActiveDB } from '../../../hooks/useActiveDB';
import { unMatch } from '../../../modules/match/matchActions';
const MatchRecord: FC<BankStatementEntry> = ({
  amount,
  description,
  checknumber,
  cron_id,
  matchId,
  date,
}) => {
  const dispatch = useDispatch();
  const { databaseName } = useActiveDB();
  const color = parseFloat(amount) < 0 ? 'negative' : 'positive';
  const _matchId = matchId || -1;
  const unMatchHandler = (cronId: any) => {
    const _cronIds = cronId
      .replace(/\[/, '')
      .replace(/\]/, '')
      .replace(/\"/g, '')
      .replace(/ /g, '');
    dispatch(unMatch(databaseName!, _matchId, _cronIds));
  };
  const cronArr = cron_id
    ? JSON.parse(cron_id.replace(/"/g, '').replace(/\'/g, ''))
    : [];
  const fencyAmount = `$${parseFloat(amount).toFixed(2)}`.replace(/\$-/, '-$');
  return (
    <div className='MatchRecordDivider'>
      <div className='MatchRowWrapper'>
        <div className='MatchRow'>
          <div className='dateField'>{date}</div>
          <div className={`MatchAmount ${color}`}>{fencyAmount}</div>
          <div className='MatchComments'>
            <div className='BankComments comment'>
              <div className='CommentType'>Bank:</div>
              <div className='bankCombo'>{`${description} ${checknumber}`}</div>
            </div>

            <div className='DbComments comment'>
              <div className='CommentType'>Database:</div>
              <div>{cronArr.length}</div>
            </div>
          </div>
          <div className='MatchActions' onClick={() => unMatchHandler(cron_id)}>
            <span>
              <HighlightOff />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchRecord;
