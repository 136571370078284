import { combineReducers } from 'redux';
import { propertyReducer } from '../modules/properties/propertyReducer';
import { statementReducer } from '../modules/statement/statementReducer';
import { loginReducer } from '../modules/Login/loginReducer';
import { tenantsReducer } from '../modules/tenants/tenantsReducer';
import { transactionReducer } from '../modules/transactions/transactionReducer';
import { escrowReducer } from '../modules/escrow/escrowReducer';
import { mortgageReducer } from '../modules/mortgages/mortgageReducer';
import { creditCardReducer } from '../modules/creditCards/creditCardReducer';
import { balanceReducer } from '../modules/balance/balanceReducer';
import { VendorsReducer } from '../modules/vendors/vendorsReducer';
import { devicesReducer } from '../modules/devices/deviceReducer';
import { matchReducer } from '../modules/match/matchReducer';
import { myExpensesSlice } from '../modules/myExpenses/expensesSlice';

export const RootReducer = combineReducers({
  propertyReducer,
  statementReducer,
  loginReducer,
  tenantsReducer,
  transactionReducer,
  escrowReducer,
  mortgageReducer,
  creditCardReducer,
  balanceReducer,
  VendorsReducer,
  devicesReducer,
  myExpensesReducer:myExpensesSlice.reducer,
  matchReducer,
});

export type RootState = ReturnType<typeof RootReducer>;
