import React from "react";
import { Button, Modal } from "react-bootstrap";
import { IStopWarningData } from "../../modules/transactions/types";

type MyProps = {
    StopWarningData?: IStopWarningData,
    SetData: (data:IStopWarningData) => void
}
export const StopWarningModal: React.FC<MyProps> = ({StopWarningData,SetData}) => {   
    const ContinueAnyways = () => {
        SetData({
            AllowOverride:true,
            ShowModal:false,
        });
    }
    const closeModal = () => {        
        SetData({
            ...StopWarningData,
            ShowModal:false
        });
    }
    return (
        <Modal backdrop='static' show={StopWarningData?.ShowModal || false} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Who didn't pay</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You Don't have enough in escrow</p>
                <p>Do you want to proceed?</p>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={closeModal}>
                    Cancel
                </Button>
            <Button variant="primary" onClick={ContinueAnyways}>
                    Continue Anyways
                </Button>
            </Modal.Footer>
        </Modal>
    );
}