import React, { useEffect, useState, MouseEvent } from 'react';
import { IStatementLine } from '../../modules/statement/types';
import { formatter } from '../../Utils/formatters';
import { StatementRowWrapper } from '../../resources/Theme';
import {
  Col1,
  Col2,
  Col3,
  Col4,
  Col5,
  Col6,
  Col7,
  Col8,
} from './StatementEntries';
import visa from '../../resources/visa.png';
import { EntryType } from '../../modules/balance/types';
import { NumberColor } from './Styles';
import VisaPopup from './VisaPopup';
import CheckSwitch from './CheckSwitch';

type MyProps = {
  StatementLine: IStatementLine;
  minWidth: string;
  CheckStatementLine: (cronid: number, isChecked: boolean) => void;
};

const StatementLine: React.FC<MyProps> = (props) => {
  const [showVisa, setShowVisa] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  let currentType: EntryType = EntryType.None;
  const {
    eComment,
    mortgageComment,
    wComment,
    depositComment,
    rentComment,
    mortgageAmount,
    Notes,
    wAmount,
    depositAmount,
    rentAmount,
    eAmount,
    eCheckNumber,
    wCheckNum,
    mortgageCheck,
    TransactionTime,
    Address,
    reviewed,
    cronid,
    ccCheck,
    ExpenseName,
  } = props.StatementLine;

  useEffect(() => {
    setShowVisa(ccCheck !== null && Number(ccCheck) === 0);
  }, [ccCheck]);
  const getEntryType = (): EntryType => {
    if (mortgageAmount) {
      currentType = EntryType.Mortgage;
      return EntryType.Mortgage;
    }
    if (wAmount) {
      currentType = EntryType.Withdrawal;
      return EntryType.Withdrawal;
    }
    if (eAmount) {
      currentType = EntryType.Expense;
      return EntryType.Expense;
    }
    if (depositAmount) {
      currentType = EntryType.Deposit;
      return EntryType.Deposit;
    }
    if (rentAmount) {
      currentType = EntryType.Income;
      return EntryType.Income;
    }
    return EntryType.None;
  };

  const getEntryMessage = (): string => {
    if (eComment) return `[${ExpenseName}] ${eComment} ${Notes}`;
    if (mortgageComment) return mortgageComment;
    if (wComment) return wComment;
    if (depositComment) return depositComment;
    if (rentAmount) return `${rentComment}`;
    return `[${ExpenseName}]`;
  };

  const getEntryIncome = (): string => {
    if (depositAmount) return formatter.format(depositAmount);
    if (rentAmount) return formatter.format(rentAmount);
    return '--';
  };

  const getEntryOut = (): string => {
    if (eAmount) return formatter.format(eAmount);
    if (mortgageAmount) return formatter.format(mortgageAmount);
    if (wAmount) return formatter.format(wAmount);
    return '--';
  };

  const getCheckNum = (): string => {
    if (eCheckNumber && eCheckNumber !== 'NULL') {
      return eCheckNumber;
    }
    if (wCheckNum) {
      return wCheckNum;
    }
    if (mortgageCheck) {
      return mortgageCheck;
    }
    return '';
  };

  const onChange = (e: any, cronid: number): void => {
    props.CheckStatementLine(cronid, e.target.checked);
  };

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentType === 'Expense' && getCheckNum() === '') {
      setShowPopup(true);
    }
  };
  return (
    <div className='border bg-light text-dark pt-2 '>
      <input type='hidden' name='cronId' value={cronid} />
      <StatementRowWrapper className='' isChecked={Number(reviewed) === 1}>
        <Col1>
          <CheckSwitch
            cronid={cronid}
            reviewed={reviewed}
            onChange={onChange}
          />
        </Col1>
        <Col2>{new Date(TransactionTime).toDateString()}</Col2>
        <Col3>{Address}</Col3>
        <Col4>{getEntryType()}</Col4>
        <Col5>{getEntryMessage()}</Col5>
        <Col6>
          <NumberColor Type={currentType}>{getEntryIncome()}</NumberColor>
        </Col6>
        <Col7>
          <NumberColor Type={currentType}>{getEntryOut()}</NumberColor>
        </Col7>
        <Col8 onContextMenu={(e) => onClick(e)}>
          {!showVisa && getCheckNum()}
          {showVisa && <img alt='' style={{ maxWidth: '30px' }} src={visa} />}
          {showPopup && (
            <VisaPopup
              setShowPopup={setShowPopup}
              setShowVisa={setShowVisa}
              showVisa={showVisa}
              cronid={cronid}
            />
          )}
        </Col8>
      </StatementRowWrapper>
    </div>
  );
};

export default StatementLine;
