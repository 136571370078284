import { Dispatch, SetStateAction } from 'react';
import { IStatementLine } from '../../modules/statement/types';
import { ROOT_URL } from '../../Utils/Constants';
import { http } from '../../Utils/http';
import {
  CreditCardRecord,
  DBCreditCardRecord,
  MatchRecordEntry,
} from './types';

export const parseCreditCardRecrods = async (ccRawRecords: string[]) => {
  const _ccRecords: CreditCardRecord[] = ccRawRecords.reduce(
    (arr: CreditCardRecord[], input: string) => {
      input = input.replace('PATRIOT SOFTWARE, LLC', 'PATRIOT SOFTWARE LLC');
      const tokens = input.split(',');
      if (tokens[0] !== 'Transaction Date') {
        const record: CreditCardRecord = {
          TransactionDate: tokens[0],
          PostedDate: tokens[1],
          CardNo: tokens[2],
          Description: tokens[3],
          Amount: tokens[5] ? parseFloat(tokens[5]) : parseFloat(tokens[6]),
          isDebit: tokens[5] ? true : false,
        };
        arr.push(record);
      }
      return arr;
    },
    [],
  );

  const houseCC: IStatementLine[] = await getDatabaseCreditCardRecrods(
    'allglute_db-HOUSE2009',
  );
  const dkaCC: IStatementLine[] = await getDatabaseCreditCardRecrods(
    'allglute_db-DKA2009',
  );
  const ddCC: IStatementLine[] = await getDatabaseCreditCardRecrods(
    'allglute_db-DD',
  );
  const dbRecords: DBCreditCardRecord[] = [...houseCC, ...dkaCC, ...ddCC].map(
    (record: any) => {
      return {
        TransactionDate: record.TransactionTime,
        ExpenseName: record.ExpenseName,
        Address: record.Address,
        Amount: parseFloat(record.eAmount),
        CronId: record.cronid,
      };
    },
  );
  return {
    matchedRecords: performMatch(dbRecords, _ccRecords),
    ccRecords: _ccRecords,
  };
};

const performMatch = (
  dbRecords: DBCreditCardRecord[],
  ccRecords: CreditCardRecord[],
) => {
 
  const MatchRecords: MatchRecordEntry[] = [];
  ccRecords.forEach((ccRec: CreditCardRecord) => {
    dbRecords.forEach((dbRec: DBCreditCardRecord) => {
      const ccAmountFactor = ccRec.isDebit ? 1 : -1;
      const key: string = `${dbRec.CronId}${dbRec.Address}`;
      if (
        ccRec.Amount * ccAmountFactor ===
        dbRec.Amount //&&
        //diffRecordsDates(ccRec, dbRec) < 3
      ) {
        ccRec.foundKey = key;
        MatchRecords.push({
          CreditCardNote: ccRec.Description.replace(',', ''),
          Amount: dbRec.Amount,
          DBNote: dbRec.ExpenseName,
          CCTransactionDate: ccRec.TransactionDate,
          CCPostedDate: ccRec.PostedDate,
          DBDate: dbRec.TransactionDate.substring(0, 10),
          CardNo: ccRec.CardNo,
        });
      }
    });
  });
  return MatchRecords;
};

const getDatabaseCreditCardRecrods = async (databaseName: string) => {
  let url: string = `${ROOT_URL}/statement/statement.php?db_name=${databaseName}&onlyCreditcard=true`;
  const creditcardList: IStatementLine[] = await http(url, () => {});
  return creditcardList;
};

const diffRecordsDates = (rec1: CreditCardRecord, rec2: DBCreditCardRecord) => {
  const diffPostDate = diffDates(rec1.PostedDate, rec2.TransactionDate);
  const diffTransactionDate = diffDates(
    rec1.TransactionDate,
    rec2.TransactionDate,
  );
  if (diffPostDate > diffTransactionDate) {
    return diffDates;
  }

  return diffTransactionDate;
};
const diffDates = (date1: string, date2: string) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date1).getTime();
  const secondDate = new Date(date2).getTime();

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return Math.abs(diffDays);
};
