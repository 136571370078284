import React, { FC, useState } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { MyExpenseType } from '../MyExpenses';
import { useDispatch } from 'react-redux';
import { NormalizedAllData } from '../../../modules/myExpenses/types';
import { setActiveJson } from '../../../modules/myExpenses/expensesSlice';

type myProps = MyExpenseType & {
  selectedItem: NormalizedAllData;
};
const CommandButtons: FC<myProps> = ({ setShowData, selectedItem }) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onClickOpen = () => {
    dispatch(setActiveJson(selectedItem));
    setShowData(true);
  };
  return (
    <div className='cell1 commandsWrapper'>
      <div className='ExpenseTableButton'>
        <div>
          <OpenInBrowserIcon />
        </div>
        <div onClick={onClickOpen}>Open</div>
      </div>
      <div className='ExpenseTableButton'>
        <div>
          <DeleteForeverIcon />
        </div>
        <div onClick={() => setShowConfirm(true)}>Delete</div>
        {showConfirm && (
          <div className='ConfirmDelete'>
            <div>Please confirm!</div>
            <div className='confirmButtonsWrapper'>
              <div
                className='confirmButton'
                onClick={() => setShowConfirm(false)}>
                Cancel
              </div>
              <div className='confirmButton'>OK</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommandButtons;
