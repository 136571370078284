import { IDataBase } from '../modules/properties/types';
import { LAST_DB_BACKUP, ROOT_URL } from './Constants';
import { http } from './http';

export const backupDatabases = (
  databases: IDataBase[],
  dispatch: any,
): boolean => {
  const lastBackup = localStorage.getItem(LAST_DB_BACKUP);
  const backupTime = new Date().toDateString();
  if (!lastBackup || lastBackup !== backupTime) {
    localStorage.setItem(LAST_DB_BACKUP, backupTime);
    const backCalls: Promise<any>[] = databases.map((item) => {
      const dbName = item.DatabaseName.replace(/\.sql/, '');
      let url = `${ROOT_URL}/../config/backup.php?db_name=${dbName}`;
      return http(url, dispatch);
    });
    Promise.all(backCalls).then(() => console.log('back is done'));
    return true;
  }
  return false;
};
