import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Container, Row, Col } from 'react-grid-system';
import ContactsIcon from '@material-ui/icons/Contacts';
import {
  IMonthlyView,
  IRentPayment,
  ISecurityDeposit,
  ITenant,
  ITenantsPaymentInfo,
} from '../../modules/tenants/types';
import { RootState } from '../../reducers';
import { IProperty } from '../../modules/properties/types';
import { RowWrapper } from '../../resources/Theme';
import { SecurityDeposits } from './SecurityDeposits';
import { RentalPaymentControls } from './RentalPaymentControls';
import TenantsManagement from './TenantDetails/TenantsManagement';
import { formatDate } from '../../Utils/datesUtil';
import styled from 'styled-components';
import { createMonthlyMovingout } from '../../Utils/tenantsUtil';
import { setMoveoutMontlyView } from '../../modules/properties/propertyActions';
import { CURRENT_PAGE } from '../../Utils/Constants';

const TenantName = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 136px;
  overflow: hidden;
`;
type MyProps = {
  getTenantsAction: () => ITenantsPaymentInfo;
  payRentAction: (rentPayment: IRentPayment) => void;
  selectedProperty: IProperty;
  properties: IProperty[];
};

type CompletePayment = {
  name: string;
  amount: number;
  comment: string;
};

const RentPayments = (props: MyProps) => {
  const [selectedTenant, setSelectedTenant] = useState<ITenant>({});
  const [completePayments, setCompletePayments] = useState<CompletePayment[]>(
    [],
  );
  const [securityDeposits, setSecurityDeposits] = useState<ISecurityDeposit[]>(
    [],
  );
  const [showTenantsManagement, setShowTenantsManagement] =
    useState<boolean>(false);
  //const [selectedProperty, setSelectedProperty] = useState<IProperty>();
  const [propertyTenants, setPropertyTenants] = useState<ITenant[]>([]);
  const [rentToPay, setRentToPay] = useState<number>(0);
  const dispatch = useDispatch();

  let selectedPropertyID = useSelector(
    (state: RootState) => state.propertyReducer.selectedProperty,
  );
  let propertyList = useSelector(
    (state: RootState) => state.propertyReducer.propertyList,
  );
  const smSize: number = 12;
  const mdSize: number = 6;
  const lgSize: number = 2;
  useEffect(() => {
    localStorage.setItem(CURRENT_PAGE, 'RentPayments');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let _selectedProperty = propertyList.find(
      (prop: IProperty) => prop.PropertyID === selectedPropertyID,
    );
    let tenants: ITenant[] | undefined = _selectedProperty?.Tenants;
    if (tenants && tenants.length > 0) {
      setSelectedTenant(tenants[0]);
      onTenantSelected(tenants[0]);
    }
  }, [propertyList, selectedPropertyID]);

  useEffect(() => {
    let _selectedProperty = propertyList.find(
      (prop: IProperty) => prop.PropertyID === selectedPropertyID,
    );
    //setSelectedProperty(_selectedProperty);
    setPropertyTenants(_selectedProperty ? _selectedProperty.Tenants : []);
    if (selectedTenant) {
      let nt = _selectedProperty?.Tenants.find(
        (t) => t.TenantID === selectedTenant.TenantID,
      );
      if (nt) {
        setSelectedTenant(nt);
      }

      //live update security deposits
      if (
        selectedTenant.SecurityDeposits &&
        selectedTenant.SecurityDeposits.length > 0
      ) {
        setSecurityDeposits(selectedTenant.SecurityDeposits || []);
      }
    }

    // getWhoIsMovingOut();
    const monthlys: IMonthlyView[] = createMonthlyMovingout(propertyList);
    dispatch(setMoveoutMontlyView(monthlys));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyID, propertyList, selectedTenant]);

  useEffect(() => {
    let rentPayment =
      (selectedTenant.RentAmount || 0) - (selectedTenant.PaidAmount || 0);
    if (rentPayment < 0) {
      rentPayment = 0;
    }
    setRentToPay(rentPayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant]);

  const handleSetCompletePayments = (comment: string, amount: number) => {
    //the bottom table that shows feedback when a payment occured
    const complete: CompletePayment = {
      name: selectedTenant.TenantName || '',
      amount: amount,
      comment: comment,
    };
    setCompletePayments([...completePayments, complete]);
  };

  const onTenantSelected = (tenant: ITenant): void => {
    const selectedDeposits: ISecurityDeposit[] = tenant.SecurityDeposits || [];
    setSelectedTenant(tenant);
    if (selectedDeposits.length > 0) {
      setSecurityDeposits(selectedDeposits);
    }
  };

  const openTenantManagement = () => {
    setShowTenantsManagement(true);
  };

  const openDetails = (tenant: ITenant) => {
    onTenantSelected(tenant);
    setShowTenantsManagement(true);
  };
  return (
    <div className='flex-grow-1'>
      <Container fluid>
        <Row className='border bg-dark text-light '>
          <Col
            className='border rounded-sm'
            lg={lgSize}
            md={mdSize}
            sm={smSize}>
            Name
          </Col>
          <Col
            className='border rounded-sm d-none d-sm-block'
            lg={lgSize}
            md={mdSize}
            sm={smSize}>
            Phone 1
          </Col>
          <Col
            className='border rounded-sm d-none d-sm-block'
            lg={lgSize}
            md={mdSize}
            sm={smSize}>
            Phone 2
          </Col>
          <Col
            className='border rounded-sm  d-none d-sm-block'
            lg={lgSize}
            md={mdSize}
            sm={smSize}>
            Rent
          </Col>
          <Col
            className='border rounded-sm  d-none d-sm-block'
            lg={lgSize}
            md={mdSize}
            sm={smSize}>
            End Lease
          </Col>
          <Col
            className='border rounded-sm  d-none d-sm-block'
            lg={lgSize}
            md={mdSize}
            sm={smSize}>
            Apartment
          </Col>
        </Row>
        <div
          style={{ height: '200px', overflowY: 'auto', overflowX: 'hidden' }}>
          {propertyTenants.map((tenant, index) => {
            return (
              <Row
                onDoubleClick={openTenantManagement}
                onClick={() => onTenantSelected(tenant)}
                key={`tenant-${index}`}
                className='border bg-light'>
                <RowWrapper
                  isChecked={
                    get(selectedTenant, 'TenantID') === tenant.TenantID
                  }>
                  <Col
                    className='border rounded-sm'
                    lg={lgSize}
                    md={mdSize}
                    sm={smSize}>
                    <TenantName>
                      <ContactsIcon onClick={() => openDetails(tenant)} />
                      {tenant.TenantName}
                    </TenantName>
                  </Col>
                  <Col
                    className='border rounded-sm'
                    lg={lgSize}
                    md={mdSize}
                    sm={smSize}>
                    {tenant.Phone1}
                  </Col>
                  <Col
                    className='border rounded-sm small-hide'
                    lg={lgSize}
                    md={mdSize}
                    sm={smSize}>
                    {tenant.Phone2}
                  </Col>
                  <Col
                    className='border rounded-sm'
                    lg={lgSize}
                    md={mdSize}
                    sm={smSize}>
                    {tenant.RentAmount}
                  </Col>
                  <Col
                    className='border rounded-sm'
                    lg={lgSize}
                    md={mdSize}
                    sm={smSize}>
                    {tenant.LeaseExpirationDate!}
                  </Col>
                  <Col
                    className='border rounded-sm small-hide'
                    lg={lgSize}
                    md={mdSize}
                    sm={smSize}>
                    {tenant.Apartment!}
                  </Col>
                </RowWrapper>
              </Row>
            );
          })}
        </div>
      </Container>
      <Container fluid>
        <Row>
          <RentalPaymentControls
            setCompletePayments={handleSetCompletePayments}
            balanceToPay={rentToPay}
            selectedTenant={selectedTenant}
            propertyID={selectedPropertyID}
            mdSize={mdSize}
            smSize={smSize}
          />
          <SecurityDeposits
            tenantID={selectedTenant.TenantID!}
            securityDeposits={securityDeposits}
            mdSize={mdSize}
            smSize={smSize}
            selectedProperty={props.selectedProperty}
          />
        </Row>
      </Container>
      <div className='flex-grow-1 d-none d-sm-block'>
        <Container fluid>
          <Row className='border bg-dark text-light '>
            <Col
              className='border rounded-sm'
              lg={lgSize}
              md={mdSize}
              sm={smSize}>
              Name
            </Col>
            <Col
              className='border rounded-sm d-none d-sm-block'
              lg={lgSize}
              md={mdSize}
              sm={smSize}>
              Amount
            </Col>
            <Col className='border rounded-sm d-none d-sm-block'>Comments</Col>
          </Row>
          {completePayments.map((item, index) => {
            return (
              <Row key={`complete-${index}`} className='border bg-light'>
                <Col
                  className='border rounded-sm text-lg-left'
                  lg={lgSize}
                  md={mdSize}
                  sm={smSize}>
                  {item.name}
                </Col>
                <Col
                  className='border rounded-sm text-lg-left'
                  lg={lgSize}
                  md={mdSize}
                  sm={smSize}>
                  ${item.amount}
                </Col>
                <Col
                  className='border rounded-sm  d-none d-sm-block text-lg-left'
                  md={mdSize}
                  sm={smSize}>
                  {item.comment}
                </Col>
              </Row>
            );
          })}
        </Container>
      </div>
      {selectedTenant.toString() !== '{}' && (
        <TenantsManagement
          showTenantsManagement={showTenantsManagement}
          setSelectedTenant={setSelectedTenant}
          selectedTenant={selectedTenant}
          propertyID={
            props.selectedProperty ? props.selectedProperty.PropertyID : -1
          }
          closeWindow={() => {
            setShowTenantsManagement(false);
          }}
        />
      )}
    </div>
  );
};

export default RentPayments;
