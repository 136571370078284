import { Dispatch } from "react";
import { ROOT_URL } from "../../Utils/Constants";
import { post, http } from "../../Utils/http";
import { setLoaderFlag } from "../properties/propertyActions";
import { ISetLoaderFlagAction } from "../properties/types";
import {
  SET_SELECTED_DEVICE,
  ADD_DEVICE_RECORD,
  SingleDevice,
  DeviceRecord,
  deviceActionsType,
  GET_ALL_DEVICES,
  DELETE_DEVICE
} from "./types";

export const setSelectedDeviceAction = (device: SingleDevice) => ({
  type: SET_SELECTED_DEVICE,
  payload: device
});

export const addDeviceRecord =
  (device: DeviceRecord, db: string) =>
  async (dispatch: Dispatch<deviceActionsType | ISetLoaderFlagAction>) => {
    try {
      const url: string = `${ROOT_URL}/devices/saveDevice.php?db_name=${db}`;
      const persistedRecord = await post(url, device, dispatch);
      dispatch({
        type: ADD_DEVICE_RECORD,
        payload: persistedRecord
      });
      dispatch(setLoaderFlag(false));
    } catch (e) {
      dispatch(setLoaderFlag(false));
    }
  };

export const getAllDevices =
  (db: string) =>
  async (dispatch: Dispatch<deviceActionsType | ISetLoaderFlagAction>) => {
    try {
      const url: string = `${ROOT_URL}/devices/getAllDevices.php?db_name=${db}`;
      const allDevices = await http(url, dispatch);
      dispatch({
        type: GET_ALL_DEVICES,
        payload: allDevices.devices
      });
      dispatch(setLoaderFlag(false));
    } catch (e) {
      dispatch(setLoaderFlag(false));
    }
  };

export const deleteDevice =
  (db: string, deviceId: number) =>
  async (dispatch: Dispatch<deviceActionsType | ISetLoaderFlagAction>) => {
    try {
      const url: string = `${ROOT_URL}/devices/deleteDevice.php?db_name=${db}&deviceId=${deviceId}`;
      const deleteStatus = await http(url, dispatch);
      if (deleteStatus.status === "success") {
        dispatch({
          type: DELETE_DEVICE,
          payload: deleteStatus.deviceId
        });
      }

      dispatch(setLoaderFlag(false));
    } catch (e) {
      dispatch(setLoaderFlag(false));
    }
  };
