export const GET_MORTGAGE_LIST = 'GET_MORTGAGE_LIST';
export const POST_MORTGAGE_PAYMENTS = 'POST_MORTGAGE_PAYMENTS';


export interface IMortgageEntry {
    PropertyID: number,
    MortgageID: number,
    DefaultComment: string,
    Monthly: number,
    MortgageName: string,
    NextPaymentMonth: string,
    IsSelected:boolean
 }

 export interface IGetMortgageListAction{
     type: typeof GET_MORTGAGE_LIST,
     payload: IMortgageEntry[]
 }

 export interface IPostMortgagePayment{
     type: typeof POST_MORTGAGE_PAYMENTS,
     payload: IMortgageEntry[]
 }
 export type IMortgageActions = IGetMortgageListAction | IPostMortgagePayment;