import React, { FC, RefObject } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { EntryType } from '../../../../modules/balance/types';
import { toggleMatchBankRecord } from '../../../../modules/match/matchActions';
import { BankStatementEntry } from '../../../../modules/match/types';
import { NumberColor } from '../../Styles';

type MyProps = {
  BankLine: BankStatementEntry;
  Validate: Function;
  elemRef: RefObject<HTMLDivElement>;
};
const BankStatementLine: FC<MyProps> = ({ BankLine, elemRef, Validate }) => {
  const {
    description,
    amount,
    checknumber,
    date,
    matchId,
    transactionNumber,
    selected,
  } = BankLine;
  const dispatch = useDispatch();
  const et: EntryType =
    parseFloat(amount) > 0 ? EntryType.Income : EntryType.Expense;

  return (
    <div
      onClick={() => {
        dispatch(toggleMatchBankRecord(matchId!));
        Validate();
      }}
      ref={elemRef}
      className={`border ${
        !selected ? 'bg-light text-dark' : 'bg-dark text-light'
      } pt-2`}>
      <input type='hidden' name='cronId' value={matchId || 'NULL'} />
      <div className='MatchBankLineWrapper'>
        <div>{date}</div>
        <div title={transactionNumber}>{description}</div>
        <div>{checknumber}</div>
        <div>
          <NumberColor Type={et}>{amount}</NumberColor>
        </div>
      </div>
    </div>
  );
};

export default BankStatementLine;
