import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { EscrowActionItem } from './EscrowActionItem';
import { useDispatch, useSelector } from 'react-redux';
import { ActionRecord } from '../../modules/escrow/types';
import { useActiveDB } from '../../hooks/useActiveDB';
import {
  resetEscrowTree,
  runMonthlyTask,
} from '../../modules/escrow/escrowActions';
import { RootState } from '../../reducers';
import LoadingDrape from '../Shared/LoadingDrape';
import { getExpenseTypeList } from '../../modules/transactions/transactionActions';

// @ts-ignore
type MyProps = {};

const StyledGrayText = styled.div`
  color: gray;
`;

const AutoActions: React.FC<MyProps> = (props: MyProps) => {
  const dispatch = useDispatch();
  const showLoaderValue = useSelector(
    (state: RootState) => state.propertyReducer.showLoader,
  );
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { databaseName } = useActiveDB();
  const records = useSelector(
    (state: RootState) => state.escrowReducer.monthlyRuns,
  );
  useEffect(() => {
    setShowLoader(showLoaderValue);
  }, [showLoaderValue]);
  const handleClick = () => {
    dispatch(runMonthlyTask(databaseName!));
    dispatch(resetEscrowTree());
    dispatch(getExpenseTypeList(databaseName!));
  };
  return (
    <div className='flex-grow-1'>
      <Container fluid>
        <Row>
          <Button
            style={{ width: '150px' }}
            variant='primary'
            onClick={handleClick}
            block>
            Run Auto Actions
          </Button>
        </Row>
        <LoadingDrape IsVisible={showLoader} />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <StyledGrayText>Auto Actions</StyledGrayText>
              </Card.Header>
              <Card.Body>
                <div
                  style={{
                    display: 'flex',
                    height: '320px',
                    overflow: 'auto',
                  }}>
                  <div style={{ justifyContent: 'flex-start', width: '100%' }}>
                    {records.map((r: ActionRecord, ndx: number) => {
                      return (
                        <EscrowActionItem
                          key={ndx}
                          ActionName={r.ActionName}
                          ActionType={r.ActionType}
                          Address={r.Address}
                          Amount={r.Amount}
                          ExpenseName={r.ExpenseName}
                          OnCreditCard={r.OnCreditCard}
                        />
                      );
                    })}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AutoActions;
