import React, { ChangeEvent, FC } from 'react';
import './VisaPopup.css';
import visa from '../../resources/visa.png';
import { ROOT_URL } from '../../Utils/Constants';
import { http } from '../../Utils/http';
import { useActiveDB } from '../../hooks/useActiveDB';
import { useDispatch } from 'react-redux';

type MyProps = {
  setShowPopup: any;
  showVisa: boolean;
  setShowVisa: any;
  cronid: number;
};
const VisaPopup: FC<MyProps> = ({
  setShowPopup,
  showVisa,
  setShowVisa,
  cronid,
}) => {
  const { databaseName } = useActiveDB();
  const dispatch = useDispatch();
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    //https://aviezri.com/api/objects/expenses/updateCreditcard.php?db_name=allglute_dev-db-DKA2009&cronid=28&setCC=true&cb=10
    let url: string = `${ROOT_URL}/expenses/updateCreditcard.php?db_name=${databaseName}&`;
    url = url + `cronid=${cronid}&`;
    url = url + `setCC=${e.currentTarget.checked}`;
    const result = await http(url, dispatch);
    if (result.status === 'success') {
      setShowVisa(result.ccId !== 'NULL');
    }
  };
  return (
    <div className='visaPopupWrapper' onMouseLeave={(e) => setShowPopup(false)}>
      <input type='checkbox' checked={showVisa} onChange={(e) => onChange(e)} />
      <div>
        <img alt='' style={{ maxWidth: '30px' }} src={visa} />
      </div>
    </div>
  );
};

export default VisaPopup;
