import {
  NormalizedAllData,
} from './types';
import { ROOT_URL } from '../../Utils/Constants';
import { http } from '../../Utils/http';
import {
  DBJsonList
} from '../../components/MyExpenses/Types';
import { getNormalizeNetworkData } from '../../components/MyExpenses/utils';
import { setMayExpensesTableItems, setMyExpenses } from './expensesSlice';

export const getMyExpensesListItemsAction = (dispatch) => {
  const load = async () => {
     let url: string = `${ROOT_URL}/myexpenses/getExpensesList.php?db_name=allglute_db-HOUSE2009`;
    let result: DBJsonList = await http(url, dispatch);
    dispatch(setMayExpensesTableItems(result));
  }
  load();
   
  };

export const getMyExpenses =
  (dispatch:any) => {
    const load = async () => {
      let url1: string = `${ROOT_URL}/myexpenses/getExpenses.php?db_name=allglute_db-DKA2009`;
      let url2: string = `${ROOT_URL}/myexpenses/getExpenses.php?db_name=allglute_db-HOUSE2009`;
      const dka = await http(url1, dispatch);
      const house = await http(url2, dispatch);
      var payload:NormalizedAllData =  getNormalizeNetworkData({dka,house});
      dispatch(setMyExpenses(payload));
    }
    load();
  };