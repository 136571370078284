import moment from 'moment';
import { IProperty } from '../modules/properties/types';
import { IMonthlyView, ITenant } from '../modules/tenants/types';

export const createMonthlyMovingout = (
  properties: IProperty[],
): IMonthlyView[] => {
  const monthlys: IMonthlyView[] = [];

  properties.reduce((tenArr: IMonthlyView[], p: IProperty) => {
    return p.Tenants.reduce((monthViews: IMonthlyView[], tenant: ITenant) => {
      const date = moment(tenant.LeaseExpirationDate);
      let keyMonth2Month = 'Month2Month';
      let Month: string = `${date.format('MMMM')} ${date.format('YYYY')}`;
      let Key: string = `${date.format('YYYY')}_${Month}`;

      //Addressing month to month tenants
      if (date.isBefore(moment())) {
        let month2month: IMonthlyView | undefined = monthlys.find(
          (m) => m.Key === keyMonth2Month,
        );
        if (!month2month!) {
          month2month = {
            Key: keyMonth2Month,
            Id: -1,
            Month: keyMonth2Month,
            Tenants: [],
          };
          monthlys.push(month2month);
        }
        month2month?.Tenants.push(tenant);
        return monthViews;
      }

      //Addressing yearly lease tenants
      let monthView: IMonthlyView | undefined = monthlys.find(
        (m) => m.Key === Key,
      );
      if (!monthView) {
        const key1: number = date.month() + date.year() * 10;
        monthView = {
          Key,
          Month,
          Tenants: [],
          Id: key1,
        };
        monthlys.push(monthView);
      }

      monthView.Tenants.push(tenant);
      return monthViews;
    }, []);
  }, []);
  return monthlys;
};
