import styled from 'styled-components';

export const DevicesWrapp = styled.div`
  display: grid;
  grid-template-columns: 200px 60px 180px 1fr 1fr 1fr 1fr 1fr;
  margin: 30px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

export const ButtonWrapper = styled.div`
  height: 30px;
  cursor: pointer;
  display: flex;
  border: 1px solid gray;
  width: fit-content;
  top: 12px;
  left: 25px;
  position: relative;
  padding-left: 16px;
  padding-right: 10px;
  border-radius: 5px;
`;
export const ImgElem = styled.img`
  max-width: 72px;
  max-height: 40px;
  margin: 3px;
`;
