import React, { FC, FormEvent, useEffect } from 'react';
import DollarsInput from './DollarsInput';
import { DataKey, ExpenseTable, NormalizedData } from '../Types';
type MyProps = {
  data: ExpenseTable;// NormalizedData[];
  normalData: DataKey;
  updateCellData: (item: NormalizedData, tots: any) => void;
};
const DataTable: FC<MyProps> = ({ data, normalData, updateCellData }) => {
  const length = data.normalizedDataArr.length;
  let prevAddress = '';
  let showAddress = false;
  let addAddressSpace = false;

  //determine the colors
  let colorClass = '';
  switch (normalData) {
    case 'Expenses':
      colorClass = 'expenseColor';
      break;
    case 'Rent':
      colorClass = 'incomeColor';
      break;
    case 'Mortgage':
      colorClass = 'mortgageColor';
      break;
  }

  const updateInput = (
    e: FormEvent<HTMLInputElement>,
    item: NormalizedData,
  ) => {
    const _item: NormalizedData = {
      ...item,
      Amount: parseFloat(e.currentTarget.value.replace(/\$/, '')),
    };
    updateCellData(_item, {});
  };
  return (
    <div className='dataTable'>
      {data.normalizedDataArr.map((item, index) => {
        if (item.Address !== prevAddress) {
          if (prevAddress !== '') {
            addAddressSpace = true;
          }
          showAddress = true;
          prevAddress = item.Address;
        } else {
          showAddress = false;
          addAddressSpace = false;
        }
        const lastRowClass = length - 1 === index ? 'lastRowClass' : '';
        return (
          <React.Fragment key={index}>
            {addAddressSpace && (
              <>
                <div className='emptyAddress'></div>
                <div className='emptyAddress'></div>
                <div className='emptyAddress'></div>
              </>
            )}
            {showAddress && (
              <div className={`cell lastCell ${colorClass} ${lastRowClass}`}>
                {item.Address}
              </div>
            )}
            {!showAddress && <div className='blankAddress'> </div>}
            <div className={`cell ${colorClass} ${lastRowClass}`}>
              {item.Label}
            </div>
            <div
              key={item.Key}
              className={`cell  ${colorClass} lastRowCell ${lastRowClass} currency`}>
              <DollarsInput
                item={item}
                colorClass={colorClass}
                lastRowClass={lastRowClass}
                updateInput={updateInput}
              />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DataTable;
