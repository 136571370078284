//GET https://aviezri.com/api/objects/statement/statement.php?db_name=allglute_DKA2009&onlyCreditcard=true

import { Dispatch } from 'react';
import { ROOT_URL } from '../../Utils/Constants';
import { http } from '../../Utils/http';
import { IStatementLine } from '../statement/types';
import {
  CreditcardActionsType,
  GET_CREDITCARD_LINES,
  RESET_CREDITCARD_LINES,
} from './types';

export const getCreditcardList = (databaseName: string): any => async (
  dispatch: Dispatch<CreditcardActionsType>,
) => {
  let url: string = `${ROOT_URL}/statement/statement.php?db_name=${databaseName}&onlyCreditcard=true`;
  const creditcardList: IStatementLine[] = await http(url, dispatch);
  dispatch({
    type: GET_CREDITCARD_LINES,
    payload: creditcardList,
  });
};

export const payCreditCard = (
  databaseName: string,
  amount: number,
  checkNumber: string,
): any => async (dispatch: Dispatch<CreditcardActionsType>) => {
  let url: string = `${ROOT_URL}/creditcard/update.php?db_name=${databaseName}&checkNumber=${checkNumber}&amount=${amount}`;
  try {
    await http(url, dispatch);
    dispatch({
      type: RESET_CREDITCARD_LINES,
      payload: [],
    });
  } catch (e) {
    //TODO: add general error message
  }
};
