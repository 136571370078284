import { Dispatch } from '@reduxjs/toolkit';
import { NormalizedAllData } from '../../modules/myExpenses/types';
import { DataKey, DBNormalizedData, DBSource, ExpenseTable, NormalizedData } from './Types';
import { setTotals } from '../../modules/myExpenses/expensesSlice';

export const calculateNumbers = (allData: DBNormalizedData[]): any => {
  let totals = {};
  allData.forEach((dbItem: DBNormalizedData) => {
    let ExpenseTotal;
    let RentTotal;
    let Mortgage;
    dbItem.expenses.normalizedDataArr.forEach((item: any) => {
      //expenses
      ExpenseTotal = totals[item.Address];
      if (ExpenseTotal === undefined || ExpenseTotal.expenses === undefined) {
        totals[item.Address] = {
          ...totals[item.Address],
          expenses: item.Amount,
        };
      } else {
        totals[item.Address].expenses =
          totals[item.Address].expenses + item.Amount;
      }
    });
    dbItem.incomes.normalizedDataArr.forEach((item: any) => {
      //income
      RentTotal = totals[item.Address];
      if (RentTotal === undefined || RentTotal.incomes === undefined) {
        totals[item.Address] = {
          ...totals[item.Address],
          incomes: item.Amount,
        };
      } else {
        totals[item.Address].incomes =
          totals[item.Address].incomes + item.Amount;
      }
    });

    dbItem.mortgages.normalizedDataArr.forEach((item: any) => {
      //mortgage
      Mortgage = totals[item.Address];
      if (Mortgage === undefined || Mortgage.mortgages === undefined) {
        totals[item.Address] = {
          ...totals[item.Address],
          mortgages: item.Amount,
        };
      } else {
        totals[item.Address].mortgages =
          totals[item.Address].mortgages + item.Amount;
      }
    });
  });
  return totals;
};

export const getNormalizedData = (key: DataKey, data: any, source: DBSource):ExpenseTable => {
  let total:number = 0;
  let positive:boolean = true;
  const arr:NormalizedData[] = data.map((item:any) => { 
    let amount = 0;     
    switch (key) {
      case 'Expenses':
        positive = false;  
        amount = parseFloat(item.AutoPaymentAmount);
        total+=amount;                
        return {
          Address: item.Address,
          Amount: amount,
          PrevValue: parseFloat(item.AutoPaymentAmount),
          Label: item.ExpenseName,
          Pristine: true,
          Key: `Exp_${item.ExpenseName.replace(
            / /g,
            '',
          )}_${item.Address.replace(/ /g, '')}`,
          Source: source,
          IsAdding: false,
        };
      case 'Rent':
        amount = parseFloat(item.RentAmount);
        total+=amount; 
        return {
          Address: item.Address,
          Amount: amount,
          PrevValue: parseFloat(item.RentAmount),
          Label: item.TenantName,
          Pristine: true,
          Key: `Rent_${item.TenantName.replace(
            / /g,
            '',
          )}_${item.Address.replace(/ /g, '')}`,
          Source: source,
          IsAdding: true,
        };
      case 'Mortgage':
        positive = false; 
        amount = parseFloat(item.Monthly);
        total+=amount;
        return {
          Address: item.Address,
          Amount: amount,
          PrevValue: parseFloat(item.Monthly),
          Label: item.MortgageName,
          Pristine: true,
          Key: `Mortgage_${item.MortgageName.replace(
            / /g,
            '',
          )}_${item.Address.replace(/ /g, '')}`,
          Source: source,
          IsAdding: false,
        };
    }
    return null;
  });
  return {
    total,
    normalizedDataArr: arr,
    positive,
    addressName:''
  }
};

export const getNormalizeNetworkData = (allData: any): NormalizedAllData => {
  const dka: DBNormalizedData = {
    incomes: getNormalizedData('Rent', allData.dka.incomes, 'dka'),
    expenses: getNormalizedData('Expenses', allData.dka.expenses, 'dka'),
    mortgages: getNormalizedData('Mortgage', allData.dka.mortgages, 'dka'),
  };

  const house: DBNormalizedData = {
    incomes: getNormalizedData('Rent', allData.house.incomes, 'house'),
    expenses: getNormalizedData('Expenses', allData.house.expenses, 'house'),
    mortgages: getNormalizedData('Mortgage', allData.house.mortgages, 'house'),
  };

  return { dka, house };
};



export const calculateTotals = (dispatch: Dispatch<any>, activeJson: NormalizedAllData) => {
  let prevAddress = '';
  let totals = {};
  const calcTotalsPerArray = (dbName: string, arrayName: string, addingFactor: number) => {
    activeJson[dbName]![arrayName].normalizedDataArr.forEach(item => {    
      if(item.Address !== prevAddress)
      {
        prevAddress = item.Address;
        totals[prevAddress] = {...totals[prevAddress],[arrayName]: addingFactor * item.Amount};
        if(Number.isNaN(totals[prevAddress][arrayName]))
        {
          totals[prevAddress][arrayName] = 0;
        }
      } else {
        totals[prevAddress][arrayName] = totals[prevAddress][arrayName] + addingFactor * item.Amount;
        if(Number.isNaN(totals[prevAddress][arrayName]))
        {
          totals[prevAddress][arrayName] = 0;
        }
      }
  
    });
    
  }
  calcTotalsPerArray('dka', 'expenses', -1);
  calcTotalsPerArray('dka', 'incomes', 1);
  calcTotalsPerArray('dka', 'mortgages', -1);
  calcTotalsPerArray('house', 'expenses', -1);
  calcTotalsPerArray('house', 'incomes', 1);
  calcTotalsPerArray('house', 'mortgages', -1);
  
  dispatch(setTotals(totals));
  
}