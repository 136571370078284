import { createBrowserHistory } from 'history';
import {
  Middleware,
  Dispatch,
  AnyAction,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
// @ts-ignore
import { createLogger } from 'redux-logger';
import { applyMiddleware, compose, createStore } from 'redux';
import { RootReducer } from '../reducers';
import DevTools from '../components/Containers/DevTools';
const contextRoot: string = process.env.CONTEXT_ROOT
  ? `/${process.env.CONTEXT_ROOT}`
  : '';

export const history = createBrowserHistory({
  basename: contextRoot,
});

export const configureStore = () => {
  // Redux Configuration
  const middleware: Middleware<{}, any, Dispatch<AnyAction>>[] = [];
  const enhancers: StoreEnhancer<{ dispatch: unknown }, {}>[] = [];

  // Thunk Middleware

  middleware.push(thunk);

  // // Logging Middleware
  const logger = createLogger({
    level: 'info',
    collapsed: true,
  });

  // Skip redux logs in console during the tests
  // if (process.env.NODE_ENV !== 'test') {
  middleware.push(logger);
  // }

  // Apply Middleware & Compose Enhancers
  //const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancers.push(applyMiddleware(...middleware));

  //adding the connected route middleware
  //enhancers.push(applyMiddleware(routerMiddleware(history)));
  const enhancer = compose(...enhancers, DevTools.instrument());

  // Create Store
  // const store = createStore(connectRouter(history)(rootReducer), initialState, enhancer);

  // @ts-ignore
  const store = createStore(RootReducer, {}, enhancer);

  return store;
};
