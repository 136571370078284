import React from 'react';
import styled from 'styled-components';
import { ActionRecord } from '../../modules/escrow/types';

const ItemStyle = styled.div<{ActionType:string}>`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 1fr;
  grid-template-rows:1fr;
  text-align:left;
  div {color:${props => props.ActionType === 'No Action' ? 'green' : 'blue' }}
  @media(max-width:600px){
    grid-template-columns: 1fr 1fr ;
    grid-template-rows:1fr 1fr 1fr;
    border:1px solid grey;
  }
`;

const AddressField = styled.div`
  white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 136px;
    overflow: hidden;
    padding-left:13px;
    @media(max-width:600px){
      padding-left:0;
      max-width: 156px;
    }
`;

export const EscrowActionItem:React.FC<ActionRecord> = (props:ActionRecord) => {
    const {ActionName, ActionType, Address, ExpenseName, Amount} = props;
    return (
        <ItemStyle ActionType={ActionType}>
            <div>{ActionName}</div>
            <div>{ActionType}</div>
            <AddressField>{Address}</AddressField>
            <div>{ExpenseName}</div>
            <div>{Amount}</div>         
        </ItemStyle>
    );
};
