import React, { Dispatch, useEffect } from 'react';
import { Row, Col} from 'react-grid-system';
import styled from 'styled-components';
import { Dropdown} from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import {getPropertyList, setActiveProperty} from "../../modules/properties/propertyActions";
import {useDispatch} from "react-redux";
import {IProperty} from "../../modules/properties/types";
import { useActiveDB } from '../../hooks/useActiveDB';
import { useActiveUser } from '../../hooks/useActiveUser';

const PropertyLabel = styled.div`
  font-size:20px;
  font-weight: bold;
`;

const Properties:React.FC = () => {
    const dispatch = useDispatch<Dispatch<any>>();
    const userName = useActiveUser(); 
    const {databaseName,selectedProperty,propertyList} = useActiveDB();

    useEffect(()=>{   
        if(userName){
            dispatch(getPropertyList(databaseName!));
        }            
              // eslint-disable-next-line react-hooks/exhaustive-deps
    },[databaseName,userName]);
    
    const onPropertySelected = (property:IProperty) => {   
        dispatch(setActiveProperty(property));
    };

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <DropdownButton
                            as={ButtonGroup}
                            variant=""
                            title="Properties"

                        >
                            {propertyList.map((property,index) => {
                            return (
                                <Dropdown.Item 
                                    onClick={()=> onPropertySelected(property)} 
                                    key={`prop-${index}`} 
                                    eventKey="{property.PropertyID}">
                                        {property.Address}
                                </Dropdown.Item>
                            )
                        })}
                        </DropdownButton>
                    </Col>
                </Row>
                <Row><Col>
                <PropertyLabel>
                    {selectedProperty ? selectedProperty.Address : ''}
                </PropertyLabel>
            </Col></Row>
            </Col>
        </Row>
    )    
}

export default Properties;