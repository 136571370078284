import styled from 'styled-components';
export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: column;
  align-content: flex-start;
`;

export const SplitControlsScreen = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
`;

export const JustPaidExpenses = styled.div`
  background-color: #fff;
  margin: 5px;
  margin-bottom: 20px;
  border-radius: 13px;
  color: darkorange;
  max-height: 245px;
  overflow-y: auto;
  @media (max-width: 1400px) {
    display: none;
  }
`;
export const JustPaidRow = styled.div`
  grid-template-columns: 2fr 1fr;
  display: grid;
  text-align: left;
  padding-left: 13px;
  overflow-x: hidden;
`;
