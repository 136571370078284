import React, { useEffect } from 'react';
import detector from './Images/smokedetector.png';
import flake from './Images/flake.png';
import heater from './Images/hotwaterheater.png';
import extinguisher from './Images/extinguisher.png';
import boilder from './Images/boiler.png';
import battery from './Images/fireSystemBattery.png';
import range from './Images/range.png';
import sump from './Images/sump.jpg';
import refrigerator from './Images/refrigerator.png';
import { Tooltip } from '@material-ui/core';
import { DevicesWrapp, ImgElem } from './Devices.style';
import { DeviceRecord } from '../../modules/devices/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import {
  deleteDevice,
  getAllDevices,
} from '../../modules/devices/deviceActions';
import { useActiveDB } from '../../hooks/useActiveDB';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
export const DeleteWrapper = styled.div`
  cursor: pointer;
`;
export const DeviceRecords = () => {
  const dispatch = useDispatch();
  const devices: DeviceRecord[] = useSelector(
    (state: RootState) => state.devicesReducer.records,
  );
  const { databaseName } = useActiveDB();
  useEffect(() => {
    dispatch(getAllDevices(databaseName!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DevicesWrapp>
      {devices.map((record: DeviceRecord) => {
        const {
          Device: device,
          Building,
          Apartment,
          InstallDate,
          YearsInService,
          Notes,
          ID,
        } = record;
        return (
          <>
            <div>{device}</div>
            <div>
              {device === 'SmokeDetector' && <ImgElem src={detector} />}
              {device === 'HotWaterHeater' && <ImgElem src={heater} />}
              {device === 'Extinguisher' && <ImgElem src={extinguisher} />}
              {device === 'BoilerService' && <ImgElem src={boilder} />}
              {device === 'Battery' && <ImgElem src={battery} />}
              {device === 'Range' && <ImgElem src={range} />}
              {device === 'Refrigerator' && <ImgElem src={refrigerator} />}
              {device === 'AntiFreeze' && <ImgElem src={flake} />}
              {device === 'SumpPump' && <ImgElem src={sump} />}
            </div>
            <div>{Building}</div>
            <div>{Apartment}</div>
            <div>{InstallDate}</div>
            <div>{YearsInService}</div>
            <div>{Notes}</div>
            <div
              onClick={(e) =>
                dispatch(deleteDevice(databaseName!, ID as number))
              }>
              <DeleteWrapper>
                <Tooltip title='Delete' placement='right'>
                  <DeleteIcon />
                </Tooltip>
              </DeleteWrapper>
            </div>
          </>
        );
      })}
    </DevicesWrapp>
  );
};
