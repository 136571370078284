import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { IExpenseType } from '../../modules/transactions/types';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseTypeList } from '../../modules/transactions/transactionActions';
import { RootState } from '../../reducers';
import ajaxLoader from '../../resources/ajax-loader.gif';
import { useActiveDB } from '../../hooks/useActiveDB';
import { LoaderWrapper } from '../../Styles/Styles';
const ExpenseList = styled.div`
  height: 397px;
  overflow-y: scroll;
  color: gray;
  padding: 3px;
`;
const SingleItem = styled.div<{ active: boolean }>`
  .list-group-item {
    background-color: ${(props) => (props.active ? '#c5c4c6' : 'white')};
    color: ${(props) => (props.active ? 'black' : 'gray')};
    &:hover {
      background-color: #e0dee0;
      color: black;
      cursor: pointer;
    }
  }
`;

type MyProps = {
  SelectedExpenseType: IExpenseType | undefined;
  SetSelectedExpenseType: (IExpenseType) => void;
};
const ExpenseTypeList: React.FC<MyProps> = ({
  SelectedExpenseType,
  SetSelectedExpenseType,
}) => {
  const [selectedCaption, setSelectedCaption] =
    useState<string>('No Selection');
  const dispatch = useDispatch();
  const { databaseName } = useActiveDB();
  const expenses = useSelector(
    (state: RootState) => state.transactionReducer.expenseTypeList,
  );
  useEffect(() => {
    if (expenses.length === 0) {
      dispatch(getExpenseTypeList(databaseName!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getExpenseTypeList(databaseName!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseName, dispatch, getExpenseTypeList]);

  useEffect(() => {
    if (SelectedExpenseType) {
      setSelectedCaption(SelectedExpenseType.ExpenseName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedExpenseType]);

  const handleClick = (item: IExpenseType) => {
    SetSelectedExpenseType(item);
  };
  return (
    <>
      <div>{selectedCaption}</div>
      <ListGroup>
        <LoaderWrapper
          showLoader={
            expenses === undefined || (expenses && expenses.length === 0)
          }>
          <img alt='' src={ajaxLoader} />
        </LoaderWrapper>

        <ExpenseList>
          {expenses &&
            expenses.map((item, index) => {
              return (
                <SingleItem
                  key={`etl-${index}`}
                  active={
                    item.ExpenseTypeID === SelectedExpenseType?.ExpenseTypeID
                  }>
                  <ListGroup.Item onClick={() => handleClick(item)}>
                    {item.ExpenseName}
                  </ListGroup.Item>
                </SingleItem>
              );
            })}
        </ExpenseList>
      </ListGroup>
    </>
  );
};

export default ExpenseTypeList;
