import moment from 'moment';
import { ROOT_URL } from '../../Utils/Constants';
import { http, post } from '../../Utils/http';
import { setLoaderFlag } from '../properties/propertyActions';
import {
  ActionNameEnum,
  ActionRecord,
  ActionTypeEnum,
  AutoAction,
  CreditCardEnum,
  IResetEscrowTree,
  MonthlyRun,
  RESET_ESCROW_TREE,
  SET_MONTHLY_RECORDS,
} from './types';

export const addEscrowEntry = (
  databaseName: string,
  propertyId: number,
  expenseId: number,
  amount: number,
  monthly: number,
  escrow: boolean,
  creditCard: boolean,
) => async (dispatch) => {
  let url: string = `${ROOT_URL}/escrow/addEscrowEntry.php?db_name=${databaseName}&`;
  url = url + `propertyId=${propertyId}&`;
  url = url + `expenseId=${expenseId}&`;
  url = url + `amount=${amount}&`;
  url = url + `monthly=${monthly}&`;
  url = url + `escrow=${escrow}&`;
  url = url + `creditCard=${creditCard}`;
  await http(url, dispatch);
  // dispatch({
  //     type: GET_CREDITCARD_LINES,
  //     payload: creditcardList
  // })
};

export const runMonthlyTask = (db: string) => async (dispatch) => {
  const now = moment();
  const month: number = now.get('month') + 1;
  let sMonth: string = month.toString();
  sMonth = sMonth.length === 1 ? `0${sMonth}` : sMonth;
  const year: number = now.get('year');
  const endOfMonth: string = now.clone().endOf('month').format('DD');

  let url: string = `${ROOT_URL}/escrow/escrowMonthly.php?db_name=${db}&getActions=true`;
  //url = url + `&month=${year}-${sMonth}&lastDay=${endOfMonth}`;
  const monthlys: MonthlyRun[] = await http(url, dispatch);
  const propertyIds: number[] = [];
  const expenseIds: number[] = [];
  monthlys.forEach((m: MonthlyRun) => {
    if (propertyIds.indexOf(m.PropertyID) === -1) {
      propertyIds.push(m.PropertyID);
    }

    if (expenseIds.indexOf(m.ExpenseTypeID) === -1) {
      expenseIds.push(m.ExpenseTypeID);
    }
  });

  const strPropertyIds = propertyIds.join(',');
  const strExpenseIds = expenseIds.join(',');
  url = `${ROOT_URL}/escrow/escrowMonthly.php?db_name=${db}`;
  url = url + `&propertyIds=${strPropertyIds}&expenseIds=${strExpenseIds}`;
  url = url + `&month=${year}-${sMonth}&lastDay=${endOfMonth}`;
  const actionsPerformed: AutoAction[] = await http(url, dispatch);

  //this is where we are looking at actual payments that were inserted (not escrow but direct on statement)
  url = `${ROOT_URL}/escrow/autoPayments.php?db_name=${db}`;
  url = url + `&propertyIds=${strPropertyIds}&expenseIds=${strExpenseIds}`;
  url = url + `&month=${year}-${sMonth}&lastDay=${endOfMonth}`;
  const paymentsPerformed: AutoAction[] = await http(url, dispatch);
  const ServerRecords: MonthlyRun[] = [];
  const records: ActionRecord[] = monthlys.reduce(
    (arr: ActionRecord[], item: MonthlyRun) => {
      let record: ActionRecord;
      const action = [...actionsPerformed, ...paymentsPerformed].find(
        (a) => a.AutoPaymentID === item.AutoPaymentID,
      );
      if (action) {
        record = {
          ActionName:
            Number(item.ToEscrow) === 1
              ? ActionNameEnum.Escrow
              : ActionNameEnum.Payment,
          Address: action.Address,
          ActionType: ActionTypeEnum.NoAction,
          ExpenseName: action.ExpenseName,
          Amount: action.Amount,
          OnCreditCard: item.CC === CreditCardEnum.OnCreditCard,
        };
      } else {
        //didn't find the action. add it to another array to make a call to perform the actions
        record = {
          ActionName:
            Number(item.ToEscrow) === 1
              ? ActionNameEnum.Escrow
              : ActionNameEnum.Payment,
          Address: item.Address,
          ActionType: ActionTypeEnum.NewEntry,
          ExpenseName: item.ExpenseName,
          Amount: item.Amount,
          OnCreditCard: item.CC === CreditCardEnum.OnCreditCard,
        };
        ServerRecords.push(item);
      }
      arr.push(record);
      return arr;
    },
    [],
  );
  dispatch({
    type: SET_MONTHLY_RECORDS,
    payload: records,
  });

  if (ServerRecords.length > 0) {
    try {
      url = url = `${ROOT_URL}/escrow/persistServerRecords.php?db_name=${db}`;
      await post(url, ServerRecords, dispatch);
    } catch (e) {
      dispatch(setLoaderFlag(false));
    }
  }
};

export const resetEscrowTree = (): IResetEscrowTree => ({
  type: RESET_ESCROW_TREE,
});
