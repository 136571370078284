export const SEND_LOGIN_ACTION = 'SEND_LOGIN_ACTION';
export const SEND_LOGOUT_ACTION = 'SEND_LOGOUT_ACTION';
export const TOGGLE_LOGIN_ERROR = 'TOGGLE_LOGIN_ERROR';
//export const CHECK_STATUS = 'CHECK_STATUS';

export interface ILoginCredentials{
    userName: string,
    password: string
}

export interface ILoginResults {
    status: number,
    msg: string,
    token: string,
    username: string
  }
export interface ISendLoginAction{
    type: typeof SEND_LOGIN_ACTION,
    payload: ILoginResults
}

export interface ISendLogOutAction{
    type: typeof SEND_LOGOUT_ACTION,
    payload: any
}

export interface IToggleLoginError{
    type: typeof TOGGLE_LOGIN_ERROR,
    payload: boolean
} 

// export interface ICheckStatus{
//     type: typeof CHECK_STATUS,
//     payload: ILoginResults
// } 

export type LoginActionsType = ISendLoginAction | ISendLogOutAction | IToggleLoginError;// | ICheckStatus;