import React, {
  FC,
  SetStateAction,
  useEffect,
  useState,
  Dispatch,
  Fragment,
} from 'react';
import './MainExpensesTable.css';
import CommandButtons from './CommandButtons';
import { MyExpenseType } from '../MyExpenses';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../../Utils/datesUtil';
import { NormalizedJson } from '../Types';
import { RootState } from '../../../reducers';
import { NormalizedAllData } from '../../../modules/myExpenses/types';
import { setActiveJson } from '../../../modules/myExpenses/expensesSlice';

const MainExpensesTable: FC<MyExpenseType> = ({ setShowData }) => {
  const [list, setList] = useState<any>();
  const [currentExpenses, setCurrentExpenses] = useState<NormalizedAllData>();
  const dispatch = useDispatch();
  const _list = useSelector(
    (state: RootState) => state.myExpensesReducer.savedRecords.expenses,
  );
  const current = useSelector(
    (state: RootState) => state.myExpensesReducer.currentExpenses,
  );
  useEffect(() => {
    if (_list) {
      setList(_list);
    }
  }, [_list]);

  if (!list) {
    return null;
  }
  const onClickLoadCurrent = () => {
    dispatch(setActiveJson(current));
    setShowData(true);
  };
  return (
    <div className='MainTableWrapper'>
      <div className='loadCurrent' onClick={onClickLoadCurrent}>
        Load Current
      </div>
      {list.map &&
        list.map((item: NormalizedJson,index:number) => {
          return (
            <Fragment key={index}>
              <div className='cell1'>
                {formatDate(item.CreatedTime)}
              </div>
              <div className='cell1 topic'>{item.Label}</div>
              <CommandButtons
                setShowData={setShowData}
                selectedItem={JSON.parse(item.Json)}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export default MainExpensesTable;
