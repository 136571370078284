import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useActiveDB } from '../../../hooks/useActiveDB';
import { getBankEntries } from '../../../modules/match/matchActions';
import { BankStatementEntry } from '../../../modules/match/types';
import { getStatementLinesAction } from '../../../modules/statement/statementActions';
import { RootState } from '../../../reducers';
import './Match.css';
import MatchRecord from './MatchRecord';
const UnMatch = () => {
  const dispatch = useDispatch();
  const { databaseName } = useActiveDB();
  const [_bl, setBl] = useState<BankStatementEntry[]>([]);
  const bankLines = useSelector(
    (state: RootState) => state.matchReducer.bankStatementLines,
  );
  useEffect(() => {
    dispatch(getBankEntries('all', databaseName));
  }, []);

  useEffect(() => {
    setBl(bankLines);
  }, [bankLines]);
  return (
    <div className='FoundMatchWrapper'>
      {_bl.map((line: BankStatementEntry) => (
        <MatchRecord {...line} />
      ))}
    </div>
  );
};

export default UnMatch;
