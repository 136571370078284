import React from 'react';
import { MovingOutProps } from '../MovingOutModal';
import { Row} from "react-grid-system";
import styled from 'styled-components';

const Col = styled.div`
  min-width: 200px; 
  max-width: 226px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 136px;
  overflow: hidden;
  height: 24px; 
  padding-left: 33px;
`;
const ListView = styled.div<{showRight:boolean}>`
    height: 200px; 
    width:464px;   
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    left:${ prop => prop.showRight ? '-464px' : '0'};
    transition: left 1s;    
    border: 1px solid gray;
`;


const MovingOutList:React.FC<MovingOutProps> = (props:MovingOutProps) => {    
    return (
              <>
              {
                    props.movingOuts.length === 0 && <ListView showRight={props.showRightPane!}>No Move outs!!</ListView>
                }
                {
                    props.movingOuts.length > 0 &&
                    <ListView showRight={props.showRightPane!}>
                        {props.movingOuts.map((mo,index)=>{
                            return (
                                    <Row key={`mo-${index}`}>
                                        <Col>{mo.TenantName}</Col>
                                        <Col>{mo.DayToMoveOut} days</Col>
                                    </Row>
                                )
                        })}
                    </ListView>
                }  
              </>                            
        
    );
};

export default MovingOutList;