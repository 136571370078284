import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useActiveUser } from '../../hooks/useActiveUser';
import { sendLogoutAction } from '../../modules/Login/loginActions';
import { CURRENT_PAGE } from '../../Utils/Constants';
import { LoginBackground } from './styles';

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  const userName = useActiveUser();
  dispatch(sendLogoutAction(userName));
  useEffect(() => {
    localStorage.removeItem(CURRENT_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <LoginBackground authenticate={false}></LoginBackground>;
};

export default Logout;
