import {
  ADD_REMOVE_TENANT,
  GET_PROPERTY_LIST,
  IDataBase,
  IProperty,
  SET_ACTIVE_DB,
  SET_ACTIVE_PROPERTY,
  SET_LOADER_FLAG,
  SET_MOVEOUT_MONTHLYS,
  UPDATE_SECURITY_DEPOSITS,
  UPDATE_SINGLE_TENANT,
} from './types';
import {
  IMonthlyView,
  ISecurityDeposit,
  ITenant,
  MAKE_RENT_PAYMENT,
} from '../tenants/types';
import { databases, DB_KEY, devDatabases } from '../../Utils/Constants';
export interface IPropertyReducer {
  propertyList: IProperty[];
  selectedProperty: number;
  databases: IDataBase[];
  selectedDatabaseID: number;
  showLoader: boolean;
  monthlyViews: IMonthlyView[];
}

const localStorageDbID: number = localStorage.getItem(DB_KEY)
  ? parseInt(localStorage.getItem(DB_KEY)!)
  : 2;
localStorage.setItem(DB_KEY, localStorageDbID.toString());
const INIT_STATE: IPropertyReducer = {
  propertyList: [],
  selectedProperty: 0,
  databases:
    window.location.hostname === 'localhost' ? devDatabases : databases,
  selectedDatabaseID: localStorageDbID,
  showLoader: false,
  monthlyViews: [],
};

const updatePayment = (state, payload) => {
  let property = state.propertyList.find((p) =>
    p.Tenants.find((t) => t.TenantID === payload.tenantId),
  );
  let tenant = property.Tenants.find((t) => t.TenantID === payload.tenantId);
  tenant = {
    ...tenant,
    PaidAmount: tenant.PaidAmount + parseFloat(payload.amount),
  };
  property = {
    ...property,
    Tenants: property.Tenants.map((t, i) => {
      if (t.TenantID === tenant.TenantID) {
        return tenant;
      }
      return t;
    }),
  };
  return {
    ...state,
    propertyList: state.propertyList.map((p) => {
      if (p.PropertyID === property.PropertyID) {
        return property;
      }
      return p;
    }),
  };
};

export const propertyReducer = (
  state: IPropertyReducer = INIT_STATE,
  action: any,
): IPropertyReducer => {
  switch (action.type) {
    case GET_PROPERTY_LIST:
      const defaultPropertyID: number = action.payload[0].PropertyID;
      return {
        ...state,
        propertyList: action.payload,
        selectedProperty: defaultPropertyID,
      };
    case SET_ACTIVE_PROPERTY:
      const { payload } = action;
      let index = payload.PropertyID;
      return { ...state, selectedProperty: index };
    case UPDATE_SINGLE_TENANT:
      return {
        ...state,
        propertyList: getUpdatedProperies(state.propertyList, action.payload),
      };
    case ADD_REMOVE_TENANT:
      return {
        ...state,
        propertyList: addRemoveTenants(state.propertyList, action.payload),
      };
    case MAKE_RENT_PAYMENT:
      return updatePayment(state, action.payload);
    case SET_ACTIVE_DB:
      return { ...state, selectedDatabaseID: action.payload.ID };
    case SET_LOADER_FLAG:
      return { ...state, showLoader: action.payload };
    case UPDATE_SECURITY_DEPOSITS:
      return {
        ...state,
        propertyList: getUpdatedPropertiesWithSecurityDeposit(
          state.propertyList,
          action.payload,
        ),
      };
    case SET_MOVEOUT_MONTHLYS:
      return {
        ...state,
        monthlyViews: action.payload,
      };
    default:
      return state;
  }
};

const getUpdatedPropertiesWithSecurityDeposit = (
  properties: IProperty[],
  sd: ISecurityDeposit,
): IProperty[] => {
  return properties.map((prop) => {
    const newProp = { ...prop };
    newProp.Tenants = prop.Tenants.map((t) => {
      if (t.TenantID === sd.TenantID) {
        sd.Date = '';
        t.SecurityDeposits?.push(sd);
      }
      return t;
    });
    return newProp;
  });
};

const getUpdatedProperies = (
  properties: IProperty[],
  tenant: ITenant,
): IProperty[] => {
  return properties.map((prop) => {
    const newProp = { ...prop };
    let tenantWasUpdated = false;
    newProp.Tenants = prop.Tenants.map((t) => {
      if (t.TenantID === tenant.TenantID) {
        tenantWasUpdated = true;
        return tenant;
      }
      return t;
    });

    //adding brand new tenant
    if (!tenantWasUpdated && tenant.PropertyID === prop.PropertyID) {
      tenant.SecurityDeposits = [];
      newProp.Tenants.push(tenant);
    }
    return newProp;
  });
};
function addRemoveTenants(
  propertyList: IProperty[],
  payload: ITenant,
): IProperty[] {
  return propertyList.map((prop) => {
    const newProp = { ...prop };

    //Tenant belong to the property ==== add the tenant
    if (newProp.PropertyID === payload.PropertyID) {
      if (
        //@ts-ignore
        newProp.Tenants.findIndex((t) => t.TenantID == payload.TenantID) === -1
      ) {
        if (payload.Active?.toString() === '1') {
          newProp.Tenants.push(payload);
          return newProp;
        }
      }
      //remove the tenant
      newProp.Tenants = prop.Tenants.reduce(
        (tenantArr: ITenant[], tenant: ITenant) => {
          if (payload.TenantID !== tenant.TenantID) {
            tenantArr.push(tenant);
          }
          return tenantArr;
        },
        [],
      );
    }

    return newProp;
  });
}
