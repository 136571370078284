import { TOKEN_KEY, USER_KEY } from "../../Utils/Constants";
import {LoginActionsType, SEND_LOGIN_ACTION, SEND_LOGOUT_ACTION, TOGGLE_LOGIN_ERROR} from "./types";

interface ILoginReducer{
    token: string,
    userName: string,
    hideError: boolean
}

const INIT_STATE: ILoginReducer = {
    token: '',
    userName: '',
    hideError:true
};

export const loginReducer = (state:ILoginReducer = INIT_STATE, action:LoginActionsType): ILoginReducer => {    
    switch (action.type){
        case SEND_LOGIN_ACTION:
            const {username,token,status} = action.payload;
            if(status === 504){
                return {...state,hideError:false};
            }
            if(token){
                localStorage.setItem(TOKEN_KEY, token); 
                localStorage.setItem(USER_KEY, username); 
            }
            
            return {...state, userName: username, token, hideError:status === 200} 
        case TOGGLE_LOGIN_ERROR:
            return {...state, hideError: action.payload}             
        case SEND_LOGOUT_ACTION: 
            localStorage.removeItem(TOKEN_KEY);   
            localStorage.removeItem(USER_KEY);   
            return INIT_STATE;
        default:
            return state;
    }
}