import { IStatementLine } from "../statement/types";
import { CreditcardActionsType, GET_CREDITCARD_LINES, RESET_CREDITCARD_LINES } from "./types";

export interface ICreditcardReducer{
    creditcardList:IStatementLine[]
}

const INIT_STATE:ICreditcardReducer = {
    creditcardList:[]
}

export const creditCardReducer = (
    state:ICreditcardReducer=INIT_STATE,
    action:CreditcardActionsType
  ) => {
   switch(action.type){
       case GET_CREDITCARD_LINES:
           return {
            creditcardList: action.payload
           };
       case RESET_CREDITCARD_LINES:
           return {
            creditcardList:[]
           };
       default:
           return state;
   }
}