import React, {
  FC,
  FormEvent,
  useRef,
  useState,
  KeyboardEvent,
  useEffect,
} from 'react';
import { NormalizedData } from '../Types';

type MyProps = {
  item: NormalizedData;
  colorClass: string;
  lastRowClass: string;
  updateInput: (e: FormEvent<HTMLInputElement>, item: NormalizedData) => void;
};
const DollarsInput: FC<MyProps> = ({
  item,
  colorClass,
  lastRowClass,
  updateInput,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [numberValue, setNumberValue] = useState<string>('');
  const [dollars, setDollars] = useState<string>();
  useEffect(() => {
    if (item) {
      setNumberValue(item.Amount.toString());
      setDollars(
        new Intl.NumberFormat(`en-US`, {
          currency: `USD`,
          style: 'currency',
        }).format(item.Amount),
      );
    }
  }, [item]);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
   persistChange(e);
  };
  const onFocus = (e: FormEvent<HTMLInputElement>) => {
    e.currentTarget.setSelectionRange(0, numberValue.length);
  };

  const onBlur = (e: any) => {
    e.key = 'Enter';
    persistChange(e);
  }

  const persistChange = (e) => {
    setIsEditing(false);

    let newAmount: any = parseFloat(numberValue);

    const _item: NormalizedData = {
      ...item,
      Amount: newAmount,
      Pristine: item.PrevValue === newAmount,
    };
    updateInput(e, _item);
  }
  return (
    <>
      {isEditing && (
        <input
          className={`${colorClass} inputBox`}
          value={numberValue}
          onChange={(e) => setNumberValue(e.currentTarget.value)}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          key={item.Key}
          ref={inputRef}
        />
      )}
      {!isEditing && (
        <div
          className={`cell  ${colorClass} lastRowCell ${lastRowClass} spanDollars`}
          onClick={() => {
            setIsEditing(true);
            setTimeout(() => inputRef.current && inputRef.current.focus(), 100);
          }}>
          {item.Pristine && <div>{`${dollars}`}</div>}
          {!item.Pristine && (
            <div className='prevValue'>
              <div>({item.PrevValue})</div>
              <div className='not_pristine'>{`${dollars}`}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DollarsInput;
