import { Dispatch } from 'react';
import { IDepositWithdrawal } from '../../components/Transactions/Transactions';
import { ROOT_URL } from '../../Utils/Constants';
import { buildTree, setEscrowHashTable } from '../../Utils/expensesUtils';
import { http } from '../../Utils/http';
import {
  IEscrowActions,
  IEscrowExpense,
  SET_ESCROW_HASHTABLE,
  SET_ESCROW_TREE,
  SET_ESCROW_TREE_DATA,
} from '../escrow/types';
import { IProperty } from '../properties/types';
import {
  GET_EXPENSE_TYPE_LIST,
  ITransactionActions,
  IPayment,
  IExpenseType,
  IJustPaidExpense,
  ADD_JUST_PAID_ENTRY,
  IAddJustPaidEntry,
} from './types';

export const loadExpenseTypeList =
  (dbname: string): any =>
  async (
    dispatch: Dispatch<ITransactionActions | IEscrowActions>,
    getState: any,
  ): Promise<void> => {
    const url1: string = `${ROOT_URL}/expenses/expenses.php?db_name=${dbname}`;
    const { expenses } = await http(url1, dispatch);
    dispatch({
      type: GET_EXPENSE_TYPE_LIST,
      payload: expenses,
    });
  };

export const getExpenseTypeList =
  (dbname: string): any =>
  async (
    dispatch: Dispatch<ITransactionActions | IEscrowActions>,
    getState: any,
  ): Promise<void> => {
    const url2: string = `${ROOT_URL}/escrow/escrow.php?db_name=${dbname}`;

    //making sure that we don't have expenses in the store yet, if we don't get it otherwise, take it from
    //the store
    const state = getState();
    const _expenses = state.transactionReducer.expenseTypeList;
    if (_expenses.length === 0) {
      dispatch(loadExpenseTypeList(dbname));
    }

    const escrows: IEscrowExpense[] = await http(url2, dispatch);
    const expenseTypeIds = escrows.reduce(
      (arr: number[], escrow: IEscrowExpense) => {
        if (arr.findIndex((i) => i === escrow.ExpenseTypeID) === -1) {
          arr.push(escrow.ExpenseTypeID);
        }
        return arr;
      },
      [],
    );
    const url3: string = `${ROOT_URL}/escrow/escrowExpense.php?db_name=${dbname}&expIds=${expenseTypeIds.join(
      ',',
    )}`;
    const escrowExpenses: IEscrowExpense[] = await http(url3, dispatch);
    const collectedHashTable = setEscrowHashTable(escrows);
    let escrowHashtable = setEscrowHashTable(
      escrowExpenses,
      collectedHashTable,
    );

    //calculating escrow-collected vs. escrow-expense-paid
    escrowHashtable = Object.keys(escrowHashtable).reduce(
      (hash: object, key) => {
        let balance =
          (collectedHashTable[key] || 0) - (escrowHashtable[key] || 0);
        hash[key] = balance;
        return hash;
      },
      {},
    );

    dispatch({
      type: SET_ESCROW_HASHTABLE,
      payload: escrowHashtable,
    });

    dispatch({
      type: SET_ESCROW_TREE_DATA,
      payload: escrowExpenses,
    });

    dispatch({
      type: SET_ESCROW_TREE,
      payload: buildTree(escrows, escrowHashtable),
    });
  };

export const MakeExpensePayment =
  (
    data: IPayment,
    selectedExpenseType: IExpenseType,
    prop: IProperty,
    dbname: string,
  ) =>
  async (dispatch) => {
    let url: string = `${ROOT_URL}/expenses/payExpense.php?db_name=${dbname}&`;
    url = url + `propertyId=${prop.PropertyID}&`;
    url = url + `expenseTypeId=${selectedExpenseType.ExpenseTypeID}&`;
    url = url + `amount=${data.Amount}`;
    if (data.Comments) {
      url = url + `&comment=${data.Comments}`;
    }

    if (data.Notes) {
      url = url + `&notes=${data.Notes}`;
    }

    if (data.CheckNum) {
      url = url + `&checkNumber=${data.CheckNum}`;
    }

    if (data.IsCreditCard) {
      url = url + '&onCreditcard=true';
    }

    try {
      await http(url, dispatch);
      return true;
    } catch (e) {
      return false;
    }
  };

export const makeDeposit =
  (data: IDepositWithdrawal, propertyId: number, dbname: string) =>
  async (dispatch) => {
    let url: string = `${ROOT_URL}/transactions/deposit.php?db_name=${dbname}&`;
    url = url + `propertyId=${propertyId}&`;
    url = url + `amount=${data.Amount}`;
    url = url + `&comment=${data.Comment}`;

    try {
      await http(url, dispatch);
      return true;
    } catch (e) {
      return false;
    }
    //https://aviezri.com/api/objects/transactions/deposit.php?propertyId=1&amount=1234.33&comment=comment
  };

export const makeWithdrawal =
  (data: IDepositWithdrawal, propertyId: number, dbname: string) =>
  async (dispatch) => {
    let url: string = `${ROOT_URL}/transactions/withdrawal.php?db_name=${dbname}&`;
    url = url + `propertyId=${propertyId}&`;
    url = url + `amount=${data.Amount}`;
    url = url + `&comment=${data.Comment}`;
    if (data.CheckNumber) {
      url = url + `&checkNumber=${data.CheckNumber}`;
    } else {
      url = url + `&checkNumber=`;
    }

    try {
      await http(url, dispatch);
      return true;
    } catch (e) {
      return false;
    }
    //https://aviezri.com/api/objects/transactions/withdrawal.php?propertyId=1&amount=1234.33&comment=comment&checkNumber=123 HTTP/1.1
  };

export const addJustPaidEntry = (
  entry: IJustPaidExpense,
): IAddJustPaidEntry => ({
  type: ADD_JUST_PAID_ENTRY,
  payload: entry,
});
