import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { FC } from 'react';
import { BuildingName } from '../../modules/devices/types';

export const Buildings: FC<{
  value: string;
  setvalue: (v: BuildingName) => void;
  setApartment: (v: string) => void;
}> = ({ value, setvalue, setApartment }) => {
  const handleChange = (e) => {
    setvalue(e.target.value as BuildingName);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>Building</InputLabel>
      <Select
        error={value === ''}
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        value={value}
        label='Building'
        onChange={(e) => handleChange(e)}
        disabled={true}>
        <MenuItem value='75Monroe'>75 Monroe</MenuItem>
        <MenuItem value='476-478Middle'>476-478 Middle</MenuItem>
        <MenuItem value='11-13Durrell'>11-13Durrell</MenuItem>
        <MenuItem value='9Linden'>9 Linden</MenuItem>
      </Select>
    </FormControl>
  );
};
