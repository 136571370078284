import { NumericFilter } from '../Utils/numericFilter';

export const useForm = (data: object, setData: (input: any) => void) => {
  const onChange = (e) => {
    let newData = {
      ...data,
    };
    if (e.target.type === 'checkbox') {
      newData[e.target.name] = e.target.checked;
    } else if (e.target.dataset.numeric) {
      if (e.target.value === '') {
        newData[e.target.name] = 0;
      } else {
        newData[e.target.name] = NumericFilter(e.target.value);
      }
    } else {
      newData[e.target.name] = e.target.value;
    }
    setData(newData);
  };
  return { onChange };
};
