export const GET_STATEMENT_LINES = 'STATEMENT/GET_STATEMENT_LINES';
export const CHECK_LINE = 'STATEMENT/CHECK_LINE';
export const APPLY_STATEMENT_CHANGES = 'STATEMENT/APPLY_STATEMENT_CHANGES';
export const CLEAR_STATEMENT = 'STATEMENT/CLEAR_STATEMENT';
export const APPLY_AMOUNT_CHANGE = 'STATEMENT/APPLY_AMOUNT_CHANGE';

export interface IStatementLine {
  TransactionTime: Date;
  Address: string;
  eCheckNumber: string | null;
  eAmount: number | null;
  eComment: string | null;
  ExpenseName: string | null;
  mortgageAmount: number | null;
  mortgageComment: string | null;
  wAmount: number | null;
  wComment: string | null;
  wCheckNum: string | null;
  depositAmount: number | null;
  depositComment: string | null;
  rentAmount: number | null;
  rentDate: Date | null;
  rentComment: string | null;
  cronid: number;
  tenantName: string | null;
  reviewed: number;
  Notes: string | null;
  mortgageCheck: string | null;
  ccCheck: string | null;
}

export interface IGetStatementAction {
  type: typeof GET_STATEMENT_LINES;
  payload: IStatementLine[];
}

export interface IApplyAmountChangeAction {
  type: typeof APPLY_AMOUNT_CHANGE;
  payload: IStatementLine[];
}

interface ICheckStatementLine {
  cronid: number;
  isChecked: boolean;
}
export interface ICheckLineAction {
  type: typeof CHECK_LINE;
  payload: ICheckStatementLine;
}

export interface IApplyStatmentChangesAction {
  type: typeof APPLY_STATEMENT_CHANGES;
  payload: number[];
}

export interface IClearStatementAction {
  type: typeof CLEAR_STATEMENT;
}
export type StatementActionsType =
  | IGetStatementAction
  | ICheckLineAction
  | IApplyStatmentChangesAction
  | IClearStatementAction
  | IApplyAmountChangeAction;
