import styled from 'styled-components';

export const TableCell = styled.div<{ bold?: boolean }>`
  background-color: whitesmoke;
  margin: 3px;
  font-weight: ${(prop) => (prop.bold ? 'bold' : 'inherit')};
  border-bottom: ${(prop) => (prop.bold ? '4px solid red' : 'none')};
  @media (max-width: 600px) {
    margin: 0;
  }
`;

export const TableHeaderRow = styled.div<{ showSpace: boolean }>`
  display: grid;
  grid-template-columns: ${(prop) =>
    prop.showSpace ? '1fr 1fr 1fr 1fr 17px' : '1fr 1fr 1fr 1fr'};
  grid-template-rows: 33px auto;
  @media (max-width: 600px) {
    background-color: whitesmoke;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border: 1px solid gray;
  }
`;

export const TableBody = styled.div`
  overflow-y: auto;
  max-height: 400px;
  width: 100%;
`;

export const CreditCardsContainer = styled.div`
  display: grid;
  grid-template-rows: 67px auto;
  width: 100%;
  height: 500px;
  background-color: #343a40;
  @media (max-width: 600px) {
    grid-template-rows: 164px auto;
  }
`;
export const Controls = styled.div`
  background-color: whitesmoke;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const VControls = styled.div`
  background-color: whitesmoke;
  padding: 5px;
  margin: 5px;
  margin-top: 3px;
  display: grid;
  grid-template-rows: 50px 50px auto;
  grid-template-columns: 1fr;
  justify-content: space-evenly;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  button {
    width: 177px;
    margin: auto;
    padding: 5px;
  }
`;
export const Label = styled.span`
  padding-right: 4px;
`;
export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  @media (max-width: 600px) {
    grid-template-columns: 100%;
    grid-template-rows: 20% 80%;
  }
`;
