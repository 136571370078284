import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import UnMatch from './UnMatch';
import ManualMatch from './ManualMatch';

const MatchTabs = () => {
  type TabValue = 'Manual' | 'Unmatch';
  const [value, setValue] = useState<TabValue>('Manual');
  const handleChange = (event: any) => {
    switch (event.target.innerHTML) {
      case 'UnMatch':
        setValue('Unmatch');
        break;
      default:
        setValue('Manual');
    }
  };
  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          aria-label='secondary tabs example'>
          <Tab value='Manual' label='Manual Match' />
          <Tab value='Unmatch' label='UnMatch' />
        </Tabs>
      </Box>
      {value === 'Manual' && <ManualMatch />}
      {value === 'Unmatch' && (
        <div>
          <UnMatch />
        </div>
      )}
    </div>
  );
};

export default MatchTabs;
