import {
  APPLY_AMOUNT_CHANGE,
  APPLY_STATEMENT_CHANGES,
  CHECK_LINE,
  CLEAR_STATEMENT,
  GET_STATEMENT_LINES,
  IStatementLine,
  StatementActionsType,
} from './types';
import { MultiLineSelector } from '../../Utils/MultiLineSelector';

export interface IStatementReducer {
  statementLines: IStatementLine[];
  totalAmount: number;
}

const INIT_STATE: IStatementReducer = {
  statementLines: [],
  totalAmount: 0,
};

export const statementReducer = (
  state: IStatementReducer = INIT_STATE,
  action: StatementActionsType,
) => {
  const mlSelector = new MultiLineSelector();
  let updatedStatementLines: IStatementLine[];
  switch (action.type) {
    case GET_STATEMENT_LINES:
      return { ...state, statementLines: action.payload };
    case CHECK_LINE:
      const { cronid, isChecked } = action.payload;
      let selectedCheckNumber: string = '';

      updatedStatementLines = state.statementLines.map(
        (line: IStatementLine) => {
          //collecting all the cronIds that associated with the same check number
          mlSelector.addId(line);
          if (line.cronid === cronid) {
            const checkNum = mlSelector.getCheckNumber(line);
            selectedCheckNumber = checkNum === 'NULL' ? '' : checkNum;
            line.reviewed = isChecked ? 1 : 0;
            return line;
          }
          return line;
        },
      );
      //then marking as selected all the lines that have the same check number
      updatedStatementLines = mlSelector.updateLines(
        updatedStatementLines,
        selectedCheckNumber,
        isChecked,
      );
      return {
        ...state,
        statementLines: updatedStatementLines,
        totalAmount: mlSelector.totalSelected,
      };
    case APPLY_STATEMENT_CHANGES:
      updatedStatementLines = state.statementLines.reduce(
        (arr: IStatementLine[], line) => {
          if (action.payload.indexOf(line.cronid) === -1) {
            arr.push(line);
          }
          return arr;
        },
        [],
      );
      return {
        ...state,
        statementLines: updatedStatementLines,
        totalAmount: 0,
      };
    case CLEAR_STATEMENT:
      return {
        ...state,
        statementLines: [],
        totalAmount: 0,
      };
    case APPLY_AMOUNT_CHANGE:
      return {
        ...state,
        statementLines: action.payload
      }
    default:
      return state;
  }
};
