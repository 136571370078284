import React, { FC } from 'react';
import styled from 'styled-components';
import Cancel from '@material-ui/icons/Cancel';

const FilterPopupWrapper = styled.div`
  width: 300px;
  height: 140px;
  border: 1px solid gray;
  border-radius: 4px;
  z-index: 10;
  background-color: #ffffff;
  position: absolute;
  right: 57px;
  top: 14px;
  cursor: default;
`;

const CloseWrapper = styled.div`
  position: relative;
  float: right;
  cursor: pointer;
`;
export const FilterPopup: FC<{ setShowCursorPopup: (v: boolean) => void }> = ({
  setShowCursorPopup,
}) => {
  return (
    <FilterPopupWrapper>
      <CloseWrapper>
        <Cancel onClick={() => setShowCursorPopup(false)} />
      </CloseWrapper>
    </FilterPopupWrapper>
  );
};
