import styled from "styled-components";

export const Cell = styled.div`
  width:40px;
  height: 40px;
  border: 1px solid gray;
`;

export const BodyContent = styled.div`
    height:231px;
    overflow-y:auto;
`;

export const BalanceRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 122px auto;
  border:1px solid grey;
  button{        
    border-radius:8px;
    padding:0;
    margin-right: 10px;
  } 
`;

export const ButtonContainer = styled.div`
      width: 65px;
      max-height: 27px;
      margin-top:24px;
      justify-self:end;
      display:flex;
      flex-direction:row;
      justify-content:space-evenly;
`;


export const BalanceFigures = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
`;
export const Icon = styled.div`
   top: -2px;
    position: relative;
`;
export const Table = styled.div<{rows:number}>`
  display: grid;
  grid-template-columns: repeat(9,1fr);
  grid-template-rows:${props => { return `repeat(${props.rows},1fr)`}}
`;