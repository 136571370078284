export const NumericFilter = (input: string): string => {
  const strArr = input.split('');
  let foundDot = false;
  let foundDash = false;
  return strArr.reduce((str, ch) => {
    if (ch === '.' && !foundDot) {
      str = str + '.';
      foundDot = true;
      return str;
    }

    if (!foundDash && ch === '-' && input.indexOf('-') === 0) {
      foundDash = true;
      str = str + '-';
      return str;
    }
    if (/[0-9]+/.test(ch)) {
      str = str + ch;
      return str;
    }
    return str;
  }, '');
};
