import { IProperty } from '../../../modules/properties/types';
import { ITenant, ITenantMovingOut } from '../../../modules/tenants/types';
import { daysDiff } from '../../../Utils/datesUtil';

export const WhoIsMovingOut = (propertyList: IProperty[]) => {
  const properties: IProperty[] = propertyList || [];
  const Tenants: ITenant[] = properties.reduce(
    (tenantArr: ITenant[], p: IProperty) => {
      tenantArr = [...tenantArr, ...p.Tenants];
      return tenantArr;
    },
    [],
  );
  const movingOut: ITenantMovingOut[] = Tenants.reduce(
    (movOutArr: ITenantMovingOut[], tenant) => {
      const diff: number = daysDiff(tenant.LeaseExpirationDate); // movOut.diff(today, 'days');
      if (diff < 61 && diff > 0) {
        let movingOut: ITenantMovingOut = {
          TenantName: tenant.TenantName,
          DayToMoveOut: diff,
        };
        movOutArr.push(movingOut);
      }
      return movOutArr;
    },
    [],
  );
  return movingOut;
};
