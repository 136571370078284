import { RefObject } from 'react';

export const GET_BANKMATCH_LINES = 'match/GET_BANKMATCH_LINES';
export const SELECT_BANKMATCH_LINE = 'match/SELECT_BANKMATCH_LINE';
export const POST_MATCH_SETTELE = 'match/POST_MATCH_SETTELE';
export const UN_MATCH = 'match/UN_MATCH';

export type BankStatementEntry = {
  transactionNumber: string;
  date: string;
  description: string;
  amount: string;
  checknumber?: string;
  cron_id?: string;
  matchId: number | null;
  selected: boolean;
};

export type MatchRecordEntry = {
  cronId?: number;
  bankMessage: string;
  dbMessage: string;
  amount: number;
  bankId?: number;
};

export type PostMatchSelection = {
  matchId: number;
  statementIds: number[];
};

export type BankRefObj = {
  ref: RefObject<HTMLDivElement>;
  amount: string;
  matchId: number | null;
};
export interface IGetMatchBankLinesAction {
  type: typeof GET_BANKMATCH_LINES;
  payload: BankStatementEntry[];
}

export interface ISelectBankmatchLine {
  type: typeof SELECT_BANKMATCH_LINE;
  payload: { matchId: number };
}

export interface IPostMatchSettele {
  type: typeof POST_MATCH_SETTELE;
  payload: PostMatchSelection;
}

export interface IUnMatch {
  type: typeof UN_MATCH;
  payload: number; //matchId
}
export type MatchActionsType =
  | IGetMatchBankLinesAction
  | ISelectBankmatchLine
  | IPostMatchSettele
  | IUnMatch;
