import { Col, Row } from 'react-grid-system';
import { InputGroup, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { ControlsGroup } from '../../resources/Theme';
import styled from 'styled-components';
import { WhoDidntPayModal } from './WhoDidntPayModal';
import { MovingOutModal } from './MovingOutModal';
import {
  IRentPayment,
  ITenant,
  ITenantMovingOut,
} from '../../modules/tenants/types';
import { IProperty } from '../../modules/properties/types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { useActiveDB } from '../../hooks/useActiveDB';
import { payRentAction } from '../../modules/tenants/tenantsActions';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ManageTenantsModal } from './ManageTenantsModal';
import { RootState } from '../../reducers';
import { WhoIsMovingOut } from './MovingOut/utils';
import TenantsManagement from './TenantDetails/TenantsManagement';
import { EntryType, IRawBalanceEntry } from '../../modules/balance/types';
import { updateBalanceFigures } from '../../modules/balance/balanceActions';

type MyProps = {
  mdSize: number;
  smSize: number;
  balanceToPay: number;
  selectedTenant: ITenant;
  propertyID: number;
  setCompletePayments: (comment: string, amount: number) => void;
};

type MyFormData = {
  amountToPay: number;
  comment?: string;
};
export const ListView = styled.div`
  height: 200px;
  border: 1px solid gray;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const RentalPaymentControls = (props: MyProps) => {
  const [formData, setFormData] = useState<MyFormData>({ amountToPay: 0 });
  const [showMovingOut, setShowMovingOut] = useState<boolean>(false);
  const [selectedTenant, setSelectedTenant] = useState<ITenant>({});
  const [showTenantsManagement, setShowTenantsManagement] =
    useState<boolean>(false);
  const [movingOuts, setMovingOuts] = useState<ITenantMovingOut[]>();
  const [allTenants, setAllTenants] = useState<ITenant[]>([]);
  const [showWhoDidntPay, setShowWhoDidntPay] = useState<boolean>(false);
  const [showManageTenants, setShowManageTenants] = useState<boolean>(false);
  const { databaseName } = useActiveDB();
  const dispatch = useDispatch();
  const propertyList = useSelector(
    (s: RootState) => s.propertyReducer.propertyList,
  );
  const { onChange } = useForm(formData, setFormData);
  // const myRef = useRef(null);

  useEffect(() => {
    setFormData({
      ...formData,
      amountToPay: props.balanceToPay,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.balanceToPay]);
  useEffect(() => {
    const mo: ITenantMovingOut[] = WhoIsMovingOut(propertyList);
    setMovingOuts(mo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyList]);

  const handleFocus = (e) => {
    e.target.select();
  };

  const payRent = () => {
    const payment: IRentPayment = {
      amount: formData.amountToPay,
      comments: formData.comment,
      tenantId: props.selectedTenant.TenantID,
      tenantName: props.selectedTenant.TenantName!,
      propertyId: props.propertyID,
    };

    dispatch(payRentAction(payment, databaseName!));
    //updating the UI balance
    //UPDATE_BALANCE_FIGURES
    const balanceEntry: IRawBalanceEntry = {
      name: EntryType.Income,
      Address: '',
      PropertyID: payment.propertyId!,
      Amount: payment.amount,
    };
    dispatch(updateBalanceFigures(balanceEntry));
    setFormData({ comment: '', amountToPay: 0 });
    props.setCompletePayments(formData.comment!, formData.amountToPay);
  };

  const openWhoDidntPay = () => {
    if (propertyList) {
      let at = propertyList.reduce((tArr: ITenant[], property: IProperty) => {
        tArr = [...tArr, ...property.Tenants];
        return tArr;
      }, []);
      setAllTenants(at);
    }
    setShowWhoDidntPay(true);
  };
  return (
    <Col className='border bg-secondary' lg={props.mdSize} sm={props.smSize}>
      <Row>
        <TenantsManagement
          showTenantsManagement={showTenantsManagement}
          setSelectedTenant={setSelectedTenant}
          selectedTenant={selectedTenant}
          propertyID={props.propertyID}
          closeWindow={() => {
            setShowTenantsManagement(false);
          }}
        />
        <ControlsGroup>
          <InputGroup size='sm' className='mb-3'>
            <InputGroup.Prepend>
              <InputGroup.Text id='inputGroup-sizing-sm'>
                Amount
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              data-numeric={true}
              value={formData.amountToPay}
              name='amountToPay'
              onChange={onChange}
              autoFocus
              //ref={input => input && input.focus()}
              onFocus={handleFocus}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id='inputGroup-sizing-sm'>
                Comments
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as='textarea'
              name='comment'
              value={formData.comment}
              onChange={onChange}
            />
          </InputGroup>
          <InputGroup>
            <ButtonContainer>
              <StyledButton onClick={() => setShowManageTenants(true)}>
                Manage Tenants
              </StyledButton>
              <StyledButton onClick={() => setShowTenantsManagement(true)}>
                Add New Tenant
              </StyledButton>
            </ButtonContainer>
          </InputGroup>
        </ControlsGroup>
      </Row>

      <Row>
        <Col lg={5} sm={12}>
          <StyledButton disabled={formData.amountToPay === 0} onClick={payRent}>
            Pay Rent
          </StyledButton>
        </Col>
        <Col className='22d-none 22d-sm-inline-block' lg={2}>
          <StyledButton sm-hidden onClick={() => setShowMovingOut(true)}>
            {movingOuts && movingOuts.length > 0 && (
              <ChatIcon style={{ color: 'red' }} />
            )}
            {movingOuts && movingOuts.length === 0 && (
              <CheckCircleOutlineIcon style={{ color: 'green' }} />
            )}
          </StyledButton>
        </Col>
        <Col className='border rounded-sm d-sm-block' lg={5} sm={12}>
          <StyledButton onClick={openWhoDidntPay}>Who Didn't Pay</StyledButton>
        </Col>
      </Row>
      <WhoDidntPayModal
        showWhoDidntPay={showWhoDidntPay}
        whoDidntPayList={allTenants}
        closeModal={() => setShowWhoDidntPay(false)}
      />

      <MovingOutModal
        showMovingOut={showMovingOut}
        setShowMovingOut={setShowMovingOut}
        movingOuts={movingOuts || []}
      />

      <ManageTenantsModal
        showManageTenants={showManageTenants}
        closeMangeTenantsModal={() => setShowManageTenants(false)}
      />
    </Col>
  );
};
