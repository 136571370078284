import Navbar from 'react-bootstrap/esm/Navbar';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import MovingOutAlert from './MovingOutAlert';
import { useActiveDB } from '../../hooks/useActiveDB';

export const NavBarComp = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { databaseName } = useActiveDB();
  const [showDevices, setShowDevices] = useState<boolean>(false);
  const [showMyExpenses, setShowMyExpenses] = useState<boolean>(false);
  useEffect(() => {
    const showDevicesFlag: boolean =
      databaseName === 'allglute_dev-db-DKA2009' ||
      databaseName === 'allglute_db-DKA2009';
    setShowDevices(showDevicesFlag);

    const showMyExpensesFlag: boolean =
      databaseName === 'allglute_dev-db-HOUSE2009' ||
      databaseName === 'allglute_db-HOUSE2009';
    setShowMyExpenses(showMyExpensesFlag);
  }, [databaseName]);
  const history = useHistory();
  const gotoLogout = () => {
    setShowPopup(false);
    history.push('/Logout');
  };
  return (
    <>
      <Navbar
        collapseOnSelect={true}
        expand='lg'
        bg='dark'
        variant='dark'
        className='navbar navbar-expand-md navbar-light bg-light sticky-top'>
        <Navbar.Brand>
          <NavLink to='/RentPayments' className='nav-link'>
            <MovingOutAlert />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse
          className='collapse navbar-collapse'
          id='#navbarResponsive'>
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item'>
              <NavLink to='/RentPayments' className='nav-link'>
                Rent Payment
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/Transactions' className='nav-link'>
                Transactions
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/Escrow' className='nav-link'>
                Escrow
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/Statement' className='nav-link'>
                Statement
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/CreditCard' className='nav-link'>
                Credit Card
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/Vendors' className='nav-link'>
                Vendors
              </NavLink>
            </li>
            {showDevices && (
              <li className='nav-item'>
                <NavLink to='/Devices' className='nav-link'>
                  Devices
                </NavLink>
              </li>
            )}
            {showMyExpenses && (
              <li className='nav-item'>
                <NavLink to='/MyExpenses' className='nav-link'>
                  My Expenses
                </NavLink>
              </li>
            )}
            <li className='nav-item'>
              <div onClick={() => setShowPopup(true)} className='nav-link'>
                Logout
              </div>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
      <Modal
        centered
        show={showPopup}
        onHide={() => setShowPopup(false)}
        animation={true}>
        <Modal.Header>
          <Modal.Title>Logout??</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you want to logout?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowPopup(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={gotoLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
