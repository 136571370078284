import React, { useEffect, useState } from 'react';
import Expenses from './Expenses';
import DepositWithdrawal from './DepositWithDrawal';
import { MortgagePayment } from './Mortgage/MortgagePayment';
import {
  makeDeposit,
  makeWithdrawal,
} from '../../modules/transactions/transactionActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useActiveDB } from '../../hooks/useActiveDB';
import { CURRENT_PAGE } from '../../Utils/Constants';
import { EntryType, IRawBalanceEntry } from '../../modules/balance/types';
import { updateBalanceFigures } from '../../modules/balance/balanceActions';

export interface IDepositWithdrawal {
  Amount?: number;
  Comment?: string;
  CheckNumber?: string;
}
type MyProps = {};
const Transactions: React.FC<MyProps> = () => {
  const [deposit, setDeposit] = useState<IDepositWithdrawal>({});
  const [withdrawal, setWithdrawal] = useState<IDepositWithdrawal>({});
  const { databaseName } = useActiveDB();
  const dispatch = useDispatch();
  const propertyId = useSelector(
    (state: RootState) => state.propertyReducer.selectedProperty,
  );

  useEffect(() => {
    localStorage.setItem(CURRENT_PAGE, 'Transactions');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitDeposit = () => {
    dispatch(makeDeposit(deposit, propertyId, databaseName!));
    setDeposit({ Amount: 0, Comment: '' });
    let depositEntry: IRawBalanceEntry = {
      name: EntryType.Deposit,
      Amount: deposit.Amount!,
      PropertyID: propertyId,
      Address: '',
    };
    dispatch(updateBalanceFigures(depositEntry));
  };

  const submitWithdrawal = () => {
    dispatch(makeWithdrawal(withdrawal, propertyId, databaseName!));
    setWithdrawal({ Amount: 0, Comment: '', CheckNumber: '' });
    let widthdrawalEntry: IRawBalanceEntry = {
      name: EntryType.Withdrawal,
      Amount: withdrawal.Amount!,
      PropertyID: propertyId,
      Address: '',
    };
    dispatch(updateBalanceFigures(widthdrawalEntry));
  };

  const isDisabled = (value: string) => {
    if (value === 'Deposit') {
      return !deposit.Amount || !deposit.Comment;
    }

    return !withdrawal.Amount || !withdrawal.Comment;
  };

  return (
    <div className='flex-grow-1'>
      <Expenses />
      <MortgagePayment />
      <DepositWithdrawal
        IsDisabled={isDisabled('Deposit')}
        Name='Deposit'
        Apply={submitDeposit}
        data={deposit}
        setData={setDeposit}
      />
      <DepositWithdrawal
        IsDisabled={isDisabled('Withdrawal')}
        Name='Withdrawal'
        Apply={submitWithdrawal}
        data={withdrawal}
        setData={setWithdrawal}
        ShowCheckNum={true}
      />
    </div>
  );
};

export default Transactions;
