import React from 'react';
import { ScreenClassProvider } from 'react-grid-system';
import { Route } from 'react-router-dom';
import RentPayments from '../RentPayments/RentPayments';
import Transactions from '../Transactions/Transactions';
import Escrow from '../Escrow/Escrow';
import Statement from '../Statement/Statement';
import Login from '../Login/Login';
import ProtectedRoute from './ProtectedRoute';
import Logout from '../Login/Logout';
import Vendors from '../vendors/Vendors';
import CreditCard from '../CreditCards/CreditCard';
import Devices from '../Devices/Devices';
import MyExpenses from '../MyExpenses/MyExpenses';

const Routes = () => {
  return (
    <ScreenClassProvider>
      <div className='d-flex flex-column'>
        <Route path='/login' component={Login} />
        <ProtectedRoute path='/RentPayments' component={RentPayments} />
        <ProtectedRoute path='/Transactions' component={Transactions} />
        <ProtectedRoute path='/Escrow' component={Escrow} />
        <ProtectedRoute path='/Statement' component={Statement} />
        <ProtectedRoute path='/CreditCard' component={CreditCard} />
        <ProtectedRoute path='/Vendors' component={Vendors} />
        <ProtectedRoute path='/Devices' component={Devices} />     
        <ProtectedRoute path='/Logout' component={Logout} />
        <ProtectedRoute path='/MyExpenses' component={MyExpenses} />
      </div>
    </ScreenClassProvider>
  );
};

export default Routes;
