import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IProperty, IPropertyReduced } from '../../modules/properties/types';
import { getExpenseTypeList } from '../../modules/transactions/transactionActions';
import { IExpenseType } from '../../modules/transactions/types';
import { RootState } from '../../reducers';
import { useForm } from '../../hooks/useForm';
import { useActiveDB } from '../../hooks/useActiveDB';
import { addEscrowEntry } from '../../modules/escrow/escrowActions';

const ControlCentered = styled.div`
  padding-top: 25px;
`;
const DropButtonWrapper = styled.div`
  button {
    width: 150px;
  }
  .dropdown-menu.show {
    max-height: 300px;
    overflow-y: auto;
  }
`;

type MyProps = {};
type FormData = {
  Amount: number;
  Monthly: number;
  IntoEscrow: boolean;
  CreditCard: boolean;
};
const AddEscrowEntry: React.FC<MyProps> = () => {
  const defaultProp: IPropertyReduced = {
    Address: 'Select Property',
    PropertyID: -1,
  };
  const [selectedExpense, setSelectedExpense] = useState<IExpenseType>({
    ExpenseName: 'ExpenseName',
    ExpenseTypeID: -1,
  });
  const [selectedProperty, setSelectedProperty] =
    useState<IPropertyReduced>(defaultProp);
  const [formData, setFormData] = useState<any>({
    Amount: 0,
    Monthly: 0,
    IntoEscrow: false,
    CreditCard: false,
  });
  const [isApplyDisabled, setIsApplyDisabled] = useState<boolean>(false);
  const { databaseName } = useActiveDB();
  const dispatch = useDispatch();
  const expenses: IExpenseType[] = useSelector(
    (state: RootState) => state.transactionReducer.expenseTypeList,
  );
  const properties: IProperty[] = useSelector(
    (state: RootState) => state.propertyReducer.propertyList,
  );

  useEffect(() => {
    if (expenses.length === 0 && databaseName) {
      dispatch(getExpenseTypeList(databaseName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseName]);
  useEffect(() => {
    //in case of a single property, make it selected
    if (properties.length === 1) {
      setSelectedProperty(properties[0]);
    } else {
      setSelectedProperty(defaultProp);
    }
    // eslint-disable-next-line
  }, [properties]);

  useEffect(() => {
    if (
      selectedExpense.ExpenseTypeID !== -1 &&
      selectedProperty.PropertyID !== -1 &&
      formData.Amount !== 0 &&
      formData.Amount !== '' &&
      formData.Amount !== '.'
    ) {
      setIsApplyDisabled(false);
    } else {
      setIsApplyDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, selectedProperty, selectedExpense]);
  const { onChange } = useForm(formData, setFormData);
  const handleFocus = (e) => {
    e.target.select();
  };
  const handleApply = () => {
    dispatch(
      addEscrowEntry(
        databaseName!,
        selectedProperty.PropertyID,
        selectedExpense.ExpenseTypeID,
        formData.Amount,
        formData.Monthly,
        formData.IntoEscrow,
        formData.CreditCard,
      ),
    );
  };
  return (
    <Form className='d-none d-sm-block'>
      <Form.Row>
        <Form.Group className='p-2'>
          <Form.Label>Expense Name</Form.Label>
          <DropButtonWrapper>
            <DropdownButton
              id='dropdown-basic-button'
              title={selectedExpense.ExpenseName}>
              {expenses.map((expenseType: IExpenseType, index: number) => {
                return (
                  <Dropdown.Item
                    key={`escrow-et${index}`}
                    onClick={() => setSelectedExpense(expenseType)}
                    href='#/action-1'>
                    {expenseType.ExpenseName}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </DropButtonWrapper>
        </Form.Group>
        <Form.Group className='p-2'>
          <Form.Label>Property</Form.Label>
          <DropButtonWrapper>
            <DropdownButton
              id='dropdown-basic-button'
              title={selectedProperty.Address}>
              {properties.map((prop: IProperty, index: number) => {
                return (
                  <Dropdown.Item
                    key={`esc-prop-${index}`}
                    href='#/action-1'
                    onClick={() => setSelectedProperty(prop)}>
                    {prop.Address}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </DropButtonWrapper>
        </Form.Group>
        <Form.Group className='p-2' as={Col} controlId='formGridCity'>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            data-numeric={true}
            value={formData.Amount}
            name='Amount'
            onChange={onChange}
            onFocus={handleFocus}
          />
        </Form.Group>

        <Form.Group className='p-2' as={Col} controlId='formGridState'>
          <Form.Label>Monthly</Form.Label>
          <Form.Control
            data-numeric={true}
            value={formData.Monthly}
            name='Monthly'
            onChange={onChange}
            onFocus={handleFocus}
          />
        </Form.Group>

        <Form.Group className='p-2' controlId='formGridZip'>
          <ControlCentered>
            <Form.Check
              id='IntoEscrow'
              className='ml-3'
              type='switch'
              label='Into Escrow?'
              checked={formData.IntoEscrow || false}
              name='IntoEscrow'
              onChange={onChange}
            />
            <Form.Check
              id='CreditCard'
              className='ml-3'
              type='switch'
              label='Credit?'
              checked={formData.CreditCard || false}
              name='CreditCard'
              onChange={onChange}
            />
          </ControlCentered>
        </Form.Group>

        <Form.Group className='p-2' as={Col}>
          <ControlCentered>
            <Button
              onClick={handleApply}
              disabled={isApplyDisabled}
              variant='primary'
              size='lg'
              block
              active>
              <div>Apply</div>
            </Button>
          </ControlCentered>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default AddEscrowEntry;
