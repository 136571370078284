import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { formatter } from '../../Utils/formatters';
import styled from 'styled-components';
import { ITenant } from '../../modules/tenants/types';
import { ListView } from './RentalPaymentControls';
import { TableCol2 } from './Styles';

type MyProps = {
  whoDidntPayList: ITenant[];
  showWhoDidntPay: boolean;
  closeModal: () => void;
};

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const WhoDidntPayModal = (props: MyProps) => {
  const [whoDidntPayList, setWhoDidntPayList] = useState<ITenant[]>([]);
  let totalUnpaid: number = 0;

  useEffect(() => {
    setWhoDidntPayList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const theList = props.whoDidntPayList.reduce(
      (lst: ITenant[], item: ITenant) => {
        let balanceToPay = (item.RentAmount || 0) - (item.PaidAmount || 0);
        // eslint-disable-next-line
        if (balanceToPay > 0 && item.IsReal == 1) {
          lst.push({ ...item, RentAmount: balanceToPay });
        }
        return lst;
      },
      [],
    );
    setWhoDidntPayList(theList);
  }, [props.whoDidntPayList]);

  return (
    <Modal show={props.showWhoDidntPay} onHide={props.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Who didn't pay</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListView>
          {whoDidntPayList &&
            whoDidntPayList.length > 0 &&
            whoDidntPayList.map((t, index) => {
              totalUnpaid = t.RentAmount
                ? totalUnpaid + +t.RentAmount
                : totalUnpaid;
              return (
                <TableRow key={`wdp-${index}`}>
                  <TableCol2 mobileWidth={3} width={2}>
                    {t.TenantName}
                  </TableCol2>
                  <TableCol2 hideMobile={true} width={2}>
                    {t.Address}
                  </TableCol2>
                  <TableCol2 mobileWidth={2} width={1}>
                    {formatter.format(t.RentAmount || 0)}
                  </TableCol2>
                </TableRow>
              );
            })}
        </ListView>
        <Row>
          <Col></Col>
          <Col className='text-right'>Total:</Col>
          <Col>{formatter.format(totalUnpaid)}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={props.closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
