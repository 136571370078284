import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormControl, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { ISecurityDeposit } from '../../modules/tenants/types';
import { useForm } from '../../hooks/useForm';
import { updateSecurityDeposit } from '../../modules/properties/propertyActions';
import { useActiveDB } from '../../hooks/useActiveDB';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../Utils/datesUtil';
import { RowGrid } from './Styles';
import { IProperty } from '../../modules/properties/types';
import { RootState } from '../../reducers';

type MyProps = {
  mdSize: number;
  smSize: number;
  securityDeposits: ISecurityDeposit[];
  tenantID: number;
  selectedProperty: IProperty;
};
export const SecurityDeposits: React.FC<MyProps> = (props: MyProps) => {
  const { mdSize, smSize, tenantID } = props;
  const [data, setData] = useState<any>({});
  const [securityDeposits, setSecurityDeposits] = useState<ISecurityDeposit[]>(
    props.securityDeposits,
  );
  const { onChange } = useForm(data, setData);
  const { databaseName } = useActiveDB();
  const dispatch = useDispatch();
  const selectedPropertyID: number = useSelector(
    (state: RootState) => state.propertyReducer.selectedProperty,
  );
  const properties: IProperty[] = useSelector(
    (state: RootState) => state.propertyReducer.propertyList,
  );

  useEffect(() => {
    if (
      props.securityDeposits.length > 0 &&
      props.securityDeposits[0].Amount != null &&
      tenantID === props.securityDeposits[0].TenantID
    ) {
      setSecurityDeposits(props.securityDeposits);
    } else {
      setSecurityDeposits([]);
    }
  }, [props.securityDeposits, properties, tenantID]);

  useEffect(() => {
    setSecurityDeposits([]);
  }, [databaseName, selectedPropertyID]);

  const submitSecurityDesosit = () => {
    data.TenantID = tenantID;
    dispatch(updateSecurityDeposit(databaseName!, data));
    setData({
      TenantID: tenantID,
      CheckNumber: '',
      Comment: '',
      Apartment: '',
      Amount: 0,
    });
  };

  const validateSubmit = () => {
    return !data.Apartment || !data.Amount || !tenantID;
  };
  return (
    <Col
      className='rounded-sm  d-none d-sm-block border bg-secondary'
      lg={mdSize}
      sm={smSize}>
      <Row>
        <Col>
          <h3>Security Deposits</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <label htmlFor='basic-url'>Apt</label>
          <InputGroup className='mb-3'>
            <FormControl
              onChange={onChange}
              name='Apartment'
              value={data.Apartment}
              id='apt'
              aria-describedby='basic-addon3'
            />
          </InputGroup>
        </Col>
        <Col lg={2}>
          <label htmlFor='basic-url'>Amount</label>
          <InputGroup className='mb-3'>
            <FormControl
              onChange={onChange}
              name='Amount'
              value={data.Amount}
              data-numeric={true}
              id='sd-amount'
              aria-describedby='basic-addon3'
            />
          </InputGroup>
        </Col>
        <Col lg={2}>
          <label htmlFor='basic-url'>Check #</label>
          <InputGroup className='mb-3'>
            <FormControl
              onChange={onChange}
              name='CheckNumber'
              value={data.CheckNumber}
              id='sd-checknum'
              aria-describedby='basic-addon3'
            />
          </InputGroup>
        </Col>
        <Col lg={4}>
          <label htmlFor='basic-url'>Comment</label>
          <InputGroup className='mb-3'>
            <FormControl
              onChange={onChange}
              name='Comment'
              value={data.Comment}
              id='sd-comment'
              aria-describedby='basic-addon3'
            />
          </InputGroup>
        </Col>
        <Col lg={2}>
          <Button onClick={submitSecurityDesosit} disabled={validateSubmit()}>
            Submit
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <RowGrid Check='' Record={false}>
            <div style={{ minWidth: '100px' }}>Date</div>
            <div>Apartment</div>
            <div>Amount</div>
            <div>Check #</div>
            <div>Comment</div>
          </RowGrid>
          {securityDeposits.map((sd, index) => {
            const formatted_date = formatDate(sd.Date);
            return (
              <RowGrid Check={sd.CheckNumber} Record={true} key={`sd-${index}`}>
                <div style={{ minWidth: '100px' }}>{formatted_date}</div>
                <div>{sd.Apartment}</div>
                <div>{sd.Amount}</div>
                <div>{sd.CheckNumber}</div>
                <div>{sd.Comment}</div>
              </RowGrid>
            );
          })}
        </Col>
      </Row>
    </Col>
  );
};
