import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortAlphaDown, faSortAlphaUp} from "@fortawesome/free-solid-svg-icons";
import styled from 'styled-components';
import {headerKeys} from "./StatementEntries";

const SingleHeader = styled.span`
 cursor: pointer;
 &:hover{
   background-color: #6c757d;
 }
`;

type MyProps = {
    toggleValue: number,
    myKey: headerKeys,
    selectedKey: headerKeys,
    onHeaderClicked: (header:headerKeys) => void
}


let SortableHeader = (props: MyProps) => {
    const showHeader: boolean = props.myKey === props.selectedKey;
    // @ts-ignore
    return (
        <>
            <SingleHeader onClick={() => props.onHeaderClicked(props.myKey)}>
                {props.myKey.toString()}&nbsp;
                {showHeader && props.toggleValue % 3 === 1 && <FontAwesomeIcon icon={faSortAlphaDown}/>}
                {showHeader && props.toggleValue % 3 === 2 && <FontAwesomeIcon icon={faSortAlphaUp}/>}
            </SingleHeader>
        </>
    );
}

export {SortableHeader};