import { getMortgageComment } from "../../Utils/mortgageUtil";
import { GET_MORTGAGE_LIST, IMortgageActions, IMortgageEntry, POST_MORTGAGE_PAYMENTS } from "./types";

interface IMortgageReducer{
    mortgages: IMortgageEntry[]
}
const INIT_STATE:IMortgageReducer = {
    mortgages :[]
}

export const mortgageReducer = (state:IMortgageReducer=INIT_STATE, action:IMortgageActions ) => {
  switch(action.type){
      case GET_MORTGAGE_LIST:
          const mortgages = action.payload.map((m:IMortgageEntry)=>{       
           m.IsSelected = true;      
           m.DefaultComment = getMortgageComment();
           return m;
          });
           
          return {...state, mortgages }
      
      case POST_MORTGAGE_PAYMENTS:          
          const setOffMortgages = state.mortgages.reduce((mArr:IMortgageEntry[],m:IMortgageEntry)=>{
            m.IsSelected = false;
            mArr.push(m);
            return mArr;
          },[])
          return { mortgages: [...setOffMortgages]}
      default:
          return state;
  }
}