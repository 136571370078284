import { IStatementLine } from "../statement/types";

export const GET_CREDITCARD_LINES = 'GET_CREDITCARD_LINES';
export const RESET_CREDITCARD_LINES = 'RESET_CREDITCARD_LINES';

export interface IGetCreditcardLines{
    type: typeof GET_CREDITCARD_LINES
    payload: IStatementLine[]
}

export interface IResetCreditcardLines{
    type: typeof RESET_CREDITCARD_LINES
    payload: IStatementLine[]
}

export type CreditcardActionsType = IGetCreditcardLines | IResetCreditcardLines;