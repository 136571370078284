import { Dispatch } from 'react';
import { RootState } from '../../reducers';
import { ROOT_URL } from '../../Utils/Constants';
import { http } from '../../Utils/http';
import {
  BalanceActionType,
  EntryType,
  GET_BALANCE_DATA,
  IPropertyBalance,
  IRawBalanceEntry,
  ITableEntry,
  UPDATE_BALANCE_FIGURES,
} from './types';

export const getBalanceData =
  (db: string) =>
  async (dispatch: Dispatch<BalanceActionType>, state: RootState) => {
    let url: string = `${ROOT_URL}/property/balance.php?db_name=${db}`;
    const result: IRawBalanceEntry[] = await http(url, dispatch);
    const propertyBalances: IPropertyBalance[] = result.reduce(
      (arr: IPropertyBalance[], item: IRawBalanceEntry) => {
        let propertyEntry: IPropertyBalance | undefined = arr.find(
          (p) => p.PropertyID === item.PropertyID,
        );
        if (!propertyEntry) {
          propertyEntry = {
            PropertyID: item.PropertyID,
            Address: item.Address,
            Table: [],
          };
          arr.push(propertyEntry);
        }
        const tableEntry: ITableEntry = {
          Name: getEntryType(item.name),
          Amount: item.Amount,
        };
        propertyEntry.Table.push(tableEntry);
        return arr;
      },
      [],
    );
    addEscrowsAndMissingData(propertyBalances, state);
    dispatch({
      type: GET_BALANCE_DATA,
      payload: propertyBalances,
    });
  };

export const calculateTotals = (value: IPropertyBalance): number => {
  return value.Table.reduce((total: number, item: ITableEntry) => {
    //Calculating the total First 2 (Income,Deposit) are positive, the other are negative
    if (item.Name === EntryType.Income || item.Name === EntryType.Deposit) {
      total += Number(item.Amount);
    } else {
      total -= Number(item.Amount);
    }
    return total;
  }, 0);
};

export const calculateBalance = (property: IPropertyBalance): number => {
  return property.Table.reduce((tots: number, value: ITableEntry) => {
    switch (value.Name) {
      case EntryType.Expense:
      case EntryType.Mortgage:
      case EntryType.Withdrawal:
        tots = tots - Number(value.Amount);
        break;
      case EntryType.Deposit:
      case EntryType.Income:
        tots = tots + Number(value.Amount);
    }
    return tots;
  }, 0);
};

const addEscrowsAndMissingData = (
  propertyBalances: IPropertyBalance[],
  getState: any,
): IPropertyBalance[] => {
  const state = getState();
  const escrowTree = state.escrowReducer.escrowTree.Children;
  propertyBalances.map((value: IPropertyBalance, ndx: number) => {
    if (value.Table.length < 5) {
      value.Table = amendEntryTable(value.Table);
    }
    const propertyEscrowTotal = escrowTree?.find(
      (e) => e.Label === value.Address,
    );
    let escrowAmount = 0;
    if (propertyEscrowTotal) {
      escrowAmount = propertyEscrowTotal.Amount;
    }
    value.Table.push({ Name: EntryType.Escrow, Amount: escrowAmount });
    const tots = calculateTotals(value);
    value.Table.push({ Name: EntryType.Total, Amount: tots });
    return null;
  });
  return propertyBalances;
};

const amendEntryTable = (table: ITableEntry[]): ITableEntry[] => {
  const result: ITableEntry[] = [
    {
      Name: EntryType.Income,
      Amount: 0,
    },
    {
      Name: EntryType.Deposit,
      Amount: 0,
    },
    {
      Name: EntryType.Withdrawal,
      Amount: 0,
    },
    {
      Name: EntryType.Expense,
      Amount: 0,
    },
    {
      Name: EntryType.Mortgage,
      Amount: 0,
    },
  ];
  for (let i = 0; i < table.length; i++) {
    let item = result.find((e) => e.Name === table[i].Name);
    if (item) {
      item.Amount = table[i].Amount;
    }
  }
  return result;
};

const getEntryType = (input: string): EntryType => {
  switch (input) {
    case 'deposit':
      return EntryType.Deposit;
    case 'expense':
      return EntryType.Expense;
    case 'withdrawal':
      return EntryType.Withdrawal;
    case 'income':
      return EntryType.Income;
    case 'mortgage':
      return EntryType.Mortgage;
    default:
      return EntryType.None;
  }
};

export const updateBalanceFigures = (balanceEntry: IRawBalanceEntry) => {
  return {
    type: UPDATE_BALANCE_FIGURES,
    payload: balanceEntry,
  };
};
