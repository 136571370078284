export const GET_EXPENSE_TYPE_LIST = 'GET_EXPENSE_TYPE_LIST';
export const ADD_JUST_PAID_ENTRY = 'ADD_JUST_PAID_ENTRY';

export interface IExpenseType {
  ExpenseName: string;
  ExpenseTypeID: number;
}

export interface IJustPaidExpense {
  ExpenseName?: string;
  Amount?: number;
}

export interface IGetExpenseTypeList {
  type: typeof GET_EXPENSE_TYPE_LIST;
  payload: IExpenseType[];
}

export interface IAddJustPaidEntry {
  type: typeof ADD_JUST_PAID_ENTRY;
  payload: IJustPaidExpense;
}

export interface IPayment {
  Amount?: number;
  Available?: number;
  Comments?: string;
  Notes?: string;
  CheckNum?: string;
  IsCreditCard?: boolean;
  StopWarning?: boolean;
  ExpenseId?: number;
}

export interface IStopWarningData {
  AllowOverride?: boolean;
  ShowModal?: boolean;
}

export type ITransactionActions = IGetExpenseTypeList | IAddJustPaidEntry;
