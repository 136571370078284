import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';
import './MyExpenses.css';
import { ROOT_URL } from '../../../Utils/Constants';
import { post } from '../../../Utils/http';
import { useDispatch } from 'react-redux';
import { setLoaderFlag } from '../../../modules/properties/propertyActions';

type MyProps = {
  setShowPopup: any;
  activeJson: any;
  setShowData: Dispatch<SetStateAction<boolean>>;
};
const SavePopup: FC<MyProps> = ({ setShowPopup, activeJson, setShowData }) => {
  const [sheetLabel, setSheetLabel] = useState<string>('');
  const dispatch = useDispatch();
  const saveSheet = async () => {
    if (!sheetLabel) {
      return;
    }

    const payload = {
      sheetLabel,
      activeJson: JSON.stringify(activeJson),
    };
    try {
      let url = `${ROOT_URL}/myexpenses/upsertExpenses.php?db_name=allglute_db-HOUSE2009`;
      await post(url, payload, dispatch);
      setShowPopup(false);
      setShowData(false);
    } catch (e) {
      dispatch(setLoaderFlag(false));
    }
  };
  return (
    <div className='savePopup'>
      <div>Please label your sheet</div>
      <div>
        <input
          value={sheetLabel}
          onChange={(e) => setSheetLabel(e.currentTarget.value)}
        />
        <div className='SavePopupButtonsWrapper'>
          <div onClick={saveSheet} className='ExpenseBannerButton'>
            <div>
              <CloudUploadIcon />
            </div>
            <div>Save</div>
          </div>
          <div
            onClick={() => {
              setShowPopup(false);
            }}
            className='ExpenseBannerButton'
            style={{ width: '114px' }}>
            <div>
              <CancelIcon />
            </div>
            <div>Cancel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavePopup;
