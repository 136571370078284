import {
  ILoginCredentials,
  ILoginResults,
  LoginActionsType,
  SEND_LOGIN_ACTION,
  SEND_LOGOUT_ACTION,
  TOGGLE_LOGIN_ERROR,
} from './types';
import { Dispatch } from 'react';
import { ADMINDB, ROOT_URL } from '../../Utils/Constants';
import { http } from '../../Utils/http';

export const sendLoginAction = (credentials: ILoginCredentials) => async (
  dispatch: Dispatch<LoginActionsType>,
  getState: any,
) => {
  const { userName, password } = credentials;
  let url: string = `${ROOT_URL}/login/login.php?db_name=${ADMINDB}`;
  url += `&user=${userName}&password=${password}`;
  const result: ILoginResults = await http(url, dispatch);
  dispatch({
    type: SEND_LOGIN_ACTION,
    payload: result,
  });
};

export const sendLogoutAction = (userName: string) => async (
  dispatch: Dispatch<LoginActionsType>,
) => {
  let url: string = `${ROOT_URL}/login/logout.php?db_name=${ADMINDB}`;
  url += `&user=${userName}`;
  await http(url, dispatch);
  dispatch({
    type: SEND_LOGOUT_ACTION,
    payload: {},
  });
};

export const toggleLoginError = (isShowing: boolean) => (
  dispatch: Dispatch<LoginActionsType>,
) => {
  dispatch({
    type: TOGGLE_LOGIN_ERROR,
    payload: isShowing,
  });
};

export const checkStatus = (username: string, token: string) => async (
  dispatch: Dispatch<LoginActionsType>,
) => {
  let url: string = `${ROOT_URL}/login/status.php?db_name=${ADMINDB}`;
  url += `&user=${username}&token=${token}`;
  const result: ILoginResults = await http(url, dispatch);
  if (result.status === 200) {
    dispatch({
      type: SEND_LOGIN_ACTION,
      payload: result,
    });
  } else {
    dispatch({
      type: SEND_LOGOUT_ACTION,
      payload: {},
    });
  }
};
