import { Button, TextField } from '@material-ui/core';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCreditcardList,
  payCreditCard,
} from '../../modules/creditCards/creditCardActions';
import { IGetCreditcardLines } from '../../modules/creditCards/types';
import { IStatementLine } from '../../modules/statement/types';
import { RootState } from '../../reducers';
import { useActiveDB } from '../../hooks/useActiveDB';
import { uuidv4 } from '../../Utils/utils';
import {
  Controls,
  CreditCardsContainer,
  TableBody,
  TableWrapper,
  VControls,
} from './Styles';
import { TableHeader } from './TableHeader';
import TableRecords from './TableRecords';
import { CURRENT_PAGE } from '../../Utils/Constants';
import Automatch from './Automatch/Automatch';

const CreditCard: React.FC = () => {
  const dispatch = useDispatch<Dispatch<IGetCreditcardLines>>();
  const { databaseName } = useActiveDB();
  const [total, setTotal] = useState<number>(0);
  const [checkNumber, setCheckNumber] = useState<string>('');
  const [showAutomatch, setShowAutomatch] = useState<boolean>(false);
  const creditcardLines = useSelector(
    (state: RootState) => state.creditCardReducer.creditcardList,
  );
  useEffect(() => {
    dispatch(getCreditcardList(databaseName!));
    localStorage.setItem(CURRENT_PAGE, 'CreditCard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseName]);

  useEffect(() => {
    let tots = creditcardLines.reduce((t: number, line: IStatementLine) => {
      t = parseFloat(t.toString()) + parseFloat(line.eAmount!.toString());
      return t;
    }, 0);
    setTotal(tots);
  }, [creditcardLines]);

  const setRandomChecknumber = () => {
    const rand = uuidv4().substr(0, 8);
    setCheckNumber(rand);
  };
  const handlePayCreditCard = () => {
    dispatch(payCreditCard(databaseName!, total, checkNumber));
  };
  //SELECT * FROM `creditcard_t` where paid=1 and DateCreated between '2021-01-01' and '2021-12-31' order by CreditCardID desc

  const openAutomatch = () => {
    setShowAutomatch(true);
  };

  return (
    <CreditCardsContainer>
      <Automatch
        showAutomatch={showAutomatch}
        setShowAutomatch={setShowAutomatch}
      />
      <Controls>
        <div>
          <div>Total:</div>
          <div>{`$${total.toFixed(2)}`}</div>
        </div>
        <div>
          <TextField
            id='filled-basic'
            label='Check#'
            value={checkNumber}
            onChange={(e) => setCheckNumber(e.target.value)}
          />
          <Button variant='contained' onClick={setRandomChecknumber}>
            ...
          </Button>
        </div>
        <Button
          style={{ width: '176px' }}
          variant='contained'
          color='primary'
          disabled={checkNumber === ''}
          onClick={handlePayCreditCard}>
          {' '}
          Pay{' '}
        </Button>
      </Controls>
      <TableWrapper>
        <VControls>
          <Button variant='contained' color='primary'>
            Get Lines
          </Button>
          <Button variant='contained' color='primary' onClick={openAutomatch}>
            Auto Match
          </Button>
        </VControls>
        <div>
          <TableHeader />
          <TableBody>
            {creditcardLines.map((line: IStatementLine, ndx: number) => {
              return <TableRecords key={ndx} line={line} />;
            })}
          </TableBody>
        </div>
      </TableWrapper>
    </CreditCardsContainer>
  );
};

export default CreditCard;
