import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import StatementLine from './StatementLine';
import {
  checkStatementLineAction,
  clearStatementLines,
} from '../../modules/statement/statementActions';
import { SortableHeader } from './SortableHeader';
import { SortStatementLines } from '../../Utils/statementsUtil';
import { useActiveDB } from '../../hooks/useActiveDB';

type MyProps = {};

const StatementPane = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: repeat(48, 1fr);
  grid-template-rows: 1fr;
  text-align: left;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Col1 = styled.div`
  grid-column: 1/2;
  @media (max-width: 600px) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
`;
export const Col2 = styled.div`
  grid-column: 3/8;
  @media (max-width: 600px) {
    grid-column: 1/2;
  }
`;
export const Col3 = styled.div`
  grid-column: 9/16;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const Col4 = styled.div`
  grid-column: 16/23;
  @media (max-width: 600px) {
    grid-column: 2/3;
  }
`;
export const Col5 = styled.div`
  grid-column: 23/30;
  @media (max-width: 600px) {
    grid-column: 2/3;
    text-align: center;
    background-color: #adadad;
  }
`;
export const Col6 = styled.div`
  grid-column: 30/37;
  @media (max-width: 600px) {
    grid-column: 1/2;
  }
`;
export const Col7 = styled.div`
  grid-column: 37/43;
  @media (max-width: 600px) {
    grid-column: 2/3;
  }
`;
export const Col8 = styled.div`
  grid-column: 43/49;
  position: relative;
  @media (max-width: 600px) {
    grid-column: none;
    display: none;
  }
`;

export enum headerKeys {
  date = 'Date',
  message = 'Message',
  deposit = 'Deposit',
  widthdrawal = 'Widthdrawal',
  checkNum = 'Check #',
}

const StatementEntries: React.FC<MyProps> = () => {
  const [toggleValue, setToggleValue] = useState<number>(0);
  const { databaseName } = useActiveDB();
  const [selectedHeaderKey, setSelectedHeaderKey] = useState<headerKeys>(
    headerKeys.date,
  );
  const dispatch = useDispatch();
  const statementLines = useSelector(
    (state: RootState) => state.statementReducer.statementLines,
  );

  const sortLines = () => {
    return SortStatementLines(
      [...statementLines],
      toggleValue,
      selectedHeaderKey,
    );
  };

  useEffect(() => {
    dispatch(clearStatementLines());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseName]);

  const onHeaderClicked = (header: headerKeys) => {
    let currentKey = selectedHeaderKey;
    let localToggleValue = toggleValue + 1;

    if (header !== currentKey) {
      //this is where a user click on a different header than what we had selected before
      localToggleValue = 1;
    }
    setToggleValue(localToggleValue);
    setSelectedHeaderKey(header);
  };

  const handleCheckStatementLineAction = (
    cronid: number,
    isChecked: boolean,
  ) => {
    dispatch(checkStatementLineAction(cronid, isChecked));
  };
  return (
    <div className='flex-grow-1 '>
      <Container className='border bg-dark text-light' fluid>
        <HeaderRow>
          <Col1></Col1>
          <Col2>
            <SortableHeader
              onHeaderClicked={onHeaderClicked}
              selectedKey={selectedHeaderKey}
              myKey={headerKeys.date}
              toggleValue={toggleValue}
            />
          </Col2>
          <Col3>Property</Col3>
          <Col4>Type</Col4>
          <Col5>
            <SortableHeader
              onHeaderClicked={onHeaderClicked}
              selectedKey={selectedHeaderKey}
              myKey={headerKeys.message}
              toggleValue={toggleValue}
            />
          </Col5>
          <Col6>
            <SortableHeader
              onHeaderClicked={onHeaderClicked}
              selectedKey={selectedHeaderKey}
              myKey={headerKeys.deposit}
              toggleValue={toggleValue}
            />
          </Col6>
          <Col7>
            <SortableHeader
              onHeaderClicked={onHeaderClicked}
              selectedKey={selectedHeaderKey}
              myKey={headerKeys.widthdrawal}
              toggleValue={toggleValue}
            />
          </Col7>
          <Col8>
            <SortableHeader
              onHeaderClicked={onHeaderClicked}
              selectedKey={selectedHeaderKey}
              myKey={headerKeys.checkNum}
              toggleValue={toggleValue}
            />
          </Col8>
        </HeaderRow>
        <StatementPane>
          {sortLines().map((singleLine, index) => {
            return (
              <StatementLine
                minWidth={'170px'}
                key={`statement-${index}`}
                StatementLine={singleLine}
                CheckStatementLine={handleCheckStatementLineAction}
              />
            );
          })}
        </StatementPane>
      </Container>
    </div>
  );
};

export default StatementEntries;
