import React, {
  createRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useActiveDB } from '../../../hooks/useActiveDB';
import {
  checkStatementLineAction,
  getStatementLinesAction,
} from '../../../modules/statement/statementActions';
import { RootState } from '../../../reducers';
import MatchStatementLine from './StatementLines/MatchStatementLine';
import './Match.css';
import { formatter } from '../../../Utils/formatters';
import { Button } from 'react-bootstrap';
import { getBankEntries, postMatch } from '../../../modules/match/matchActions';
import BankStatementLine from './StatementLines/BankStatementLine';
import {
  BankRefObj,
  BankStatementEntry,
  PostMatchSelection,
} from '../../../modules/match/types';
import { IStatementLine } from '../../../modules/statement/types';

const ManualMatch = () => {
  const { databaseName } = useActiveDB();
  const dispatch = useDispatch();
  const [valid, setValid] = useState<boolean>(false);
  const scrollRefs = useRef<BankRefObj[]>([]);
  const statementLines = useSelector(
    (state: RootState) => state.statementReducer.statementLines,
  );
  const totalAmount = useSelector(
    (s: RootState) => s.statementReducer.totalAmount,
  );

  const bankLines = useSelector(
    (state: RootState) => state.matchReducer.bankStatementLines,
  );
  const validate = () => {
    const _line = bankLines.find((bl) => bl.selected); // ;
    setValid(
      _line !== undefined &&
        parseFloat(_line.amount) === parseFloat(totalAmount.toFixed(2)),
    );
  };
  useEffect(() => {
    dispatch(getStatementLinesAction(false, databaseName));
    const getAll = "no";
    dispatch(getBankEntries(getAll,databaseName!));
  }, []);

  useEffect(() => {
    if (bankLines.length > 0) {
      scrollRefs.current = bankLines.map(
        (line: BankStatementEntry, i) =>
          scrollRefs.current[i] ?? {
            ref: createRef(),
            amount: line.amount,
            matchId: line.matchId,
          },
      );
      validate();
    }
  }, [bankLines]);
  useEffect(validate, [totalAmount]);

  useEffect(() => {
    const bankEntry = scrollRefs.current.find((x) => {
      const f = totalAmount.toFixed(2);
      return x.amount === f;
    });
    //@ts-ignore
    if (bankEntry && bankEntry.ref.current) {
      //@ts-ignore
      bankEntry.ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [totalAmount]);
  const handleCheckStatementLineAction = (
    cronid: number,
    isChecked: boolean,
  ) => {
    validate();
    dispatch(checkStatementLineAction(cronid, isChecked));
  };

  const getRef = (matchId: number): RefObject<HTMLDivElement> => {
    if (scrollRefs.current.length === 0) {
      return createRef();
    }
    const refObj: BankRefObj | undefined = scrollRefs.current.find(
      (b: BankRefObj) => b.matchId === matchId,
    );
    if (refObj) {
      refObj.ref = createRef();
      return refObj.ref;
    }
    return createRef();
  };

  const performMatch = () => {
    const _line = bankLines.find((bl) => bl.selected);
    const statementIds = statementLines.reduce(
      (idArr: number[], line: IStatementLine) => {
        if (line.reviewed === 1) {
          idArr.push(line.cronid);
        }
        return idArr;
      },
      [],
    );
    const payload: PostMatchSelection = {
      matchId: _line?.matchId || -1,
      statementIds,
    };
    dispatch(postMatch(payload, databaseName));
  };
  return (
    <div>
      <div className='statementBlockHeader'>
        <div>Database Entries</div>
        <Button
          disabled={!valid}
          className='matchButton'
          onClick={performMatch}
          block>
          Match
        </Button>
        <div className='totalAmount'>
          Total: {formatter.format(totalAmount)}
        </div>
      </div>
      <div className='statementLines'>
        {statementLines.map((line: any, ndx: number) => {
          return (
            <MatchStatementLine
              minWidth={'170px'}
              key={`statement-${ndx}`}
              StatementLine={line}
              CheckStatementLine={handleCheckStatementLineAction}
            />
          );
        })}
      </div>
      <div className='bankLines'>
        <div>Bank Statement</div>
        <div className='statementLines'>
          {bankLines.map((line: BankStatementEntry, ndx: number) => {
            return (
              <BankStatementLine
                elemRef={getRef(line.matchId!)}
                key={`statement-${ndx}`}
                BankLine={line}
                Validate={validate}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ManualMatch;
