import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MoveOutAlertDiv } from './styles';
import { useSelector } from 'react-redux';
import { ITenantMovingOut } from '../../modules/tenants/types';
import { RootState } from '../../reducers';
import { WhoIsMovingOut } from '../RentPayments/MovingOut/utils';
import { useActiveDB } from '../../hooks/useActiveDB';

const Brand = styled.span`
  color: white;
`;

const MovingOutAlert = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const propertyList = useSelector(
    (s: RootState) => s.propertyReducer.propertyList,
  );
  const { displayName } = useActiveDB();
  useEffect(() => {
    const mo: ITenantMovingOut[] = WhoIsMovingOut(propertyList);
    setVisible(mo && mo.length > 0);
  }, [propertyList]);

  const handleClick = () => {
    setVisible(false);
  };
  return (
    <div>
      <Brand>{displayName}</Brand>
      {'  '}
      <MoveOutAlertDiv>
        {visible && <div onClick={handleClick}>Check Moving out</div>}
      </MoveOutAlertDiv>
    </div>
  );
};

export default MovingOutAlert;
