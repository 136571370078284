import {
  BankStatementEntry,
  GET_BANKMATCH_LINES,
  MatchActionsType,
  POST_MATCH_SETTELE,
  SELECT_BANKMATCH_LINE,
  UN_MATCH,
} from './types';

export interface IMatchReducer {
  bankStatementLines: BankStatementEntry[];
}

const INIT_STATE: IMatchReducer = {
  bankStatementLines: [],
};

export const matchReducer = (
  state: IMatchReducer = INIT_STATE,
  action: MatchActionsType,
) => {
  switch (action.type) {
    case GET_BANKMATCH_LINES:
      return { ...state, bankStatementLines: action.payload };
    case SELECT_BANKMATCH_LINE:
      const _lines = state.bankStatementLines.map((item, i) => {
        if (item.matchId === action.payload.matchId) {
          const _item = {
            ...item,
            selected: !item.selected,
          };
          return _item;
        }
        return {
          ...item,
          selected: false,
        };
      });
      return {
        ...state,
        bankStatementLines: [..._lines],
      };
    case POST_MATCH_SETTELE:
      const bankStatementLines = state.bankStatementLines.reduce(
        (arr: BankStatementEntry[], item: BankStatementEntry) => {
          if (item.matchId !== action.payload.matchId) {
            arr.push(item);
          }
          return arr;
        },
        [],
      );
      return { ...state, bankStatementLines };
    case UN_MATCH:
      const _bankStatementLines = state.bankStatementLines.reduce(
        (arr: BankStatementEntry[], item: BankStatementEntry) => {
          if (item.matchId !== action.payload) {
            arr.push(item);
          }
          return arr;
        },
        [],
      );
      return { ...state, bankStatementLines: _bankStatementLines };
    default:
      return state;
  }
};
