import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { FormControl, InputGroup, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ExpenseTypeList from './ExpenseTypeList';
import {
  IExpenseType,
  IJustPaidExpense,
  IPayment,
  IStopWarningData,
} from '../../modules/transactions/types';
import { StopWarningModal } from './StopWarningModal';
import { RootState } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  addJustPaidEntry,
  MakeExpensePayment,
} from '../../modules/transactions/transactionActions';
import SuggestBox from './SuggestBox/SuggestBox';
import { useForm } from '../../hooks/useForm';
import { IProperty } from '../../modules/properties/types';
import { useActiveDB } from '../../hooks/useActiveDB';
import {
  CheckboxGroup,
  JustPaidExpenses,
  JustPaidRow,
  SplitControlsScreen,
} from './styles';
import { EntryType, IRawBalanceEntry } from '../../modules/balance/types';
import { updateBalanceFigures } from '../../modules/balance/balanceActions';

const NO_ESCROW = 'No Escrow';

const Expenses = () => {
  const [data, setData] = useState<IPayment>({});
  const dispatch = useDispatch();
  const [selectedExpenseType, setSelectedExpenseType] =
    useState<IExpenseType>();
  const [stopWarningData, setStopWarningData] = useState<IStopWarningData>();
  const [available, setAvailable] = useState<string>(NO_ESCROW);
  const [payButtonDisabled, setPayButtonDisabled] = useState<boolean>(true);
  const { databaseName } = useActiveDB();
  const escrowExpenses = useSelector(
    (state: RootState) => state.escrowReducer.expenseEscrows,
  );
  const selectedProperty = useSelector(
    (state: RootState) => state.propertyReducer.selectedProperty,
  );
  const propertyList = useSelector(
    (state: RootState) => state.propertyReducer.propertyList,
  );
  const [currentProperty, setCurrentProperty] = useState<IProperty>(
    propertyList.find((p) => p.PropertyID === selectedProperty)!,
  );
  const showLoader: boolean = useSelector(
    (state: RootState) => state.propertyReducer.showLoader,
  );
  const JustPaidData = useSelector(
    (state: RootState) => state.transactionReducer.justPaidData,
  );
  const { onChange } = useForm(data, setData);

  useEffect(() => {
    if (selectedExpenseType) {
      handleExpenseTypeClick(selectedExpenseType);
    }
    setCurrentProperty(
      propertyList.find((p) => p.PropertyID === selectedProperty)!,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty]);
  useEffect(() => {
    if (!showLoader) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader]);
  useEffect(() => {
    if (!selectedExpenseType) {
      setPayButtonDisabled(true);
      return;
    }

    if (typeof data.Amount === 'undefined' || data.Amount.toString() === '') {
      setPayButtonDisabled(true);
      return;
    }
    setPayButtonDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExpenseType, data]);

  useEffect(() => {
    //this is when we comeback from the modal and the user want to override the
    //escrow shortage
    if (stopWarningData?.AllowOverride) {
      dispatch(
        MakeExpensePayment(
          data,
          selectedExpenseType!,
          currentProperty,
          databaseName!,
        ),
      );
      resetForm();
      updateJustPaidList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopWarningData]);
  const resetForm = () => {
    setSelectedExpenseType(undefined);
    setData({
      ...data,
      Amount: 0,
      Comments: '',
      Notes: '',
      CheckNum: '',
    });
    //data.Amount = 0;
    setAvailable(NO_ESCROW);
  };
  const handleExpenseTypeClick = (expenseType: IExpenseType) => {
    const key: string = `${selectedProperty}_${expenseType.ExpenseTypeID}`;
    const available = escrowExpenses[key];
    if (available) {
      setAvailable(available.toFixed(2));
    } else {
      setAvailable(NO_ESCROW);
    }
    setSelectedExpenseType(expenseType);
  };

  const handlePay = () => {
    if (!data.StopWarning && available !== NO_ESCROW) {
      let amount = data.Amount || 0;
      amount = parseFloat(amount.toString());
      if (amount > parseFloat(available)) {
        setStopWarningData({
          AllowOverride: false,
          ShowModal: true,
        });
        return;
      }
    }
    dispatch(
      MakeExpensePayment(
        data,
        selectedExpenseType!,
        currentProperty,
        databaseName!,
      ),
    );
    resetForm();
    updateJustPaidList();
    let expenseEntry: IRawBalanceEntry = {
      name: EntryType.Expense,
      Amount: data.Amount!,
      PropertyID: currentProperty.PropertyID,
      Address: '',
    };
    dispatch(updateBalanceFigures(expenseEntry));
  };

  const setWarningModalData = (data: IStopWarningData) => {
    setStopWarningData(data);
  };

  const updateJustPaidList = () => {
    dispatch(
      addJustPaidEntry({
        ExpenseName: selectedExpenseType?.ExpenseName,
        Amount: data.Amount,
      }),
    );
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const checkNumFocus = (e) => {
    const _data = { ...data, IsCreditCard: false };
    setData(_data);
  };

  const onChangeCC = (e) => {
    onChange(e);
    //const _data = {...data, CheckNum:''};
    // setData(_data);
    //
  };
  return (
    <Container fluid>
      <StopWarningModal
        StopWarningData={stopWarningData}
        SetData={setWarningModalData}
      />
      <Row className='border bg-dark text-light'>
        <Col lg={9}>
          <SplitControlsScreen>
            <div>
              <InputGroup className='mb-3'>
                <InputGroup.Prepend>
                  <InputGroup.Text id='basic-addon1'>
                    Available:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder='Available'
                  aria-label='Available'
                  aria-describedby='basic-addon1'
                  readOnly
                  value={available}
                />
              </InputGroup>
              <InputGroup className='mb-3'>
                <InputGroup.Prepend>
                  <InputGroup.Text id='basic-addon1'>Amount:</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder='Amount'
                  aria-label='Amount'
                  aria-describedby='basic-addon1'
                  value={data.Amount}
                  name='Amount'
                  onChange={onChange}
                  data-numeric={true}
                  autoComplete='off'
                  onFocus={handleFocus}
                />
              </InputGroup>
              <SuggestBox
                label='Comments:'
                value={data.Comments || ''}
                name='Comments'
                onChange={onChange}
              />

              <InputGroup className='mb-3'>
                <InputGroup.Prepend>
                  <InputGroup.Text id='basic-addon1'>Notes:</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder='Notes'
                  aria-label='Notes'
                  aria-describedby='basic-addon1'
                  value={data.Notes}
                  name='Notes'
                  onChange={onChange}
                  autoComplete='off'
                />
              </InputGroup>
              <InputGroup className='mb-3'>
                <InputGroup.Prepend>
                  <InputGroup.Text id='basic-addon1'>Check #:</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder='Check #'
                  aria-label='Check #'
                  aria-describedby='basic-addon1'
                  value={data.CheckNum}
                  name='CheckNum'
                  onChange={onChange}
                  onFocus={checkNumFocus}
                  autoComplete='off'
                />
              </InputGroup>
            </div>
            <JustPaidExpenses>
              {JustPaidData.map((item: IJustPaidExpense, index: number) => (
                <JustPaidRow key={index}>
                  <span>{item.ExpenseName}</span>
                  <span>${item.Amount!}</span>
                </JustPaidRow>
              ))}
            </JustPaidExpenses>
          </SplitControlsScreen>

          <CheckboxGroup>
            <Form.Check
              className='ml-3'
              type='switch'
              id='custom-switch1'
              label='Charge on credit card'
              checked={data.IsCreditCard || false}
              name='IsCreditCard'
              onChange={onChangeCC}></Form.Check>
            <Form.Check
              className='ml-3 mb-3'
              type='switch'
              id='custom-switch2'
              label='Stop Warning Dialog'
              name='StopWarning'
              onChange={onChange}
              checked={data.StopWarning || false}
            />
          </CheckboxGroup>

          <Button
            disabled={payButtonDisabled}
            variant='primary'
            size='lg'
            className='mb-3'
            onClick={handlePay}
            block>
            Pay
          </Button>
        </Col>
        <Col lg={3}>
          <ExpenseTypeList
            SetSelectedExpenseType={handleExpenseTypeClick}
            SelectedExpenseType={selectedExpenseType}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Expenses;
