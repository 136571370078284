import styled from "styled-components";

export const SuggestDropdown = styled.div`
    width: calc(100% - 106px);
    left: 106px;
    top:39px;
    height: 130px;
    background-color:white;
    color:gray;
    border: 1px solid gray;
    border-radius:10px;
    position: absolute;
    z-index:10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    padding-left: 30px;
    font-weight: bold;
`;
export const SuggestItem = styled.div<{in:number}>`
   padding:7px;
   cursor: pointer;
   background-color:yellow
   color: red;
   &:hover{
       background-color:#dddddd;
       color:white;
   }
  
`;
///*${props => props.in === 0 ? 'red' : '#dddddd'}*/
//