import React from 'react';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import { useActiveDB } from '../../hooks/useActiveDB';
import { IDataBase } from '../../modules/properties/types';
import { setActiveDB } from '../../modules/properties/propertyActions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getBalanceData } from '../../modules/balance/balanceActions';
import { resetEscrowTree } from '../../modules/escrow/escrowActions';
import { DB_KEY } from '../../Utils/Constants';
import { loadExpenseTypeList } from '../../modules/transactions/transactionActions';
import { backupDatabases } from '../../Utils/DatabaseBackupTool';
// @ts-ignore
type MyProps = {};

const PropertyLabel = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const Databases: React.FC<MyProps> = () => {
  const { databaseName, selectedDatabase, allDatabases } = useActiveDB();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBalanceData(databaseName!));
    dispatch(resetEscrowTree());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatabase]);
  const handleClick = (db: IDataBase) => {
    dispatch(setActiveDB(db));
    dispatch(loadExpenseTypeList(db.DatabaseName));
    localStorage.setItem(DB_KEY, db.ID.toString());
    backupDatabases(allDatabases, dispatch);
  };
  return (
    <Row>
      <Col lg={12}>
        <Row>
          <Col>
            <DropdownButton as={ButtonGroup} variant='' title='Choose Database'>
              {allDatabases.map((d: IDataBase, ndx: number) => {
                return (
                  <Dropdown.Item
                    key={ndx}
                    onClick={() => handleClick(d)}
                    active={selectedDatabase!.ID === d.ID}>
                    {d.DisplayName}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <PropertyLabel>{selectedDatabase?.DisplayName}</PropertyLabel>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Databases;
