import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { ROOT_URL } from '../../../Utils/Constants';
import { useActiveDB } from '../../../hooks/useActiveDB';
import { SuggestDropdown } from './Styles';

type MyProps = {
  value: string,
  label: string,
  name: string,
  onChange: (e:any) => void
}
const SuggestItem = styled.div<{in:number, current:number}>`
   padding:7px;
   cursor: pointer;
   background-color: ${props => props.in === props.current ? '#cdcdcd' : '#ffffff'};
   color: ${props => props.in === props.current ? '#ffffff' : '#cdcdcd'};
   &:hover{
       background-color:#dddddd;
       color:white;
   }
  
`;

type Target = {
    name: string,
    value: string,
    dataset: any
}

type Event = {
    target: Target
}
const SuggestBox: React.FC<MyProps> = ({value,label,onChange,name}) => {
    const [arr, setArr] = useState<any[]>([]);
    const [current, setCurrent] = useState<number>(0);
    const {databaseName} = useActiveDB();
    const searchAPI = text => fetch(`${ROOT_URL}/expenses/notes.php?db_name=${databaseName}&notes=` + encodeURIComponent(text));
    const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);
    const textChanged =  (e) => {
        onChange(e);
        if(e.target.value === '') {
            setArr([]);
            return;
        }
        searchAPIDebounced(e.target.value).then(resp => {
            resp.text().then(text => {
                try{
                    setArr(JSON.parse(text));
                } catch(e){
                    setArr([]);
                }
                
            })
        
        });
        
   }

   const handleClick = ( lcur ) => {
    getCurrentCommentsValue(lcur);
   }

   const getCurrentCommentsValue = ( lcur ) => {
       if(!lcur){
           lcur = current;
       }
    const t:Target = {name: 'Comments', value:arr[lcur]['ActivityNote'],dataset:{} };
    const e1:Event = {target: t};
    onChange(e1);
    setArr([]);
   }

   const handleKeyUp = e => {
       switch(e.key){
           case 'ArrowDown':
               if(current !== 2){
                setCurrent(current + 1);
               }
               break;
            case 'ArrowUp':
                if(current !== 0){
                    setCurrent(current - 1);
                   }
                break;
            case 'Enter':
                getCurrentCommentsValue(current);
                setArr([]);
                break;
            case 'Escape':
                setArr([]);
              break;
       }
   }
  return (
    <InputGroup className="mb-3">
        <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">{label}</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
            placeholder={name}
            aria-label={name}
            aria-describedby="basic-addon1"
            value={value}
            name={name}
            onChange={e=>textChanged(e)}
            onKeyUp={handleKeyUp}
            autoComplete="off"
        />
        { arr.length > 0 && 
        <SuggestDropdown >
            {
              arr.map((item,index) => {
                  return (
                    <SuggestItem 
                       key={`an-${index}`} 
                       in={index} 
                       current={current}
                       onClick={() => handleClick(index)}
                       >
                           {item.ActivityNote}
                    </SuggestItem>
                  );
              })
            }
        </SuggestDropdown>
       }
   </InputGroup>
  )
}
//http://aviezri.com/api/objects/expenses/notes.php?notes=final
//https://aviezri.com/api/objects/expenses/updateNotes.php?comment=final4

export default SuggestBox;