import styled from "styled-components";

export const selectedColor = '#cccccc';
export const gridHoverColor = '#dddddd';

export const StatementRowWrapper = styled.div<{ isChecked?: boolean }>`
  background-color: ${props => props.isChecked ? selectedColor : 'white'};
  display:grid;
  grid-template-columns: repeat(48,1fr);
  grid-template-rows:1fr;
  text-align:left;
  &:hover{
       background-color: ${gridHoverColor};
       
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows:1fr 1fr 1fr;
  }
`;
export const RowWrapper = styled.div<{ isChecked?: boolean }>`
   background-color: ${props => props.isChecked ? selectedColor : 'white'};
   display: flex;
   flex-direction: row;
  @media (max-width: 768px) {
    border: 1px solid grey;
    display:grid;
    grid-template-columns: 50% 50%;
    grid-template-rows:50% 50%;
  }
   flex:1;   
    &:hover{
      background-color: ${gridHoverColor};
     }
  .small-hide{
    @media(max-width:600px){
      display:none;
    }
  }
`;

export const ControlsGroup = styled.div`
  padding:20px;
  width:100%;
`;