import { Leaf } from '../escrow/types';
import {
  GET_VENDERS_LIST,
  IVendor,
  SET_SELECTED_VENDOR,
  VendorsActions,
} from './types';

export interface IVendorsReducer {
  vendorsTree: Leaf;
  selectedVendor?: IVendor;
}

const INIT_STATE: IVendorsReducer = {
  vendorsTree: { Label: 'No Vendors', Amount: 0 },
  selectedVendor: undefined,
};

export const emptyVendor: IVendor = {
  Id: -1,
  Vendor: '',
  Contact1: '',
  Contact2: '',
  Phone1: '',
  Phone2: '',
  Address: '',
  City: '',
  State: '',
  Zipcode: '',
  Comments: '',
  Trade: '',
};

export const VendorsReducer = (
  state: IVendorsReducer = INIT_STATE,
  action: VendorsActions,
) => {
  switch (action.type) {
    case GET_VENDERS_LIST:
      return { ...state, vendorsTree: buildTree(action.payload) };
    case SET_SELECTED_VENDOR:
      if (action.payload) {
        return { ...state, selectedVendor: action.payload };
      }
      return { ...state, selectedVendor: emptyVendor };
    default:
      return state;
  }
};

const buildTree = (payload: IVendor[]): Leaf => {
  const rootNode: Leaf = { Label: 'Vendors', Amount: 0, Children: [] };
  let tradeNode: Leaf = { Label: '', Amount: 0 };
  let childNode: Leaf = { Label: '', Amount: 0 };
  payload.forEach((item: IVendor) => {
    if (tradeNode.Label !== item.Trade) {
      tradeNode = {
        Label: item.Trade,
        Amount: 0,
        Children: [],
      };
      rootNode.Children?.push(tradeNode);
    }
    childNode = {
      Label: item.Vendor,
      Amount: 0,
      Context: item,
    };
    tradeNode.Children?.push(childNode);
  });
  return rootNode;
};
