import { IEscrowExpense, Leaf } from '../modules/escrow/types';

export const setEscrowHashTable = (
  escrowExpenses: IEscrowExpense[],
  collectedHashTable?: object,
): object => {
  const pf = (input: number): number => {
    let num = parseFloat(input.toString());
    return num;
  };
  const escrowHashTable = escrowExpenses.reduce(
    (result: IEscrowExpense, escrowExpense: IEscrowExpense) => {
      const key: string = `${escrowExpense.PropertyID}_${escrowExpense.ExpenseTypeID}`;
      if (key in result) {
        let amount: number = pf(result[key]);
        amount = amount + pf(escrowExpense.Amount);
        result[key] = amount;
      } else {
        result[key] = pf(escrowExpense.Amount || 0);
      }
      return result;
    },
    {} as IEscrowExpense,
  );

  if (collectedHashTable) {
    Object.keys(collectedHashTable).forEach((key: string) => {
      if (!escrowHashTable[key]) {
        escrowHashTable[key] = 0;
      }
    });
  }
  return escrowHashTable;
};

export const buildTree = (data: IEscrowExpense[], hash: {}): Leaf => {
  const rootLeaf = {
    Label: 'Escrows',
    Amount: 0,
    Children: [] as Leaf[],
  };
  let prevAddress: string = '';
  let currentAddress: Leaf = {} as Leaf;
  let prevExpense: string = '';
  let currentExpense: Leaf = {} as Leaf;
  let item: IEscrowExpense;
  for (let i = 0; i < data.length; i++) {
    item = data[i];
    if (prevAddress !== item.Address) {
      //Adding a new address leaf
      prevAddress = item.Address!;
      currentAddress = {} as Leaf;
      currentAddress.Label = item.Address!;
      currentAddress.Amount = 0;
      currentAddress.Children = [] as Leaf[];
      rootLeaf.Children.push(currentAddress);
    }

    if (prevExpense !== item.ExpenseName) {
      //Adding a new expense type to the
      prevExpense = item.ExpenseName;
      currentExpense = {} as Leaf;
      currentExpense.Label = item.ExpenseName;
      currentExpense.Amount = 0;
      currentExpense.Children = [] as Leaf[];

      currentAddress.Children?.push(currentExpense);
    }

    //Adding the entry to Expense leaf
    let currentLeaf: Leaf = {
      Label: item.ActionDate.substr(0, 10),
      Amount: parseFloat(item.Amount.toString()),
      AutoActionID: parseFloat(item.AutoActionID!.toString()),
    } as Leaf;

    //updating amount in currentExpense
    currentExpense.Children?.push(currentLeaf);

    const key = `${item.PropertyID}_${item.ExpenseTypeID}`;
    currentExpense.Amount =
      hash[key] || //this is the case when we collected escrow but no expense paid out
      currentExpense.Children!.reduce((total, item) => {
        total += item.Amount;
        return total;
      }, 0);

    //updating amount in currentAddress
    currentAddress.Amount =
      currentAddress.Children?.reduce((total: number, item: Leaf): number => {
        total = total + item.Amount;
        return total;
      }, 0) || currentAddress.Amount;

    //updating root.amount
    rootLeaf.Amount =
      rootLeaf.Children?.reduce((total: number, item: Leaf): number => {
        total = total + item.Amount;
        return total;
      }, 0) || rootLeaf.Amount;
  }
  return rootLeaf;
};

export const extractAllEscrowEntries = (
  escrows: IEscrowExpense[],
): string[] => {
  return escrows.reduce((arr: string[], item: IEscrowExpense) => {
    const key = `${item.PropertyID}_${item.ExpenseTypeID}`;
    if (arr.indexOf(key) === -1) {
      arr.push(key);
    }
    return arr;
  }, []);
};
