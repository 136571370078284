import { Dictionary } from 'lodash';
import { IStatementLine } from '../modules/statement/types';

export class MultiLineSelector {
  private commonChecks: Dictionary<number[]>;
  totalSelected: number;

  constructor() {
    this.commonChecks = {};
    this.totalSelected = 0;
  }

  public addId = (line: IStatementLine) => {
    let checkNumber: string = this.getCheckNumber(line);

    if (checkNumber) {
      let idsArr: number[] = this.commonChecks[checkNumber] || [];
      idsArr.push(line.cronid);
      this.commonChecks[checkNumber] = idsArr;
    }
  };

  public getCheckNumber(line: IStatementLine) {
    if (line.eCheckNumber) {
      return line.eCheckNumber;
    } else if (line.mortgageCheck) {
      return line.mortgageCheck;
    } else if (line.wCheckNum) {
      return line.wCheckNum;
    }
    return '';
  }

  updateLines(
    updatedStatementLines: IStatementLine[],
    selectedCheckNumber: string,
    isChecked: boolean,
  ): IStatementLine[] {
    const cronIdArr = this.commonChecks[selectedCheckNumber];

    return updatedStatementLines.map((line) => {
      if (cronIdArr && cronIdArr.indexOf(line.cronid) > -1) {
        line.reviewed = isChecked ? 1 : 0;
      }

      if (line.reviewed === 1) {
        this.totalSelected += this.getAmount(line);
      }
      return line;
    });
  }

  private getAmount(line: IStatementLine): number {
    if (line.eAmount) {
      return -1 * line.eAmount;
    }
    if (line.depositAmount) {
      return 1 * line.depositAmount;
    }
    if (line.mortgageAmount) {
      return -1 * line.mortgageAmount;
    }
    if (line.rentAmount) {
      return 1 * line.rentAmount;
    }
    if (line.wAmount) {
      return -1 * line.wAmount;
    }
    return 0;
  }
}
