export const GET_VENDERS_LIST = 'GET_VENDERS_LIST';
export const SET_SELECTED_VENDOR = 'SET_SELECTED_VENDOR';
export interface IVendor {
  Id: number;
  Vendor: string;
  Contact1?: string;
  Contact2?: string;
  Phone1: string;
  Phone2?: string;
  Address?: string;
  City?: string;
  State?: string;
  Zipcode?: string;
  Comments?: string;
  Trade: string;
}

interface IGetVendersListAction {
  type: typeof GET_VENDERS_LIST;
  payload: IVendor[];
}

interface ISetSelectedVenderAction {
  type: typeof SET_SELECTED_VENDOR;
  payload: IVendor;
}

export type VendorsActions = IGetVendersListAction | ISetSelectedVenderAction;
