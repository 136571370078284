import React, { FC, useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import './MyExpenses.css';
import { MyExpenseType } from '../MyExpenses';
import SavePopup from './SavePopup';

const ExpenseBanner: FC<MyExpenseType> = ({ setShowData, activeJson }) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <div className='ExpenseBannerWrapper'>
      {showPopup && (
        <SavePopup
          setShowPopup={setShowPopup}
          setShowData={setShowData}
          activeJson={activeJson}
        />
      )}
      <div
        onClick={() => {
          setShowPopup(true);
        }}
        className='ExpenseBannerButton'>
        <div>
          <CloudUploadIcon />
        </div>
        <div>Save</div>
      </div>
      <div></div>
      <div onClick={() => setShowData(false)} className='ExpenseBannerButton'>
        <div>
          <CancelIcon />
        </div>
        <div>Close</div>
      </div>
    </div>
  );
};

export default ExpenseBanner;
